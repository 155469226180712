import React, { useState } from "react";
import { connect } from "react-redux";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Dropzone from "react-dropzone";
import { constants } from "../../../../../app-config";
import {
  getProductExcel,
  getProductDetailExcel,
  productDataImport,
} from "../../../../../services/import-file.service";

const ProductImportForm = ({ company, setActiveTab, compnayId }) => {
  const initials = {
    file: "",
    fileProductDetail: "",
  };

  const [submitted, setSubmitted] = useState(false);

  const ProductImportFormSchema = Yup.object().shape(
    {
      file: Yup.array().when("fileProductDetail", {
        is: (fileProductDetail) =>
          !fileProductDetail || fileProductDetail.length === 0,
        then: Yup.array()
          .required("Se requiere al menos un campo")
          .test(
            "file_test_first",
            "por favor, importe el archivo de extensión xlsx",
            function (value) {
              let toggle = true;
              const file = value?.[0];
              const fileName = file?.name.split(".").pop();
              if (
                !value ||
                value.length === 0 ||
                !file ||
                !fileName ||
                fileName !== "xlsx"
              ) {
                toggle = false;
              }

              if (this.parent.fileProductDetail) {
                toggle = true;
              }

              return toggle;
            }
          ),
        otherwise: Yup.array(),
      }),
      fileProductDetail: Yup.array().when("file", {
        is: (file) => !file || file.length === 0,
        then: Yup.array()
          .required("Se requiere al menos un campo")
          .test(
            "file_test_second",
            "por favor, importe el archivo de extensión xlsx",
            function (value) {
              let toggle = true;
              const file = value?.[0];
              const fileName = file?.name.split(".").pop();
              if (
                !value ||
                value.length === 0 ||
                !file ||
                !fileName ||
                fileName !== "xlsx"
              ) {
                toggle = false;
              }

              if (this.parent.file) {
                toggle = true;
              }

              return toggle;
            }
          ),
        otherwise: Yup.array(),
      }),
    },
    ["file", "fileProductDetail"]
  );

  // ProductImportFormSchema = Yup.object().shape({
  //   file: Yup.array()
  //     .required("Este campo es obligatorio")
  //     .test(
  //       "file_test_first",
  //       "por favor, importe el archivo de extensión xlsx",
  //       (value) => {
  //         let toggle = true;
  //         const file = value?.[0];
  //         const fileName = file?.name.split(".").pop();
  //         if (
  //           !value ||
  //           value.length === 0 ||
  //           !file ||
  //           !fileName ||
  //           fileName !== "xlsx"
  //         ) {
  //           toggle = false;
  //         }
  //         return toggle;
  //       }
  //     ),
  //   fileProductDetail: Yup.array()
  //     .required("Este campo es obligatorio")
  //     .test(
  //       "file_test_second",
  //       "por favor, importe el archivo de extensión xlsx",
  //       (value) => {
  //         let toggle = true;
  //         const file = value?.[0];
  //         const fileName = file?.name.split(".").pop();
  //         if (
  //           !value ||
  //           value.length === 0 ||
  //           !file ||
  //           !fileName ||
  //           fileName !== "xlsx"
  //         ) {
  //           toggle = false;
  //         }
  //         return toggle;
  //       }
  //     ),
  // });

  const onSubmit = async (values) => {
    setSubmitted(true);
    const formData = new FormData();

    if (values.file[0]) {
      formData.append("file", values.file[0]);
    }
    if (values.fileProductDetail[0]) {
      formData.append("fileProductDetail", values.fileProductDetail[0]);
    }
    if (
      Object.keys(company).length > 0 &&
      company?.companyGDI &&
      company.companyGDI !== ""
    ) {
      formData.append("idCompanyGdi", company.companyGDI);
    }
    formData.append("IdCompany", compnayId);

    const res = await productDataImport(formData);
    setSubmitted(false);
    if (res && res.status === "success") {
      setActiveTab("2");
    }
  };

  const downloadProductExcel = async () => {
    const res = await getProductExcel({
      idCompanyGdi: company.companyGDI,
      IdCompany: compnayId,
    });
    if (Object.keys(res).length > 0) {
      window.open(constants.BACKEND_URL + res.file_path, "_blank");
    }
  };

  const downloadProductDetailExcel = async () => {
    const res = await getProductDetailExcel({
      idCompanyGdi: company.companyGDI,
      IdCompany: compnayId,
    });
    if (Object.keys(res).length > 0) {
      window.open(constants.BACKEND_URL + res.file_path, "_blank");
    }
  };

  return (
    <div className="content-detail">
      <div className="content-detail-button">
        <div>
          <a
            href={`${constants.BACKEND_URL}storage/excel-demo/Product.xlsx`}
            className="btn blue-square-btn"
            download
          >
            Referencia del producto
          </a>
        </div>
        <div style={{ marginLeft: "10px" }}>
          <a
            href={`${constants.BACKEND_URL}storage/excel-demo/ProductDetail.xlsx`}
            className="btn blue-square-btn"
            download
          >
            Referencia de detalle del producto
          </a>
        </div>
      </div>
      <Formik
        initialValues={initials}
        onSubmit={onSubmit}
        validationSchema={ProductImportFormSchema}
      >
        {({ values, errors, setFieldValue }) => {
          return (
            <Form>
              <div className="drop-image">
                <label>Subir Producto</label>
                <Dropzone
                  accept=".xlsx"
                  multiple={false}
                  onDrop={(acceptedFiles) => {
                    const imageDataOnDrop = acceptedFiles.map((file) =>
                      Object.assign(file, {
                        preview: URL.createObjectURL(file),
                      })
                    );

                    setFieldValue("file", imageDataOnDrop);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div>
                      <div className="drop-box" {...getRootProps()}>
                        <input {...getInputProps()} />
                        {values.file === "" &&
                          "Drag and drop file or select xlsx file"}
                        <aside>
                          {values.file &&
                            values.file.map((file, index) => {
                              return (
                                <div className="dz_img_wrp" key={file.name}>
                                  <div className="thumb">
                                    <div className="thumb-inner">
                                      <img
                                        src="/assets/images/file-icon.png"
                                        alt="itemImage"
                                      />
                                    </div>
                                    <p className="thumb-file-name">
                                      {file.name}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                        </aside>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <ErrorMessage
                  name="file"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="drop-image mt-20">
                <label>Detalles de producto</label>

                <Dropzone
                  accept=".xlsx"
                  multiple={false}
                  onDrop={(acceptedFiles) => {
                    const imageDataOnDrop = acceptedFiles.map((file) =>
                      Object.assign(file, {
                        preview: URL.createObjectURL(file),
                      })
                    );

                    setFieldValue("fileProductDetail", imageDataOnDrop);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <>
                      <div className="drop-box" {...getRootProps()}>
                        <input {...getInputProps()} />
                        {values.fileProductDetail === "" &&
                          "Drag and drop file or select xlsx file"}
                        <aside>
                          {values.fileProductDetail &&
                            values.fileProductDetail.map((file, index) => {
                              return (
                                <div className="dz_img_wrp" key={file.name}>
                                  <div className="thumb">
                                    <div className="thumb-inner">
                                      <img
                                        src="/assets/images/file-icon.png"
                                        alt="itemImage"
                                      />
                                    </div>
                                    <p className="thumb-file-name">
                                      {file.name}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                        </aside>
                      </div>
                    </>
                  )}
                </Dropzone>
                <ErrorMessage
                  name="fileProductDetail"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="tabSubmit-btn">
                <button
                  className="btn blue-square-btn"
                  type="submit"
                  disabled={submitted}
                >
                  {submitted && (
                    <i className="fa fa-spinner fa-spin button-loader"></i>
                  )}
                  Enviar
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>

      <div className="import-product-from-db">
        <div
          className="product"
          onClick={(e) => {
            downloadProductExcel();
          }}
        >
          <img src="/assets/images/file-icon.png" alt="itemImage" />
          <p className="product-button">
            exportar producto de la base de datos
          </p>
        </div>

        <div
          className="product"
          onClick={(e) => {
            downloadProductDetailExcel();
          }}
        >
          <img src="/assets/images/file-icon.png" alt="itemImage" />
          <p className="product-button-detail">
            exportar detalles del producto desde la base de datos
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ company }) => ({
  company,
});

export default connect(mapStateToProps, null)(ProductImportForm);
