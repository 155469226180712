import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { sendContact } from "../../services/contact.service";

const Contact = (props) => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const initials = {
    user: "",
    telephone: "",
    message: "",
  };

  const [formToggle, setFormToggle] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [userRole, setUserRole] = useState("basic");

  useEffect(() => {
    if (props?.userData?.permission?.type === "admin") setUserRole("admin");
  }, [props?.userData]);

  const ContactSchema = Yup.object().shape({
    user: Yup.string().required("Este campo es obligatorio"),
    telephone: Yup.string()
      .matches(phoneRegExp, "El número de teléfono no es válido")
      .required("Este campo es obligatorio"),
    message: Yup.string().required("Este campo es obligatorio"),
  });

  const onSubmit = async (values) => {
    setSubmitted(true);

    const data = { IdUser: props?.userData?.id, ...values };

    const res = await sendContact(data);
    setSubmitted(false);
    if (res && res.status === "success") {
      setFormToggle(true);
    }
  };

  return (
    <>
      <section className="section login-content-sec contact-sec">
        <div className="login-wrapper">
          <div className="login-content">
            <div className="login-content-wrap">
              <div className="login-title-wrap">
                <div className="login-title">
                  <h4>Solicita asesoría personalizada</h4>
                  <div className="login-subtitle">
                    En GDI estamos listos para apoyarte, coméntanos cómo podemos
                    hacer y nos contactaremos contigo a la brevedad
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="login-form">
            <div className="login-form-main">
              <div className="login-form-wrap">
                {!formToggle && (
                  <div className="contact-form">
                    <Formik
                      initialValues={initials}
                      onSubmit={onSubmit}
                      validationSchema={ContactSchema}
                    >
                      {({ errors, touched, values, setFieldValue }) => (
                        <Form>
                          <div className="row">
                            <div className="col form-group width-50">
                              <label>Usuario</label>

                              <Field
                                name="user"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.user && touched.user
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Sofia"
                              />
                              <ErrorMessage
                                name="user"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="col form-group width-50">
                              <label>Teléfono</label>

                              <Field
                                name="telephone"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.telephone && touched.telephone
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="56788967"
                              />
                              <ErrorMessage
                                name="telephone"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>

                          <div className="form-group">
                            <label>Mensaje</label>
                            <Field
                              name="message"
                              as="textarea"
                              rows="10"
                              placeholder="Escribe aquí tu mensaje"
                            />
                            <ErrorMessage
                              name="message"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="submit-btn">
                            <button
                              type="submit"
                              name="gdi-solution-btn"
                              disabled={submitted}
                            >
                              {submitted && (
                                <i className="fa fa-spinner fa-spin button-loader"></i>
                              )}
                              Enviar
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                )}

                {formToggle && (
                  <div>
                    <h4>
                      Gracias por contactarnos en breve nos pondremos en
                      contacto contigo para solucionar tu situacion
                    </h4>
                    <Link
                      to={
                        userRole === "admin" ? "/admin/catalogue" : "/catalogue"
                      }
                    >
                      <button className="btn">Ir al catalogo principal</button>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapToStateProps = ({ auth: { user_details } }) => ({
  userData: user_details,
});
export default connect(mapToStateProps, null)(Contact);
