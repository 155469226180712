import { axiosGet, axiosPost } from "../../axios/axios.config";
// import { showToast, toastTypes } from '../../helpers/toast.helper';
import { setLoading } from "./general.actions";
import { setToken } from "./token.actions";
import { setCompanyGdi } from "./company.actions";
import { setCartComment } from "./cart-comment.actions";

export const loginUser = (data) => {
  return (dispatch) => {
    // showToast('auth/login', toastTypes.loading, '', 15);
    dispatch(setLoading(true));
    axiosPost("login", data)
      .then((res) => {
        dispatch(setLoading(false));
        if (res["data"]) {
          // localStorage.setItem("token", res["data"]["token"]);
          dispatch(setToken(res["data"]["token"]));
          dispatch(loginSuccess(res["data"]["user"]));
        }
      })
      .catch((e) => {
        dispatch(setLoading(false));
      });
  };
};

export const getAuthUser = () => {
  return (dispatch) => {
    axiosGet("user").then((res) => {
      if (res["data"]) {
        dispatch(loginSuccess(res["data"]["user"]));
      }
    });
  };
};

export const logoutUser = () => {
  return (dispatch) => {
    dispatch(setToken(null));
    dispatch(setCompanyGdi({ companyGDI: "", logo: "", date: new Date() }));
    dispatch(setCartComment(""));
    dispatch(logout());
  };
};

export const loginSuccess = (data) => ({
  type: "LOGIN_SUCCESS",
  payload: data,
});

export const logout = () => ({
  type: "LOGOUT_USER",
});
