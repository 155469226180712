import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getImagePath } from "../../helpers/general.helper";
import { getCompanies } from "../../services/company.service";
import { Spinner } from "../../components/spinner/spinner";
import { setCompanyGdi } from "../../redux/actions/company.actions";

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}

const Brands = ({ loading, setCompanyGdi }) => {
  const [companies, setCompanies] = useState([]);
  const [page, setPage] = useState(1);
  const [total, SetTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [loadingToggle, setLoadingToggle] = useState(false);

  const getAllCompanies = async (page, search) => {
    const res = await getCompanies({
      page: page,
      search: search,
    });
    setLoadingToggle(false);
    if (
      res &&
      Object.keys(res).length > 0 &&
      res?.companies &&
      res?.total &&
      page >= 1
    ) {
      setCompanies((companies) => [...companies, ...res.companies]);
      SetTotal(res.total);
    } else {
      setCompanies([]);
      SetTotal(0);
    }
  };

  // eslint-disable-next-line
  const debounceLoadData = useCallback(debounce(getAllCompanies, 3000), []);

  useEffect(() => {
    if (page === 1) {
      setCompanies([]);
    }
    setLoadingToggle(true);
    debounceLoadData(page, search);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search]);

  const loadMore = () => {
    setPage((page) => page + 1);
  };

  return (
    <>
      <div className="section inner-banner-sec gdi-solution-banner">
        <div className="container">
          <div className="wrapper">
            <div className="inner-banner-wrap">
              <div
                className="inner-banner"
                style={{
                  backgroundImage: "url(/assets/images/inner-banner.png)",
                }}
              >
                <div className="inner-banner-title">
                  <h4>¡Bienvenido a tu plataforma GDI!</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section admin-footer-margin super-admin-footer-overlap">
        <div className="container">
          <div className="wrapper">
            <div className="admin-company-wrap">
              <Link to="/brands/create">
                <div className="add-new-company cursor-pointer">
                  Agregar nueva empresa <i className="fa fa-plus"></i>
                </div>
              </Link>
              <div className="search-company">
                <form action="#">
                  <div className="form-group">
                    <i className="fa fa-search"></i>
                    <input
                      type="text"
                      name="search_company"
                      placeholder="Walmart"
                      onChange={(e) => {
                        setPage(1);
                        if (loading === false) {
                          setSearch(e.target.value);
                        }
                      }}
                      value={search}
                    />
                  </div>
                </form>
              </div>
            </div>

            {loading ? <Spinner /> : ""}
            <div className="company-grid min-h-100">
              {companies && companies.length > 0
                ? companies.map(
                    (
                      { IdCompanyGDI, IdCompany, Logo, Name, created_at },
                      index
                    ) => (
                      <div className="company-grid-item" key={index}>
                        <div className="item-box">
                          <div className="company-logo">
                            <img
                              src={
                                Logo
                                  ? getImagePath(Logo)
                                  : `${process.env.PUBLIC_URL}/assets/images/gray-logo.png`
                              }
                              alt="brand"
                            />
                          </div>
                          <div className="company-detail">
                            <div className="content-wrap">
                              <div className="company-name">{Name}</div>
                              <div className="company-id">
                                ID: {IdCompanyGDI}
                              </div>
                            </div>
                            <div
                              className="go-icon"
                              onClick={(e) => {
                                setCompanyGdi({
                                  IdCompanyGDI,
                                  Logo,
                                  created_at,
                                });
                              }}
                            >
                              <Link to={`/brands/update/${IdCompany}`}>
                                <img
                                  src="assets/images/right-arrow.png"
                                  alt="brand-inner"
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )
                : ""}
            </div>

            {search !== "" &&
            companies.length === 0 &&
            loadingToggle === false ? (
              <div className="search-result">
                <div className="search-result-content">
                  <div className="icon">
                    <img src="/assets/images/comments.png" alt="" />
                  </div>
                  <div className="content">
                    Tu búsqueda no coincidió con ningún producto, por favor
                    realiza otra{" "}
                  </div>

                  <div
                    className="suggested-products"
                    onClick={(e) => {
                      setPage(1);
                      setSearch("");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Volver al inicio
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {total && Math.ceil(total / 6) > page && companies.length > 0 ? (
              <div className="see-more text-center">
                <a
                  href="##"
                  onClick={(e) => {
                    e.preventDefault();
                    loadMore();
                  }}
                >
                  Ver más <i className="fa fa-chevron-down"></i>
                </a>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ general: { loading } }) => ({
  loading: loading,
});

const mapDispatchToProps = (dispatch) => ({
  setCompanyGdi: (data) => dispatch(setCompanyGdi(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Brands);
