import React from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../redux/actions/auth.actions";
import BannerLogo from "./banner";
import CatalogueFilter from "./catalogue-filter";

const MainCatalogue = ({ userData, logoutUser }) => {
  return (
    <>
      <BannerLogo userData={userData} logoutUser={logoutUser} />
      <CatalogueFilter userData={userData} />
    </>
  );
};

const mapStateToProps = ({ auth: { user_details } }) => ({
  userData: user_details,
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainCatalogue);
