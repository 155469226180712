const initialState = {
  user_details: {},
  redirect_to_login: false,
};

const onLogout = (state) => {
  // localStorage.removeItem("token");
  // localStorage.removeItem("cartItems");
  return {
    ...state,
    user_details: {},
    redirect_to_login: true,
  };
};

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        user_details: action.payload,
        redirect_to_login: false,
      };
    case 'LOGOUT_USER':
      return onLogout(state);
    // return { ...state, user_details: {}, redirect_to_login: Math.random() };
    default:
      return state;
  }
};
