const initialState = {
  companyGDI: "",
  logo: "",
  date: new Date(),
};

export const company = (state = initialState, action) => {
  switch (action.type) {
    case "SET_COMPANY_GDI":
      return {
        ...state,
        companyGDI: action.payload.IdCompanyGDI,
        logo: action.payload.Logo,
        date: action.payload.created_at,
      };
    default:
      return state;
  }
};
