import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

const ForgotPasswordSuccessFail = ({ userDetails, loading }) => {
  let history = useHistory();
  const [superAdminToggle, setSuperAdminToggle] = useState(false);

  useEffect(() => {
    if (
      window.location.pathname &&
      window.location.pathname.includes('super-admin')
    ) {
      setSuperAdminToggle(true);
    }

    if (Object.keys(userDetails).length) {
      if (
        userDetails &&
        userDetails?.permission?.type &&
        userDetails.permission.type === 'superadmin'
      ) {
        history.push('/');
      }

      if (
        userDetails &&
        userDetails?.permission?.type &&
        userDetails.permission.type === 'basic'
      ) {
        history.push('/catalogue');
      }

      if (
        userDetails &&
        userDetails?.permission?.type &&
        userDetails.permission.type === 'admin'
      ) {
        history.push('/admin/catalogue');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const handleOnClick = () => {
    if (superAdminToggle) {
      history.push({ pathname: '/super-admin/login' });
    } else {
      history.push({ pathname: '/login' });
    }
  };

  return (
    <section className="section login-content-sec forgot-pwd">
      <div className="login-wrapper">
        <div className="login-content">
          <div className="login-content-wrap">
            <div className="login-logo">
              <a href="##">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/logo-white.png`}
                  alt=""
                />
              </a>
            </div>
            <div className="login-title-wrap">
              <div className="login-title">
                <h4>Recupera tu contraseña</h4>
              </div>
            </div>
            {!superAdminToggle && (
              <div className="social-icons">
                <ul>
                  <li>
                    <a href="#!">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#!">
                      <i className="fa fa-facebook-f"></i>
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="login-form">
          <div className="login-form-main">
            <div className="login-form-wrap">
              <div className="form-title forgot-success-fail-msg">
                <h5>Mensaje de éxito u operación fallida</h5>
              </div>
              <div className="login-submit">
                <button
                  type="button"
                  name="login"
                  className="blue-btn"
                  onClick={(e) => {
                    handleOnClick();
                  }}
                >
                  Regresar a inicio de sesión
                </button>
                {!superAdminToggle && (
                  <div className="link">
                    <a href="https://www.gdiuniformes.com/">Regresar a GDI</a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = ({ auth: { user_details }, general: { loading } }) => ({
  userDetails: user_details,
  loading,
});

export default connect(mapStateToProps, null)(ForgotPasswordSuccessFail);
