import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { store } from "../../redux/store";
import { loginUser } from "../../redux/actions/auth.actions";
import { emptyCart } from "../../redux/actions/cart.actions";
import { logoutUser } from "../../redux/actions/auth.actions";

const loginSchema = Yup.object().shape({
  user: Yup.string().required("Este archivado es obligatorio"),
  password: Yup.string().required("se requiere contraseña"),
});
const initials = { user: "", password: "" };

const Login = ({ userDetails, onLogin, history, loading }) => {
  const [superAdminToggle, setSuperAdminToggle] = useState(false);
  const onSubmit = (values) => {
    onLogin(values);
  };

  useEffect(() => {
    const state = store.getState();
    const token = state.token;
    if (!token) {
      emptyCart([]);
      logoutUser();
      history.push("/login");
    }
    if (
      window.location.pathname &&
      window.location.pathname.includes("super-admin")
    ) {
      setSuperAdminToggle(true);
    }

    if (Object.keys(userDetails).length) {
      if (
        userDetails &&
        userDetails?.permission?.type &&
        userDetails.permission.type === "superadmin"
      ) {
        history.push("/");
      }

      if (
        userDetails &&
        userDetails?.permission?.type &&
        userDetails.permission.type === "basic"
      ) {
        history.push("/catalogue");
      }

      if (
        userDetails &&
        userDetails?.permission?.type &&
        userDetails.permission.type === "admin"
      ) {
        history.push("/admin/catalogue");
      }
      if (
        userDetails &&
        userDetails?.permission?.type &&
        userDetails?.permission.type === "responsibeuser"
      ) {
        history.push("/responsibe-user/orders");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  return (
    <section className="section login-content-sec">
      <div className="login-wrapper">
        <div className="login-content">
          <div className="login-content-wrap">
            <div className="login-logo">
              <a href={superAdminToggle ? "/super-admin/login" : "/login"}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/logo-white.png`}
                  alt="brand-logo"
                />
              </a>
            </div>
            <div className="login-title-wrap">
              <div className="login-title">
                <h4>
                  {superAdminToggle ? (
                    <>Bienvenido al administrador de tu plataforma</>
                  ) : (
                    <div className="user-login-text">
                      Bienvenido al portal{" "}
                      <span className="gdi-color">GDI</span>
                      <span className="gital-color">gital</span>
                    </div>
                  )}
                </h4>

                {!superAdminToggle && (
                  <div className="login-subtitle">
                    {" "}
                    Estamos listos para atenderte
                  </div>
                )}
              </div>
            </div>
            {!superAdminToggle && (
              <div className="social-icons">
                <ul>
                  <li>
                    <a href="#!">
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="login-form">
          <div className="login-form-main">
            <div className="login-form-wrap">
              <div className="form-title">
                <h5>Ingresa a tu cuenta</h5>
              </div>
              <div className="login-form-inner">
                <Formik
                  initialValues={initials}
                  onSubmit={onSubmit}
                  validationSchema={loginSchema}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <div className="form-group">
                        <label>
                          {superAdminToggle
                            ? "Usuario*"
                            : " Número de empleado*"}
                        </label>
                        <Field
                          name="user"
                          type="text"
                          placeholder="7894536ABC"
                          className={
                            "form-control" +
                            (errors.user && touched.user ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="user"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="form-group">
                        <label>Contraseña*</label>
                        <Field
                          name="password"
                          placeholder="********"
                          type="password"
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="forgot-pwd">
                        {superAdminToggle ? (
                          <Link to={`/super-admin/forgot-password`}>
                            ¿Olvidaste tu contraseña?
                          </Link>
                        ) : (
                          <Link to={`/forgot-password`}>
                            ¿Olvidaste tu contraseña?
                          </Link>
                        )}
                      </div>
                      <div className="login-submit">
                        <button type="submit" className="blue-btn">
                          {loading && (
                            <i className="fa fa-spinner fa-spin button-loader"></i>
                          )}
                          Ingresar
                        </button>
                        {!superAdminToggle && (
                          <div className="link">
                            <a
                              href="https://www.gdiuniformes.com/"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              Regresar a GDI
                            </a>
                          </div>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = ({ auth: { user_details }, general: { loading } }) => ({
  userDetails: user_details,
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  onLogin: (data) => dispatch(loginUser(data)),
  emptyCart: (cartItems) => dispatch(emptyCart(cartItems)),
  logoutUser: () => dispatch(logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
