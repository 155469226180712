import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { BrandInner } from "../../brand-inner";
import TableComponent from "../../../../../components/table/table";
import { getUsers, deleteUsers } from "../../../../../services/users.service";
import { Modal } from "../../../../../components/modal/modal";

export const Users = (props) => {
  let history = useHistory();
  const [id, setId] = useState(-1);
  const [usersData, setUsersData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [deleteUser, setDeleteUser] = useState([]);

  useEffect(() => {
    if (props?.match?.params?.id) {
      setId(props.match.params.id);
    }
  }, [props?.match?.params]);

  const getAllUsers = async (page, filterData) => {
    const res = await getUsers({
      page: page,
      search: filterData,
      company_id: id,
    });

    if (res && Object.keys(res).length > 0) {
      setUsersData(res.records);
      setDataCount(res.total);
    }
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  const deleteParticularUser = (userId, checked) => {
    if (checked) {
      setDeleteUser([...deleteUser, userId]);
    } else {
      const tempArray = [...deleteUser];
      const index = tempArray.findIndex((e) => e === userId);
      if (index !== -1) {
        tempArray.splice(index, 1);
        setDeleteUser(tempArray);
      }
    }
  };

  const deleteMultipleUser = async () => {
    let data = {
      company_id: parseInt(id),
      users: deleteUser,
    };
    const res = await deleteUsers(data);
    if (res.status === "success") {
      setShowModal(false);
      const tempUser = [...usersData];
      const data = tempUser.filter((e) => !deleteUser.includes(e.IdUser));
      setUsersData([...data]);
      setDeleteUser([]);
    }
  };

  const headerData = [
    {
      header: (
        <>
          {deleteUser.length > 0 ? (
            <img
              src="/assets/images/close-red.png"
              alt=""
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                setShowModal(true);
              }}
            />
          ) : (
            <img src="/assets/images/close-dark.png" alt="" />
          )}
        </>
      ),
      cell: (props) => {
        return (
          <div className="form-group">
            <input
              type="checkbox"
              onChange={(e) =>
                deleteParticularUser(props.IdUser, e.target.checked)
              }
              id={`${props.IdUser}`}
              checked={deleteUser.includes(props.IdUser)}
            />
            <label htmlFor={`${props.IdUser}`}></label>
          </div>
        );
      },
    },
    {
      header: "Nombre",
      name: "Name",
    },
    {
      header: "Permisos",
      cell: (props) => {
        if (props.permission && Object.keys(props.permission).length > 0) {
          return props.permission.value;
        }
      },
    },
    {
      header: "Sucursales",
      cell: (props) => {
        if (props?.sucursale && props.sucursale.length > 0) {
          let tempArray = [];
          props.sucursale.forEach((e) => {
            if (e?.branch_office?.Name && e?.branch_office?.Name !== "") {
              tempArray.push(e.branch_office.Name);
            }
          });
          const Sucursales = tempArray.length > 0 ? tempArray.toString() : "";
          return Sucursales;
        } else {
          return "";
        }
      },
    },
    {
      header: "Editar",
      cell: (props) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              history.push({
                pathname: `/brands/${id}/users/update/${props.IdUser}`,
              });
            }}
          >
            <img src="/assets/images/edit.png" alt="icon" />
          </div>
        );
      },
    },
  ];

  return (
    <BrandInner buttonDisable={true}>
      {id !== -1 ? (
        <>
          <TableComponent
            title="Usuarios"
            link={
              <Link to={`/brands/${id}/users/create`}>
                <div className="add-new-user">
                  Agregar usuario
                  <i className="fa fa-user-plus"></i>
                </div>
              </Link>
            }
            headerData={headerData}
            bodyData={usersData}
            noData={
              <div className="add-new-users">
                <img src="/assets/images/new-users.png" alt="" />
                <Link to={`/brands/${id}/users/create`}>
                  <div className="content">Agregar usuario</div>
                </Link>
              </div>
            }
            onChange={getAllUsers}
            dataCount={dataCount}
            limit={10}
            search={true}
            searchNoData={
              <div className="search-result">
                <div className="search-result-content">
                  <div className="icon">
                    <img src="/assets/images/comments.png" alt="" />
                  </div>
                  <div className="content">
                    Tu búsqueda no tuvo resultados, por favor realiza otra{" "}
                  </div>
                </div>
              </div>
            }
          />
          <Modal visible={showModal} id="photo-modal" onClose={onCloseModal}>
            <div className="choose-photo-popup">
              <div className="choose-photo-wrap popup-content-center">
                <div className="title">
                  <h4>Eliminar usuarios</h4>
                </div>

                <div className="selection-photo-grid">
                  <h6>¿Estás segura de que quieres eliminar esto?</h6>
                </div>
                <div className="link">
                  <a
                    href="#!"
                    className="btn blue-square-btn mr-1"
                    onClick={onCloseModal}
                  >
                    Cancelar
                  </a>
                  <a
                    href="#!"
                    className="btn blue-square-btn"
                    onClick={deleteMultipleUser}
                  >
                    Actualizar
                  </a>
                </div>
              </div>
            </div>
          </Modal>
        </>
      ) : (
        ""
      )}
    </BrandInner>
  );
};
