import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { emptyCart } from "../../redux/actions/cart.actions";
import { setCartComment } from "../../redux/actions/cart-comment.actions";
import { saveOrder } from "../../services/order.service";
import { showToast } from "../../helpers/toast.helper";

const ConfirmationWarning = (props) => {
  let history = useHistory();
  const [orderId, setOrderId] = useState(-1);
  const [IdSucursal, setIdSucursal] = useState(-1);
  const [submitted, setSubmitted] = useState(false);

  const [userRole, setUserRole] = useState("basic");

  useEffect(() => {
    if (props?.match?.params?.id) {
      setOrderId(props.match.params.id);
    }
    if (props?.match?.params?.branchId) {
      setIdSucursal(props.match.params.branchId);
    }
    if (props?.userData?.permission?.type === "admin") {
      setUserRole("admin");
    }
  }, [props?.match?.params, props?.userData]);

  const onSubmit = async () => {
    setSubmitted(true);

    if (props.cartItems && props.cartItems.length === 0) {
      showToast("El carrito esta vacío", "info");
      setTimeout(() => {
        setSubmitted(false);
      }, 200);
    }

    if (
      orderId !== -1 &&
      IdSucursal !== -1 &&
      props?.userData?.IdCompany &&
      props?.userData?.id &&
      props.cartItems &&
      props.cartItems.length > 0
    ) {
      const data = {
        IdCompany: props.userData.IdCompany,
        IdUser: props.userData.id,
        IdSucursal: IdSucursal,
        cart: JSON.stringify(props.cartItems),
        Order_number: orderId,
        comment: props?.cartComment ? props.cartComment : "",
      };

      const res = await saveOrder(data);
      setSubmitted(false);
      if (res && Object.keys(res).length > 0 && res?.id) {
        props.emptyCart([]);
        props.setCartComment("");
        setTimeout(() => {
          if (userRole === "admin") {
            history.push({
              pathname: `/admin/order/successfull-confirmation/${orderId}/${res.id}`,
            });
          } else {
            history.push({
              pathname: `/order/successfull-confirmation/${orderId}/${res.id}`,
            });
          }
        }, 500);
      }
    }
  };
  return (
    <>
      <section className="section preorder-sec">
        <div className="container">
          <div className="wrapper">
            <div className="pre-order-wrap">
              <div className="title">
                <h4>Pre-orden de compra #{orderId}</h4>
              </div>
              <div className="date">{moment().format("Do MMMM YYYY")}</div>
            </div>
          </div>
        </div>
      </section>
      <section className="section cancel-order gray-bg confirmation-warning-sec">
        <div className="container">
          <div className="wrapper">
            <div className="cancel-order-wrap">
              <div className="box">
                <div className="title">¡AVISO!</div>
                <div className="content">
                  Este documento es una pre-orden de compra, para poder procesar
                  tu pedido es necesaria la aprobación por tu unidad de negocio
                </div>
                <div className="submit-btn">
                  <button
                    type="button"
                    className="blue-btn download-pdf-btn"
                    onClick={(e) => onSubmit()}
                    disabled={submitted}
                  >
                    {submitted && props.loading && (
                      <i className="fa fa-spinner fa-spin button-loader"></i>
                    )}
                    Entendido
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = ({
  auth: { user_details },
  cart: { items },
  general: { loading },
  cartComment,
}) => ({
  userData: user_details,
  cartItems: items,
  loading,
  cartComment,
});
const mapDispatchToProps = (dispatch) => ({
  emptyCart: (cartItems) => dispatch(emptyCart(cartItems)),
  setCartComment: (comment) => dispatch(setCartComment(comment)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationWarning);
// export default ConfirmationWarning;
