import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { constants } from "../../app-config";

const BannerLogo = ({ userData, logoutUser }) => {
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: true,
    slidesToScroll: 1,
    // className: "slides",
  };
  const [logo, setLogo] = useState("");
  const [bannerCatalog, setBannerCatalog] = useState([]);
  const [color, setColor] = useState("");
  const [employeeNumber, setEmployeeNumber] = useState(-1);

  useEffect(() => {
    if (Object.keys(userData).length) {
      if (userData?.employee_number) {
        setEmployeeNumber(userData.employee_number);
      }
      if (userData?.company?.logo) {
        setLogo(userData.company.logo);
      }
      if (userData?.company?.color) {
        setColor(userData.company.color);
      }
      if (userData?.company?.banner_catalog) {
        if (typeof userData.company.banner_catalog == "string") {
          const array = JSON.parse(userData.company.banner_catalog);
          array.length > 0 ? setBannerCatalog(array) : setBannerCatalog([]);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="section banner-section">
      <div className="container">
        <div className="wrapper">
          <div className="row">
            <div className="col welcome-box-wrap">
              <div
                className="welcome-box"
                style={{ backgroundColor: color !== "" ? color : "#e21c2a" }}
              >
                <div className="title">
                  <h4>Bienvenido:</h4>
                  <p>{employeeNumber !== -1 ? employeeNumber : 0}</p>
                </div>
                <div className="image">
                  {logo !== "" ? (
                    <img src={`${constants.BACKEND_URL}${logo}`} alt="Logo" />
                  ) : (
                    <img src="/assets/images/logo.png" alt="Logo" />
                  )}
                </div>
                <div className="link">
                  <a href="##" onClick={logoutUser}>
                    Cerrar sesión
                  </a>
                </div>
              </div>
            </div>
            <div className="col banner-slider-wrap">
              <div className="banner-slider-box">
                <div className="banner-slider">
                  {bannerCatalog.length > 0 ? (
                    <Slider {...settings}>
                      {bannerCatalog.map((e, index) => {
                        return (
                          <div key={index}>
                            <img src={`${constants.BACKEND_URL}${e}`} alt="" />
                          </div>
                        );
                      })}
                    </Slider>
                  ) : (
                    <img src="/assets/images/logo-white.png" alt="" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerLogo;
