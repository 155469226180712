import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { Spinner } from "../spinner/spinner";

const TableComponent = (props) => {
  const pagination = useRef();
  const [headerData, setHeaderData] = useState([]);
  const [bodyData, setBodyData] = useState([]);
  const [page, setPage] = useState(1);
  const [filterData, setFilterData] = useState(null);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      await props.onChange(page, filterData);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (props.headerData.length > 0) {
      setHeaderData([...props.headerData]);
    }
    if (props.bodyData.length > 0) {
      setBodyData([...props.bodyData]);
    }
    if (props.bodyData.length === 0) {
      setBodyData([]);
    }
    if (props.limit !== undefined) {
      setLimit(props.limit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const filterDataFunction = async (dataFilter) => {
    await props.onChange(page, dataFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <div className={props.divClassName === "" ? "" : "general-wrapper"}>
      <div className={props.divClassName === "" ? "" : "general-setting-wrap"}>
        <div className="title">{props.title}</div>
        <div className="users-page">
          {props.link ? (
            <div className="admin-company-wrap pb-15">
              {props.link}
              {props.search && props.search === true && (
                <div className="search-company">
                  <div className="form-group">
                    <i className="fa fa-search"></i>
                    <input
                      type="text"
                      name="search_company"
                      placeholder="Buscar"
                      onChange={(e) => {
                        filterDataFunction(e.target.value);
                        setFilterData(e.target.value);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            ""
          )}

          <div
            className={
              props.divClassName === ""
                ? props.allowUserClass !== true
                  ? ""
                  : "users-table"
                : "users-table"
            }
          >
            {props.loading ? <Spinner /> : ""}
            <table>
              <thead>
                <tr>
                  {headerData.map((e, index) => (
                    <th key={index}>{e.header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {limit >= bodyData.length &&
                  bodyData.length !== 0 &&
                  bodyData.map((row, index) => (
                    <tr key={`row-${index}`}>
                      {headerData.map((e, index) => {
                        return (
                          <td
                            key={index}
                            className={`${e.className ? e.className : ""}`}
                            onClick={(e1) =>
                              e.onclickEvent ? e.onclickEvent(row.id) : null
                            }
                          >
                            {e.cell ? (
                              e.cell(row)
                            ) : (
                              <div>{row[e.name] ? row[e.name] : "-"}</div>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
              </tbody>
            </table>{" "}
          </div>

          {bodyData.length === 0 &&
            filterData === null &&
            !props.loading &&
            props.noData}
          {filterData !== null &&
            bodyData.length === 0 &&
            !props.loading &&
            props.searchNoData}
          {limit >= bodyData.length &&
          bodyData.length !== 0 &&
          props.allDataShow !== true &&
          props?.paginationShow !== false ? (
            <ReactPaginate
              ref={pagination}
              pageCount={Math.ceil(props.dataCount / limit)}
              pageRangeDisplayed={4}
              marginPagesDisplayed={1}
              onPageChange={(e) => {
                setPage(e.selected + 1);
              }}
              containerClassName="pagination"
              activeClassName="active"
              pageLinkClassName="page-link"
              breakLinkClassName="page-link"
              nextLinkClassName="page-link"
              previousLinkClassName="page-link"
              pageClassName="page-item"
              breakClassName="page-item"
              nextClassName="page-item"
              previousClassName="page-item"
              previousLabel={<>&laquo;</>}
              nextLabel={<>&raquo;</>}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ general: { loading } }) => ({
  loading,
});
export default connect(mapStateToProps, null)(TableComponent);
