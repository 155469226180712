import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import { sendSolution } from "../../services/soution.service";

const Solutions = (props) => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const initials = {
    user: "",
    telephone: "",
    event_type: "",
    uniform_require: "",
    additional_information: "",
    image: "",
  };
  const [formToggle, setFormToggle] = useState(false);
  const [afterSubmitToggle, setAfterSubmitToggle] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const SolutionsSchema = Yup.object().shape({
    user: Yup.string().required("Este campo es obligatorio"),
    telephone: Yup.string()
      .matches(phoneRegExp, "El número de teléfono no es válido")
      .required("Este campo es obligatorio"),
    event_type: Yup.string().required("Este campo es obligatorio"),
    // uniform_require: Yup.string().required("Este campo es obligatorio"),

    uniform_require: Yup.number()
      .required("Este campo es obligatorio")
      .test("Is positive?", "Solo número permitido", (value) => value > 0),
  });

  const onSubmit = async (values) => {
    setSubmitted(true);
    const formData = new FormData();
    formData.append("IdUser", props?.userData?.id);
    formData.append("user", values.user);
    formData.append("telephone", values.telephone);
    formData.append("event_type", values.event_type);
    formData.append("uniform_require", values.uniform_require);
    formData.append("additional_information", values.additional_information);
    if (values.image[0]) formData.append("image", values.image[0]);

    const res = await sendSolution(formData);
    setSubmitted(false);
    if (res && res.status === "success") {
      setAfterSubmitToggle(true);
    }
  };

  return (
    <>
      <section className="section inner-banner-sec gdi-solution-banner">
        <div className="container">
          <div className="wrapper">
            <div className="inner-banner-wrap">
              <div
                className="inner-banner"
                style={{
                  backgroundImage: `url(/assets/images/gdi-sollution-banner.png)`,
                }}
              >
                <div className="inner-banner-title">
                  <h4>¿Tienes una solicitud especial de uniformes?</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section gdi-solution-sec">
        <div className="container">
          <div className="wrapper">
            <div className="gdi-solutionwrap">
              <div className="title">
                <h4>Soluciones GDI</h4>
                <div className="subtitle">
                  En caso de que necesites uniformes para un evento,
                  conferencia, congreso, etcétera, te tenemos cubierto con dos
                  opciones
                </div>
              </div>
              <div className="gdi-option-wrap">
                <div className="gdi-option-grid">
                  <div className="title">
                    <h4>Opción 1</h4>
                  </div>
                  <div className="option-box">
                    <div className="option-box-inner">
                      <div className="content-wrap">
                        <h6>BravoUniforms</h6>
                        <p>
                          Visita nuestra tienda en linea Bravo Uniforms, aquí
                          encontrarás todo tipo de prendas que podrás
                          personalizar directamente en el sitio.
                        </p>
                      </div>
                      {/* <div className="note">
                        No olvides que al ser cliente GDI disfrutarás de un
                        descuento especial
                      </div> */}
                    </div>
                    <div className="fill-form">
                      <div className="image">
                        <img src="/assets/images/bravouniforms.png" alt="" />
                      </div>
                      <a
                        href="https://bravouniforms.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="icon">
                          <i className="fa fa-arrow-circle-o-right"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gdi-option-grid">
                  <div className="title">
                    <h4>Opción2</h4>
                  </div>
                  {!formToggle && !afterSubmitToggle && (
                    <div className="option-box form-option-box">
                      <div className="option-box-inner">
                        <div className="content-wrap">
                          <h6>Uniformes especiales GDI</h6>
                          <p>
                            En caso de que en Bravo Uniforms no hayas encontrado
                            lo que buscabas, en GDI contamos con el servicio de
                            creación de uniformes para eventos especiales.
                          </p>
                          <p>
                            Solamente llena el formulario y un asesor te
                            contactará a la brevedad
                          </p>
                        </div>
                      </div>
                      <div className="fill-form">
                        <div className="link">
                          <a href="##">Llenar formulario</a>
                        </div>
                        <div
                          className="icon gdi-solutionform-open"
                          onClick={(e) => {
                            setFormToggle(true);
                          }}
                        >
                          <i className="fa fa-arrow-circle-o-down"></i>
                        </div>
                      </div>
                    </div>
                  )}
                  {formToggle && !afterSubmitToggle && (
                    <div className="gdi-form-wrap">
                      <div className="gdi-solutionform">
                        <Formik
                          initialValues={initials}
                          onSubmit={onSubmit}
                          validationSchema={SolutionsSchema}
                        >
                          {({ errors, touched, values, setFieldValue }) => (
                            <Form>
                              <div className="form-group">
                                <label>Usuario</label>
                                <Field
                                  name="user"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.user && touched.user
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Sofía"
                                />
                                <ErrorMessage
                                  name="user"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>

                              <div className="form-group">
                                <label>Teléfono</label>
                                <Field
                                  name="telephone"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.telephone && touched.telephone
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="56788967"
                                />
                                <ErrorMessage
                                  name="telephone"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>

                              <div className="form-group">
                                <label>Tipo de evento</label>
                                <Field
                                  name="event_type"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.event_type && touched.event_type
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Convencion"
                                />
                                <ErrorMessage
                                  name="event_type"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>

                              <div className="form-group">
                                <label>¿Cuántos uniformes requieres?</label>
                                <Field
                                  name="uniform_require"
                                  type="number"
                                  min={1}
                                  className={
                                    "form-control" +
                                    (errors.uniform_require &&
                                    touched.uniform_require
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="250"
                                />
                                <ErrorMessage
                                  name="uniform_require"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>

                              <div className="form-group">
                                <label>imágenes uniformes</label>
                                {values.image !== "" && (
                                  <div
                                    className="close-icon-solution cursor-pointer"
                                    onClick={(e) => setFieldValue("image", "")}
                                  >
                                    <i className="fa fa-times"></i>
                                  </div>
                                )}

                                <Dropzone
                                  accept=".pdf,.jpeg,.jpg,.png"
                                  multiple={false}
                                  onDrop={(acceptedFiles) => {
                                    const imageDataOnDrop = acceptedFiles.map(
                                      (file) =>
                                        Object.assign(file, {
                                          preview: URL.createObjectURL(file),
                                        })
                                    );

                                    setFieldValue("image", imageDataOnDrop);
                                  }}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div className="hover-icon">
                                      <div
                                        className="drop-box"
                                        {...getRootProps()}
                                      >
                                        <input {...getInputProps()} />
                                        {values.image === "" &&
                                          "Drag and drop files or click to select"}

                                        <aside>
                                          {values.image &&
                                            values.image.map((file, index) => {
                                              return (
                                                <div
                                                  className="dz_img_wrp"
                                                  key={file.name}
                                                >
                                                  <div className="thumb">
                                                    <div className="thumb-inner">
                                                      <img
                                                        src="/assets/images/file-icon.png"
                                                        alt="itemImage"
                                                      />
                                                    </div>
                                                    <p className="thumb-file-name">
                                                      {file.name}
                                                    </p>
                                                  </div>
                                                </div>
                                              );
                                            })}
                                        </aside>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                              </div>

                              <div className="form-group">
                                <label>Información adicional</label>
                                <Field
                                  name="additional_information"
                                  as="textarea"
                                  rows="10"
                                  placeholder="Escribe aquí tu mensaje"
                                />
                              </div>

                              <div className="submit-btn">
                                <button
                                  type="submit"
                                  name="gdi-solution-btn"
                                  disabled={submitted}
                                >
                                  {submitted && (
                                    <i className="fa fa-spinner fa-spin button-loader"></i>
                                  )}
                                  Enviar
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  )}

                  {afterSubmitToggle && (
                    <div className="option-box success-msg">
                      <div className="option-box-inner">
                        <div className="content-wrap">
                          <h6>iGracias!</h6>
                          <p>
                            En breve un ejecutivo se pondra en contacto contigo
                            para poder crear un proyecto a la medida de tus
                            necesidades{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapToStateProps = ({ auth: { user_details } }) => ({
  userData: user_details,
});
export default connect(mapToStateProps, null)(Solutions);
