import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import Select from "react-select";
import { Spinner } from "../../components/spinner/spinner";
import { getOrders } from "../../services/order.service";
import { getBranchOption } from "../../services/branches.service";
import { getYears } from "../../helpers/getYear.helper";

const BrandOrders = (props) => {
  const [ordersData, setOrdersData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, SetTotal] = useState(0);
  const [companyID, setCompanyID] = useState(-1);
  const [sucursalSelectOption, setSucursalSelectOption] = useState([]);
  const [yearSelectOption, setYearSelectOption] = useState([]);
  const [filter, setFilter] = useState([]);

  let limit = 6;

  useEffect(() => {
    if (props?.userData?.IdCompany) {
      setCompanyID(props.userData.IdCompany);
    }

    if (props?.userData?.company?.created_at) {
      const year = getYears(props.userData.company.created_at);
      if (year && year.length > 0) {
        let tempYear = [];
        year.forEach((e) => {
          tempYear.push({ label: e, value: e });
        });
        setYearSelectOption(tempYear);
      }
    }
  }, [props?.userData]);

  useEffect(() => {
    if (companyID !== -1) {
      getBranchOptions(companyID);
    }
    if (page === 1) {
      setOrdersData([]);
    }
    if (companyID !== -1) {
      getAllOrders(page, filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filter, companyID]);

  const getBranchOptions = async (id) => {
    const res = await getBranchOption({ company: parseInt(id) });
    if (res && res.length > 0) {
      const comboValue = res.map((e) => {
        return {
          label: e.Name,
          value: e.IdSucursal,
          custom: "sucursal_id",
        };
      });
      setSucursalSelectOption(comboValue);
    } else {
      setSucursalSelectOption([]);
    }
  };

  const getAllOrders = async (page) => {
    const res = await getOrders({
      page: page,
      limit: limit,
      company_id: companyID,
      sortby: JSON.stringify(filter),
    });
    if (
      res &&
      Object.keys(res).length > 0 &&
      res?.orders &&
      res?.total &&
      page >= 1
    ) {
      setOrdersData((orders) => [...orders, ...res.orders]);
      SetTotal(res.total);
    } else {
      setOrdersData([]);
      SetTotal(0);
    }
  };

  const loadMore = () => {
    setPage((page) => page + 1);
  };

  return (
    <div className="orders-container order-loder-height">
      <div className="container">
        <div className="general-setting-wrap listing-order-section">
          <div className="search-filter">
            <div className="search-filter-wrap">
              <div className="search-filter-title">Filtra tu búsqueda por:</div>
              <div className="filter-form">
                <Select
                  options={[
                    {
                      label: "Sucursales",
                      options: sucursalSelectOption,
                    },
                    {
                      label: "Año",
                      options: yearSelectOption,
                    },
                  ]}
                  placeholder="Sofía Torres"
                  onChange={(e) => {
                    setPage(1);
                    let data = {};
                    data[e.custom] = e.value;
                    setFilter([data]);
                  }}
                />
              </div>
            </div>
          </div>

          {props.loading ? <Spinner /> : ""}
          {ordersData && ordersData.length > 0 ? (
            <div className="puchase-order">
              <div className="admin-purchase-order">
                {ordersData.map((data, index) => (
                  <div className="purchase-order-item" key={data.IdOrder}>
                    <div className="item-box">
                      <div className="order-box">
                        <h5>Orden</h5>
                        <div className="id">#{data.Order_number}</div>
                      </div>
                      <div className="order-detail-box">
                        <div className="order-date">
                          <div className="date">
                            {moment(data.Creation_date)
                              .locale("es")
                              .format("LL")}
                          </div>
                          <div className="order-img">
                            {data.Active !== 0 ? (
                              data.Active === 1 ? (
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/approved.png`}
                                  alt="approved"
                                />
                              ) : (
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/not-approved.png`}
                                  alt="not-approved"
                                />
                              )
                            ) : (
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/pending.png`}
                                alt="pending"
                              />
                            )}
                          </div>
                        </div>
                        <div className="name">{data?.user?.userName}</div>
                        <div className="account">
                          Cuenta: {data?.company?.Name}
                        </div>
                        <div className="detail">
                          Detalles: {data?.total_products} productos
                        </div>
                        <div className="final-total">
                          Total final: ${parseFloat(data?.total).toFixed(2)}
                        </div>
                        <div className="see-order">
                          {/* <Link to={`/order/admin/${data.IdOrder}/${companyID}`}>
                          Ver orden +
                        </Link> */}
                          <Link
                            to={`/admin/brand-orders/${data.IdOrder}/${data.Order_number}`}
                          >
                            Ver orden +
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {total && Math.ceil(total / limit) > page ? (
                <div className="see-more text-center">
                  <a
                    href="##"
                    onClick={(e) => {
                      e.preventDefault();
                      loadMore();
                    }}
                  >
                    Ver más <i className="fa fa-chevron-down"></i>
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            !props.loading && (
              <div className="no-puchase-order">
                <img src="/assets/images/order-purchase.png" alt="" />
                <div className="content">Aún no existen ordenes de compra</div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth: { user_details }, general: { loading } }) => ({
  userData: user_details,
  loading: loading,
});

export default connect(mapStateToProps, null)(BrandOrders);
