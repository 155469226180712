import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { connect } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import { getProduct } from "../../services/catalogue.service";
import { addToCart } from "../../redux/actions/cart.actions";
import { Spinner } from "../../components/spinner/spinner";

const CatalogueDetail = (props) => {
  let history = useHistory();

  // const [productId, setProductId] = useState(-1);
  const [productData, setProductData] = useState({});
  const [productPhoto, setProductPhoto] = useState([]);
  const [quantityOption, setQuantityOption] = useState([]);
  const [tala, setTala] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [quantity, setQuantity] = useState([]);
  const [productCart, setProductCart] = useState([]);
  const [addCartToggle, setAddCartToggle] = useState(false);
  const [tallaSelect, setTallaSelect] = useState([]);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const [searchVal, setSearchVal] = useState("");
  const [userRole, setUserRole] = useState("basic");

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    asNavFor: ".slider-nav",
    dots: true,
  };

  const settingsThumbs = {
    slidesToShow: productPhoto.length > 0 ? productPhoto.length : 5,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    dots: true,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "10px",
    vertical: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          vertical: false,
        },
      },
    ],
  };

  const relatedProductSlider = {
    className: "center",
    infinite: true,
    slidesToShow: 4,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (props?.match?.params?.productId) {
      // setProductId(props.match.params.productId);
      ProductDetail(props.match.params.productId);

      let tempArray = [...quantityOption];
      for (let i = 0; i <= 50; i++) {
        tempArray.push(i);
      }
      setQuantityOption(tempArray);
    }
    if (props?.userData?.permission?.type === "admin") {
      setUserRole("admin");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params]);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slider1, slider2]);

  const ProductDetail = async (id) => {
    const res = await getProduct({ id: id });
    if (Object.keys(res).length > 0) {
      setProductData(res);
      if (res?.Photos) {
        if (typeof res?.Photos == "string") {
          const array = res.Photos.split(",");
          array.length > 0 ? setProductPhoto(array) : setProductPhoto([]);
        }
      } else {
        setProductPhoto([]);
      }

      if (res?.product_details) {
        let tempArray = [];
        res.product_details.forEach((e) => {
          if (e.hasOwnProperty("Talla")) {
            tempArray.push(e.Talla);
          }
        });
        setTala(tempArray);
      }
    }
  };

  const handleTallaCant = (cant, talla, productData, mainTalla) => {
    const tempArray = [...quantity];
    let obj = {};

    if (tempArray.length > 0) {
      const index = tempArray.findIndex((e) => e.hasOwnProperty(talla));
      if (index !== -1) {
        tempArray.splice(index, 1);
      }
      obj[`${talla}`] = cant;
      tempArray.push(obj);
    } else {
      obj[`${talla}`] = cant;
      tempArray.push(obj);
    }

    let array = [];
    for (const key in tempArray) {
      for (const value in tempArray[key]) {
        array.push(tempArray[key][value]);
      }
    }

    array = array.filter((e) => e !== "");
    if (array.length > 0) {
      let total = 0;
      array.forEach((e) => {
        total = parseInt(e) + total;
      });
      const priceSubTotal = parseFloat(
        total * parseFloat(productData.Pricing).toFixed(2)
      ).toFixed(2);
      setSubTotal(priceSubTotal);
    }

    if (array.length === 0) {
      setSubTotal(0);
    }

    const tempProductCart = [...productCart];

    const productIndex = tempProductCart.findIndex((e) => e.Talla === talla);
    if (productIndex !== -1) {
      tempProductCart.splice(productIndex, 1);
      if (cant !== "0") {
        tempProductCart.push({
          Talla: talla,
          Cantidad: parseInt(cant),
          IdProduct: productData.IdProduct,
          Precio: productData.Pricing,
          Name: productData.Name,
          Sku: productData.SKU,
          Type: productData.Type,
          subTotal: parseFloat(
            parseInt(cant) * parseFloat(productData.Pricing).toFixed(2)
          ).toFixed(2),
          IdCompanyGDI: productData.IdCompanyGDI,
          Clave_GDI: productData.Clave,
          ProductImage: productPhoto[0],
          TallOptions: tala,
        });
      }
    } else {
      if (cant !== "0") {
        tempProductCart.push({
          Talla: talla,
          Cantidad: parseInt(cant),
          IdProduct: productData.IdProduct,
          Precio: productData.Pricing,
          Name: productData.Name,
          Sku: productData.SKU,
          Type: productData.Type,
          subTotal: parseFloat(
            parseInt(cant) * parseFloat(productData.Pricing).toFixed(2)
          ).toFixed(2),
          IdCompanyGDI: productData.IdCompanyGDI,
          Clave_GDI: productData.Clave,
          ProductImage: productPhoto[0],
          TallOptions: tala,
        });
      }
    }

    const tempTalla = [...tallaSelect];
    if (!tempTalla.includes(talla)) {
      tempTalla.push(talla);
    }

    setTallaSelect(tempTalla);
    setProductCart([...tempProductCart]);
    setQuantity([...tempArray]);
  };

  const searchHandler = () => {
    if (searchVal !== "") {
      let pathname = "/catalogue";
      if (userRole === "admin") pathname = "/admin/catalogue";
      history.push({
        pathname,
        state: { searchVal },
      });
    }
  };

  const handleAddToCart = (productData) => {
    let tallaOptions = [];
    let productCart = [];
    if (
      productData?.product_details &&
      productData.product_details.length > 0
    ) {
      productData.product_details.forEach((e) => {
        if (e.hasOwnProperty("Talla")) {
          tallaOptions.push(e.Talla);
        }
      });
    }
    if (tallaOptions.length > 0) {
      tallaOptions.forEach((e) => {
        productCart.push({
          Cantidad: 1,
          Clave_GDI: 0,
          IdProduct: productData.IdProduct,
          IdCompanyGDI: props?.userData?.company?.company_gdi,
          Name: productData.Name,
          Precio: productData.Pricing,
          ProductImage: productData.Photos,
          Type: productData.Type,
          TallOptions: tallaOptions,
          Talla: e,
          subTotal: parseFloat(1 * parseFloat(productData.Pricing).toFixed(2)),
        });
      });
    }

    if (props?.userData?.company?.company_gdi && productCart.length > 0) {
      props.addCart(props.items, productCart);

      setTimeout(() => {
        userRole === "admin"
          ? history.push({
              pathname: "/admin/cart/products",
            })
          : history.push({ pathname: "/cart/products" });
      }, 200);
    }
  };

  return (
    <>
      {/* search and cart section */}
      <section className="section product-section product-detail-sec">
        <div className="container">
          <div className="wrapper">
            <div className="product-topbar">
              <div className="breadcrumb">
                <div className="search-form">
                  <form className="catalogue-search-btn">
                    <div className="form-group">
                      <div className="search-icon">
                        <i className="fa fa-search"></i>
                      </div>
                      <input
                        type="text"
                        name="search"
                        onChange={(e) => {
                          setSearchVal(e.target.value);
                        }}
                        value={searchVal}
                      />
                    </div>
                    <div
                      className="btn contact-btn"
                      onClick={(e) => {
                        searchHandler();
                      }}
                    >
                      <div className="search-class">Buscar</div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="product-searchbar">
                <div className="cart">
                  <img
                    src="/assets/images/cart.png"
                    alt=""
                    onClick={(e) => {
                      userRole === "admin"
                        ? history.push({
                            pathname: "/admin/cart/products",
                          })
                        : history.push({
                            pathname: "/cart/products",
                          });
                    }}
                  />
                </div>
              </div>
            </div>

            {props.loading ? (
              <div className="spinner-position">
                <Spinner />
              </div>
            ) : (
              ""
            )}

            {Object.keys(productData).length > 0 && !props.loading && (
              <>
                <div className="single-product">
                  <div className="single-product-wrap">
                    <div className="single-product-slider-wrap">
                      <div className="thumb-silder-wrap">
                        {productPhoto.length > 0 ? (
                          <Slider
                            {...settingsThumbs}
                            asNavFor={nav1}
                            ref={(slider) => setSlider2(slider)}
                          >
                            {productPhoto.map((slide, index) => {
                              return (
                                <div className="thumb-silder" key={index}>
                                  <img src={slide} alt="" />
                                </div>
                              );
                            })}
                          </Slider>
                        ) : (
                          <div
                            className="thumb-silder"
                            style={{
                              backgroundImage: `url(/assets/images/product-item.jpg)`,
                            }}
                          ></div>
                        )}
                      </div>

                      {productPhoto.length > 0 ? (
                        <div className="product-slider-wrap">
                          <div className="product-slider-inner">
                            <Slider
                              {...settingsMain}
                              asNavFor={nav2}
                              ref={(slider) => setSlider1(slider)}
                            >
                              {productPhoto.map((slide, index) => (
                                <div key={index} className={"product-slider"}>
                                  <img src={`${slide}`} alt="" />
                                </div>
                              ))}
                            </Slider>
                          </div>
                        </div>
                      ) : (
                        <div className="product-slider-wrap">
                          <div className={"product-slider"}>
                            <img
                              src={`/assets/images/product-item.jpg`}
                              alt=""
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="single-product-content-wrap">
                      <div className="single-product-content">
                        <div className="content-wrap">
                          <div className="title">
                            <h4>{productData.Name}</h4>
                          </div>
                          <div className="sub-title"></div>
                          <div className="unit-price">
                            Precio unitario: ${productData.Pricing} MXN
                          </div>
                        </div>

                        <div className="size-qty">
                          <div className="size-qty-wrap">
                            <div className="product-size">
                              <div className="title">Talla</div>
                              {tala && tala.length > 0
                                ? tala.map((e, index) => (
                                    <div
                                      key={index}
                                      className={`form-group
                                        ${
                                          tallaSelect.includes(e)
                                            ? "talla-select"
                                            : ""
                                        }`}
                                    >
                                      <input
                                        type="radio"
                                        id={index}
                                        name="size"
                                        value={e}
                                      />
                                      <label htmlFor="XCH">{e}</label>
                                    </div>
                                  ))
                                : ""}
                            </div>
                            <div className="product-qty">
                              <div className="title">Cant. </div>
                              {tala && tala.length > 0
                                ? tala.map((tallaData, index) => (
                                    <div className="form-group" key={index}>
                                      <input
                                        style={{
                                          width: "50px",
                                          height: "25px",
                                          marginBottom: "15px",
                                          textAlign: "center",
                                        }}
                                        type="number"
                                        min="0"
                                        max="999"
                                        name={tallaData}
                                        onKeyPress={(e) => {
                                          if (
                                            e.key === "-" ||
                                            e.key === "." ||
                                            e.key === "e"
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                        onChange={(e) => {
                                          handleTallaCant(
                                            e.target.value,
                                            tallaData,
                                            productData,
                                            tala
                                          );
                                        }}
                                      />
                                      {/* <select
                                        name={tallaData}
                                        onChange={(en) => {
                                          handleTallaCant(
                                            en.target.value,
                                            tallaData,
                                            productData,
                                            tala
                                          );
                                        }}
                                      >
                                        {quantityOption.length &&
                                          quantityOption.map(
                                            (quantityData, index1) => (
                                              <option
                                                value={quantityData}
                                                key={index1}
                                              >
                                                {quantityData}{' '}
                                              </option>
                                            )
                                          )}
                                      </select> */}
                                    </div>
                                  ))
                                : ""}
                            </div>
                            <div className="sub-total-box">
                              {addCartToggle === false && (
                                <div className="wrap">
                                  <div className="sub-total-content">
                                    <div className="title">Subtotal</div>
                                    <div className="bill-amount">
                                      {subTotal
                                        ? parseFloat(subTotal).toFixed(2)
                                        : 0}{" "}
                                      MXN
                                    </div>
                                  </div>
                                  <div className="submit-btn">
                                    {/* {productCart && productCart.length > 0 && ( */}
                                    <button
                                      name="bill"
                                      className={`blue-btn ${
                                        subTotal <= 0 ? "disabled" : ""
                                      }`}
                                      type="button"
                                      disabled={subTotal <= 0 ? true : false}
                                      onClick={(e) => {
                                        setAddCartToggle(true);
                                        props.addCart(
                                          props.items,
                                          productCart,
                                          tala
                                        );
                                      }}
                                    >
                                      Agregar a la orden
                                    </button>
                                    {/* )} */}
                                  </div>
                                </div>
                              )}
                              {addCartToggle && props.items.length > 0 && (
                                <div className="wrap">
                                  <div className="sub-total-content">
                                    <div className="title">
                                      Productos agregados exitosamente
                                    </div>
                                  </div>
                                  <div className="submit-btn">
                                    <Link
                                      to={
                                        userRole === "admin"
                                          ? `/admin/cart/products`
                                          : `/cart/products`
                                      }
                                    >
                                      <button
                                        name="bill"
                                        className="blue-btn"
                                        type="button"
                                      >
                                        Revisar orden
                                      </button>
                                    </Link>
                                  </div>
                                  <div className="submit-btn">
                                    <Link
                                      to={
                                        userRole === "admin"
                                          ? `/admin/catalogue`
                                          : `/catalogue`
                                      }
                                    >
                                      <button
                                        name="bill"
                                        className="blue-btn"
                                        type="button"
                                      >
                                        Seleccione mas productos
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="product-description">
                  <div className="row">
                    <div className="col desc-content">
                      <div className="description">
                        <h6>Descripción:</h6>
                        <p>
                          {productData.Description ? (
                            productData.Description
                          ) : (
                            <>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum
                            </>
                          )}
                        </p>
                      </div>
                      <div className="description">
                        <h6>Detalles:</h6>
                        <p>{productData.Detalles}</p>
                      </div>
                    </div>
                    <div className="col product-detail-box">
                      <div className="product-detail-boxWrap">
                        <ul>
                          <li>
                            <strong>Género:</strong> {productData.Gender}
                          </li>
                          <li>
                            <strong>Área:</strong> {productData.Area}
                          </li>

                          {productData.SKU !== 0 && (
                            <li>
                              <strong>SKU:</strong> {productData.SKU}
                            </li>
                          )}

                          {productData.Model !== "" && (
                            <li>
                              <strong>Clave GDI:</strong> {productData.Model}
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>

      {/* related products section */}
      <section className="section related-products gray-bg">
        <div className="container">
          <div className="wrapper">
            <div className="title">
              <h4>Productos relacionados</h4>
            </div>
            <div className="products">
              {Object.keys(productData).length > 0 &&
                productData?.related_product &&
                productData.related_product.length > 0 && (
                  <Slider {...relatedProductSlider}>
                    {productData.related_product.map(
                      (relatedProduct, index) => {
                        return (
                          <div className="product-item" key={index}>
                            <div className="item-box">
                              {relatedProduct?.Photos ? (
                                <img src={relatedProduct.Photos} alt="" />
                              ) : (
                                <img
                                  src="/assets/images/product-item.jpg"
                                  alt=""
                                />
                              )}
                              <div className="product-hover-box">
                                <div className="product-hover-box-wrap">
                                  <div className="hover-buttons">
                                    <button
                                      className="white-btn mb-20"
                                      onClick={(e) => {
                                        userRole === "admin"
                                          ? history.push({
                                              pathname: `/admin/catalogue/detail/${relatedProduct.IdProduct}`,
                                            })
                                          : history.push({
                                              pathname: `/catalogue/detail/${relatedProduct.IdProduct}`,
                                            });
                                        window.scrollTo({
                                          top: 0,
                                          behavior: "smooth",
                                        });
                                      }}
                                    >
                                      Ver detalles
                                    </button>

                                    <button
                                      className="blue-btn"
                                      onClick={(e) => {
                                        handleAddToCart(relatedProduct);
                                      }}
                                    >
                                      Añadir a la orden
                                    </button>
                                  </div>
                                  <div className="desc">
                                    La cantidad y tallas las podrás escoger en
                                    tu orden
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="product-detail">
                              <div className="product-name">
                                {relatedProduct.Name}
                              </div>
                              <div className="product-price">
                                ${relatedProduct.Pricing} MXN
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </Slider>
                )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = ({
  auth: { user_details },
  cart: { items },
  general: { loading },
}) => ({
  items,
  loading,
  userData: user_details,
});

const mapDispatchToProps = (dispatch) => ({
  addCart: (cartItems, data) => dispatch(addToCart(cartItems, data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CatalogueDetail));
