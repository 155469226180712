import React from "react";
import Select from "react-select";

export const SelectBox = ({
  option,
  name,
  value,
  onChange,
  disabled = null,
  multiple = false,
}) => {
  return (
    <Select
      name={name}
      value={value ? option.filter((e) => e.value === value) : null}
      onChange={(e) => onChange(e.value)}
      options={option}
      isDisabled={disabled}
      isMulti={multiple ? true : false}
    />
  );
};
