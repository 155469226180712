export const addToCart = (items, product) => {
  return (dispatch) => {
    let cartItems = items;

    if (cartItems.length > 0) {
      product.forEach((e) => {
        const oldProduct = cartItems.find(
          (e1) => e1.IdProduct === e.IdProduct && e.Talla === e1.Talla
        );
        if (oldProduct) {
          oldProduct.Cantidad =
            parseInt(oldProduct.Cantidad) + parseInt(e.Cantidad);
          oldProduct.subTotal = parseFloat(
            parseFloat(oldProduct.Precio).toFixed(2) *
              parseInt(oldProduct.Cantidad)
          ).toFixed(2);
          // return oldProduct;
        } else {
          cartItems.push(e);
        }
      });
    } else {
      cartItems.push(...product);
    }

    // localStorage.setItem("cartItems", JSON.stringify(cartItems));
    dispatch({ type: "ADD_TO_CART", payload: { cartItems } });
  };
};

export const removeFromCart = (items, product) => (dispatch) => {
  let cartItems = [...items];
  let productData = { ...product };
  const index = cartItems.findIndex(
    (e) =>
      e.IdProduct === productData.IdProduct && e.Talla === productData.Talla
  );

  if (index !== -1) {
    cartItems.splice(index, 1);
    // localStorage.setItem("cartItems", JSON.stringify(cartItems));
    dispatch({ type: "REMOVE_FROM_CART", payload: { cartItems } });
  }
};

export const multipleRemoveFromCart = (items, product) => (dispatch) => {
  let cartItemsData = [...items];

  let cartItems = cartItemsData.filter((o1) =>
    product.every(
      (o2) => o1.IdProduct !== o2.IdProduct || o1.Talla !== o2.Talla
    )
  );

  // localStorage.setItem("cartItems", JSON.stringify(cartItems));
  dispatch({ type: "MULTIPLE_REMOVE_FROM_CART", payload: { cartItems } });
};

export const editFromCart = (items) => (dispatch) => {
  let cartItems = [...items];

  // localStorage.setItem("cartItems", JSON.stringify(cartItems));
  dispatch({ type: "EDIT_FROM_CART", payload: { cartItems } });
};

export const emptyCart = (cartItems) => (dispatch) => {
  // localStorage.setItem("cartItems", JSON.stringify(cartItems));
  dispatch({ type: "EMPTY_CART", payload: { cartItems } });
};
