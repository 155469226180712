import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import TableComponent from "../../../components/table/table";
import { getAdmins, deleteAdmins } from "../../../services/admin.service";
import { Modal } from "../../../components/modal/modal";

const Admins = (props) => {
  let history = useHistory();

  // const [id, setId] = useState(-1);
  const [adminData, setAdminData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [deleteAdmin, setDeleteAdmin] = useState([]);

  // useEffect(() => {
  //   if (props?.match?.params?.id) {
  //     setId(props.match.params.id);
  //   }
  // }, [props?.match?.params]);

  const getAllAdmins = async (page, filterData) => {
    const IdUser = props?.userData && props.userData?.id;
    const res = await getAdmins({
      page: page,
      search: "",
      IdUser: IdUser,
    });

    if (res && Object.keys(res).length > 0) {
      setAdminData(res.records);
      setDataCount(res.total);
    }
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  const deleteParticularAdmin = (userId, checked) => {
    if (checked) {
      setDeleteAdmin([...deleteAdmin, userId]);
    } else {
      const tempArray = [...deleteAdmin];
      const index = tempArray.findIndex((e) => e === userId);
      if (index !== -1) {
        tempArray.splice(index, 1);
        setDeleteAdmin(tempArray);
      }
    }
  };

  const deleteMultipleAdmins = async () => {
    let data = {
      users: deleteAdmin,
    };
    const res = await deleteAdmins(data);
    if (res.status === "success") {
      setShowModal(false);
      const tempUser = [...adminData];
      const data = tempUser.filter((e) => !deleteAdmin.includes(e.IdUser));
      setAdminData([...data]);
      setDeleteAdmin([]);
    }
  };
  const headerData = [
    {
      header: (
        <>
          {deleteAdmin.length > 0 ? (
            <img
              src="/assets/images/close-red.png"
              alt=""
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                setShowModal(true);
              }}
            />
          ) : (
            <img src="/assets/images/close-dark.png" alt="" />
          )}
        </>
      ),
      cell: (props) => {
        return (
          <div className="form-group">
            <input
              type="checkbox"
              onChange={(e) =>
                deleteParticularAdmin(props.IdUser, e.target.checked)
              }
              id={`${props.IdUser}`}
              checked={deleteAdmin.includes(props.IdUser)}
            />
            <label htmlFor={`${props.IdUser}`}></label>
          </div>
        );
      },
    },
    {
      header: "Nombre",
      name: "Name",
    },
    {
      header: "Mail",
      name: "Email",
    },
    {
      header: "Editar",
      cell: (props) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              history.push({
                pathname: `/admin/update/${props.IdUser}`,
              });
            }}
          >
            <img src="/assets/images/edit.png" alt="icon" />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <section className="section inner-banner-sec gdi-solution-banner">
        <div className="container">
          <div className="wrapper">
            <div className="inner-banner-wrap">
              <div
                className="inner-banner"
                style={{
                  backgroundImage: `url(/assets/images/inner-banner.png)`,
                }}
              >
                <div className="inner-banner-title">
                  <h4>¡Bienvenido a tu plataforma GDI!</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section admin-footer-margin">
        <div className="container">
          <div className="wrapper">
            <TableComponent
              title=""
              link={
                <>
                  <Link to={`/admin/create`}>
                    <div className="add-new-user">
                      Agregar usuario
                      <img src="/assets/images/add-user.png" alt="icon" />
                    </div>
                  </Link>
                  <Link to={`/`}>
                    <div className="return-home">
                      <i className="fa fa-arrow-circle-o-left"></i>Regresar al
                      home
                    </div>
                  </Link>
                </>
              }
              headerData={headerData}
              bodyData={adminData}
              onChange={getAllAdmins}
              dataCount={dataCount}
              search={false}
              divClassName={""}
              allowUserClass={true}
            />
            <Modal visible={showModal} id="photo-modal" onClose={onCloseModal}>
              <div className="choose-photo-popup">
                <div className="choose-photo-wrap popup-content-center">
                  <div className="title">
                    <h4>Eliminar administrador</h4>
                  </div>

                  <div className="selection-photo-grid">
                    <h6>¿Estás segura de que quieres eliminar esto?</h6>
                  </div>
                  <div className="link">
                    <a
                      href="#!"
                      className="btn blue-square-btn mr-1"
                      onClick={onCloseModal}
                    >
                      Cancelar
                    </a>
                    <a
                      href="#!"
                      className="btn blue-square-btn"
                      onClick={deleteMultipleAdmins}
                    >
                      Actualizar
                    </a>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </section>
    </>
  );
};

const mapToStateProps = ({ auth: { user_details } }) => ({
  userData: user_details,
});
export default connect(mapToStateProps, null)(Admins);
