import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { constants } from "../../app-config";
import {
  getOrder,
  getOrderPdf,
  getOrderExcel,
  // FileDelete,
  changeStatus,
  upLoadPago,
  saveSeguimiento,
  crearpago,
  saveStatusMP,
} from "../../services/order.service";
import { BrandInner } from "../brands/brand-inner/brand-inner";
import { Spinner } from "../../components/spinner/spinner";
import {
  getImagePath,
} from "../../helpers/general.helper";


import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'; 

//console.log('aqui va el cliente id');
//console.log(constants.MP_CLIENTE_ID);

initMercadoPago(constants.MP_PUBLIC_KEY,{ locale: 'es-MX' });

const ViewOrder = (props) => {
  // const token = localStorage.getItem("token");
  const [orderData, setOrderData] = useState();
  const [companyID, setCompanyID] = useState(-1);
  const [userId, setUserId] = useState(null);
  const [userRole, setUserRole] = useState("basic");
  const [clickedButton, setClickedButton] = useState(null);
  const [orderNumber, setOrderNumber] = useState(-1);
  const [loadingToggle, setLoadingToggle] = useState(false);
  const [loadingTogglePdf, setLoadingTogglePdf] = useState(false);
  const [loadingToggleExcel, setLoadingToggleExcel] = useState(false);
  const [reason, setReason] = useState("");
  //fmx
  const [file , setFile] = useState(null);
  // mecadopago 

  const [preferenceId, setPreferenceId] = useState(null);

  const queryParameters = new URLSearchParams(window.location.search);
  const mp_collection_id = queryParameters.get("collection_id");
  const mp_collection_estatus = queryParameters.get("collection_status");
  const mp_payment_id = queryParameters.get("payment_id");
  const mp_estatus     =  queryParameters.get("status");
  const mp_payment_type = queryParameters.get("payment_type");
  const mp_merchant_order_id = queryParameters.get("merchant_order_id");
  const mp_preference_id = queryParameters.get("preference_id");

  const refresh = () => window.location.reload(true);

 // console.log('datos de regreso');
  //console.log(mp_estatus);

  useEffect(() => {
    if (props?.match?.params?.orderId) {
      getOrderDetails(props.match.params.orderId);
    }

    if (props?.match?.params?.companyId) {
      setCompanyID(props.match.params.companyId);
    }

    if (props?.match?.params?.orderNumber) {
      setOrderNumber(props.match.params.orderNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.match?.params]);

  useEffect(() => {
    if (props.userData) {
      if (props.userData.id) {
        setUserId(props.userData.id);
      }
      if (props.userData.permission.type) {
        setUserRole(props.userData.permission.type);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userData]);

  const getOrderDetails = async (id) => {
    setLoadingToggle(true);
    const data = await getOrder(id);

    if ((mp_estatus === 'approved') || (mp_estatus === 'rejected') ){
     
      const mpdata= new FormData(); 
     
      mpdata.append('collection_id',mp_collection_id); 
      mpdata.append('collection_status',mp_collection_estatus);
      mpdata.append('payment_id',mp_payment_id);
      mpdata.append('status',mp_estatus);
      mpdata.append('payment_type',mp_payment_type);
      mpdata.append('merchant_order_id',mp_merchant_order_id);
      mpdata.append('preference_id',mp_preference_id);
  
      saveStatusMP(mpdata); 
   
    }
  
    
    setLoadingToggle(false);
    if (data) {
      setOrderData(data);
      setCompanyID(data.IdCompany);
    }
  };

 
 // console.log('aqui el companyID');
 // console.log(companyID);
 // console.log(constants.MP_CLIENTE_ID);

  const downloadPdf = async (id) => {
    setLoadingTogglePdf(true);
    const res = await getOrderPdf({ id: id });
    setLoadingTogglePdf(false);
    if (Object.keys(res).length > 0) {
      window.open(constants.BACKEND_URL + res.file_path, "_blank");
    }
  };
  const downloadExcel = async (id) => {
    setLoadingToggleExcel(true);
    const res = await getOrderExcel({ id: id });
    setLoadingToggleExcel(false);
    if (Object.keys(res).length > 0) {
      window.open(constants.BACKEND_URL + res.file_path, "_blank");
    }
  };

  const changeApproveStatus = async (IdOrder, status, approvedBy) => {
    await changeStatus({
      IdOrder,
      Active: status,
      actionBy: approvedBy,
      reason: reason,
    });
    if (userRole === "responsibeuser") {
      props.history.push("/responsibe-user/orders");
    } else if (userRole === "admin") {
      props.history.push("/admin/brand-orders");
    }
  };
  // const deleteFile = async (id) => {
  //   const res = await FileDelete({ id: parseInt(id) });
  //   if (res && Object.keys(res).length > 0) {
  //     SetExcelFile(res);
  //   }
  // };

  const setSeguimiento =  async(e) => {
   
    const fdata= new FormData(); 
   
    fdata.append('seguimiento',e.target.value); 
    fdata.append('id_order',orderData.IdOrder);
    const res = await saveSeguimiento(fdata); 
  //  console.log(res);
    getOrderDetails(orderData.IdOrder);

  }

  const crearPago =  async(e) => {
   
    const url_actual = window.location.href;

    const fdata= new FormData(); 
   
    fdata.append('id_order',orderData.IdOrder);
    fdata.append('total', parseFloat(orderData?.total).toFixed(2));
    fdata.append('url_actual', url_actual);
    const response = await crearpago(fdata); 
 
   // getOrderDetails(orderData.IdOrder);
   //const {CheckoutId} = response.id; 


   // console.log("id en view-orders");
   // console.log(response.id);

    setPreferenceId(response.id);

    return response.id;
    
  }

  const comprar = async()=>{
    const idCompra = crearPago();
   // console.log('id dentro de funcion comprar');
   
    if(idCompra){
    //  console.log(idCompra);
      
    }

  };

  const uploadPago = async (e) =>{
    //console.log(file);

    const fData = new FormData();
    fData.append('file',file);
    fData.append('id', orderData.IdOrder);
    const uploaded = await upLoadPago(fData);
   // console.log(uploaded);
    getOrderDetails(orderData.IdOrder);
  };
 
  return (
    <>
      {userRole === "superadmin" ? (
        <BrandInner
          buttonDisable={true}
          secondSectionDisplay={false}
          companyInnerId={companyID !== -1 ? companyID : ""}
        />
      ) : (
        ""
      )}
      {props.loading && loadingToggle ? (
        <div className="spinner-position">
          <Spinner />
        </div>
      ) : (
        ""
      )}

      {orderData && (
        <>
          <section className="section archive-order-sec">
            <div className="container">
              <div className="wrapper">
                <div className="purchase-order-wrap gray-bg">
                  <div className="row">
                    <div className="col purchase-order-content">
                      <div className="title">
                        <h4>Orden de compra #{orderData.Order_number}</h4>
                      </div>
                      <div className="order-detail">
                        <div className="created_at">
                          <strong>Fecha de creación:</strong>{" "}
                          {moment(orderData.Creation_date)
                            .locale("es")
                            .format("LL")}
                        </div>
                        <div className="creator_name">
                          <strong>Nombre del creador de la orden:</strong>{" "}
                          {orderData?.user?.Name}
                        </div>
                        <div className="sent_branch">
                          <strong>Envíado a la sucursal:</strong>{" "}
                          {orderData?.branch_office.Name}
                        </div>

                        <div className="approval-details">
                          <div className="status">
                            <strong>Estado: </strong>
                            {orderData?.Active === 0 && "Pendiente"}
                            {orderData?.Active === 1 && "Aprobada"}
                            {orderData?.Active === 2 && "Rechazada"}
                          </div>

                          {/* {userRole === "superadmin" &&
                          orderData?.Active !== 0 ? (
                            <div className="action-by">
                              {orderData?.Active === 1 && (
                                <strong>Aprobada por: </strong>
                              )}
                              {orderData?.Active === 2 && (
                                <strong>Rechazada por: </strong>
                              )}
                              {orderData?.user_action_by?.Name}
                            </div>
                          ) : (
                            ""
                          )} */}
                          {orderData?.Active !== 0 ? (
                            <div className="action-by">
                              {orderData?.Active === 1 && (
                                <strong>Aprobada por: </strong>
                              )}
                              {orderData?.Active === 2 && (
                                <strong>Rechazada por: </strong>
                              )}
                              {orderData?.user_action_by?.Name}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <label>
                          <strong> Estatus de Seguimiento </strong> 
                        </label> 

                        {userRole === "basic" && orderData.seguimiento === 1 &&
                            "RECEPCION PEDIDO"} 
                        {userRole === "basic" && orderData.seguimiento === 2 &&
                            "PENDIENTE DE PAGO"} 
                        {userRole === "basic" && orderData.seguimiento === 3 &&
                            "EN PRODUCCION"} 
                        {userRole === "basic" && orderData.seguimiento === 4 &&
                            "EMPAQUE"} 
                        {userRole === "basic" && orderData.seguimiento === 5 &&
                            "PEDIDO EN TRANSITO"} 
                        {userRole === "basic" && orderData.seguimiento === 6 &&
                            "ENTREGADO"} 
                       
                        
                        {userRole !== "basic" && 
                          
                          <div style={{width:'300px'}}> 
                            <select name="seguimiento" onChange={setSeguimiento} defaultValue={orderData.seguimiento}>
                              <option value="1">RECEPCION PEDIDO</option>
                              <option value="2">PENDIENTE DE PAGO</option>
                              <option value="3">EN PRODUCCION</option>
                              <option value="4">EMPAQUE </option>
                              <option value="5">PEDIDO EN TRANSITO</option>
                              <option value="6">ENTREGADO</option>
                            </select>
                            </div>
                        }
                                      
                      <div>
                      <strong>  Comprobante de Pago: </strong>
                        <div className="row">
                           
                              <div className="col order-pago">
                              {orderData.seguimiento < 3 &&
                                  <input 
                                  onChange={(e) => { setFile(e.target.files[0]) } }
                                  
                                  type="file"  accept="image/*, application/pdf" />
                                }
                              </div>
                              <div>
                              {orderData.seguimiento < 3 &&
                                <button className="btn"
                                onClick={uploadPago}
                                
                                >               
                                  Enviar </button>
                                  
                              }
                              </div>

                         
                          <div style={{ marginLeft: '20px', marginTop:'5px'}}> 


                          {orderData.comprobante && <a href= {getImagePath(orderData.comprobante)} target='_blank' rel="noreferrer" className="btn" style={{textAlign:'right'}} > Descargar </a> }

                          </div>

                        </div>
                      </div>

                        <div className="status row">
                          <div style={{ width:'40%', marginTop:'20px'}}>
                            <strong>MercadoPago: </strong>
                            {orderData?.status === 'approved' && "Aprobado"}
                            {orderData?.status === 'rejected' && "Rechazado"}
                          </div>
                          <div style={{ width:'40%', marginTop:'20px'}}>
                            <button className="btn" onClick={refresh}>Act. Estatus MercadoPago</button>
                          </div>
                        </div>
      

                          
                      </div>    

                    </div>
                    <div className="col purchase-order-img">
                      <div
                        className="img-box"
                        style={{ backgroundColor: orderData.company.Color }}
                      >
                        <img
                          src={`${constants.BACKEND_URL}${orderData.company.Logo}`}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section order-history gray-bg admin-footer-margin">
            <div className="container">
              <div className="wrapper">
                <div className="order-history-table-wrap">
                  <table className="order-history-table archive-order-table responsive-order-listing">
                    <thead>
                      <tr>
                        <th>Artículo</th>
                        <th>Talla</th>
                        <th>Cant.</th>
                        <th>Precio</th>
                        <th>Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderData.order_details.map((data) => (
                        <tr key={Math.random()}>
                          <td data-th="Artículo">
                            <div className="article-detail">
                              {data?.product?.Photos ? (
                                <div
                                  className="image"
                                  style={{
                                    backgroundImage: `url('${data.product.Photos}')`,
                                  }}
                                ></div>
                              ) : (
                                <div
                                  className="image"
                                  style={{
                                    backgroundImage: `url('/assets/images/product-item.jpg')`,
                                  }}
                                ></div>
                              )}

                              <div className="article-detail-content">
                                <div className="title">
                                  <h4>{data?.product?.Name}</h4>
                                </div>
                                <div className="subtitle">
                                  {data?.product?.Detalles}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td data-th="Talla">{data?.Talla}</td>
                          <td data-th="Cant.">{data?.Cantidad}</td>
                          <td data-th="Precio" className="price">
                            ${parseFloat(data?.Precio).toFixed(2)} MXN
                          </td>
                          <td data-th="Subtotal" className="subtotal">
                            ${parseFloat(data?.sub_total).toFixed(2)} MXN
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {userRole !== "superadmin" && (
                    <div className="conditional-comment">
                      <div className="title">
                        Agregar comentarios adicionales
                        {orderData?.comment && orderData.comment !== null ? (
                          <div className="text-area-comment">
                            <textarea
                              rows={10}
                              readOnly={true}
                              style={{ padding: "10px" }}
                              value={orderData.comment}
                            ></textarea>
                          </div>
                        ) : (
                          <div className="content-box">
                            <b>Sin comentarios</b>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {(userRole === "responsibeuser" || userRole === "admin") &&
                    orderNumber !== -1 &&
                    orderData?.reason === null &&
                    orderData?.actionBy == null && (
                      <div className="conditional-comment">
                        <div className="title">
                          motivo del rechazo / aprobación
                          <div className="text-area-comment">
                            <textarea
                              rows={10}
                              style={{ padding: "10px" }}
                              maxLength={350}
                              onChange={(e) => {
                                setReason(e.target.value);
                              }}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    )}

                  {orderData.actionBy && (
                    <div className="conditional-comment">
                      <div className="title">
                        motivo del rechazo / aprobación
                        {orderData?.reason && orderData.reason !== null ? (
                          <div className="text-area-comment">
                            <textarea
                              rows={10}
                              readOnly={true}
                              style={{ padding: "10px" }}
                              value={orderData.reason}
                            ></textarea>
                          </div>
                        ) : (
                          <div className="content-box">
                            <b>Sin razón</b>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="total-products-wrap">
                    <div className="total-products">
                      <div className="products-unit">
                        Productos:{" "}
                        <span>{orderData.total_products} unidades</span>
                      </div>
                      <div className="total">
                        Total:
                        <span>
                          {" "}
                          ${parseFloat(orderData?.total).toFixed(2)} MXN
                        </span>                        
                      </div>
                    </div>
                   
                    { ((companyID === parseInt(constants.MP_CLIENTE_ID )) && ( orderData?.status !== 'approved' )) && 
                        <div class="fmx-mercadopago">
                            
                            <button
                              type="button"
                              className="btn"
                            onClick = {comprar}>Proceder a Pago  </button>
                          
                            {preferenceId && <Wallet initialization={{ preferenceId }}  />}
                        </div>

                    }

                    <div className="submit-btn">
                      <button
                        type="button"
                        className="blue-btn"
                        onClick={(e) => {
                          downloadExcel(orderData.IdOrder);
                        }}
                      >
                        {loadingToggleExcel && (
                          <i className="fa fa-spinner fa-spin button-loader"></i>
                        )}
                        Descargar en XLSX
                      </button>
                      <button
                        type="button"
                        className="blue-btn download-pdf-btn"
                        onClick={(e) => {
                          downloadPdf(orderData.IdOrder);
                        }}
                      >
                        {loadingTogglePdf && (
                          <i className="fa fa-spinner fa-spin button-loader"></i>
                        )}
                        Descargar PDF
                      </button>
                    </div>

                    {((userId && userRole === "admin") ||
                      userRole === "responsibeuser") &&
                      orderNumber !== -1 &&
                      orderData.Active === 0 && (
                        <div className="approve-button">
                          <button
                            type="button"
                            className="green-btn"
                            onClick={(e) => {
                              if (!clickedButton) {
                                setClickedButton("approve");
                                changeApproveStatus(
                                  orderData.IdOrder,
                                  1,
                                  userId
                                );
                              }
                            }}
                          >
                            {props.loading && clickedButton === "approve" && (
                              <i className="fa fa-spinner fa-spin button-loader"></i>
                            )}
                            Aprobar PreOrden
                          </button>
                          <button
                            type="button"
                            className="red-btn"
                            onClick={(e) => {
                              if (!clickedButton) {
                                setClickedButton("reject");
                                changeApproveStatus(
                                  orderData.IdOrder,
                                  2,
                                  userId
                                );
                              }
                            }}
                          >
                            {props.loading && clickedButton === "reject" && (
                              <i className="fa fa-spinner fa-spin button-loader"></i>
                            )}
                            Rechazar PreOrder
                          </button>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ general: { loading }, auth: { user_details } }) => ({
  loading,
  userData: user_details,
});
// export default ViewOrder;
export default connect(mapStateToProps, null)(ViewOrder);
