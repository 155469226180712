import React from "react";

const TermCondition = () => {
  return (
    <>
      <section className="section policy-disclaimer-sec">
        <div className="container">
          <div className="wrapper">
            <div className="policy-disclaimer">
              <div className="title">
                <h4>Términos y condiciones</h4>
                <div className="subtitle">Última actualización: 02-10-2021</div>
              </div>
              <div className="contain">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipiscing elit, platea
                  sapien facilisis luctus nulla duis praesent sed, commodo
                  torquent dui nunc curabitur lacinia. Sociis cum sociosqu
                  fermentum fusce tortor hac vivamus curae semper ullamcorper
                  felis eleifend velit dictumst condimentum, ultricies enim quam
                  et nibh orci iaculis penatibus cras ridiculus pharetra per
                  blandit. Lacinia tortor cubilia praesent vestibulum hendrerit
                  cras congue, semper nec aenean malesuada facilisi mauris
                  laoreet litora, eget suspendisse condimentum nulla torquent
                  aliquam.
                </p>

                <p>
                  Sem convallis augue scelerisque gravida est egestas platea
                  ullamcorper, taciti faucibus varius neque potenti volutpat
                  nibh, aenean rutrum nisl eget consequat sollicitudin nisi.
                  Torquent commodo elementum hendrerit cum fusce vulputate
                  nullam fermentum, pretium sapien magna sociis netus mollis
                  placerat, faucibus himenaeos dignissim class scelerisque
                  blandit dictum. Dapibus ridiculus sollicitudin ut leo class,
                  aptent vestibulum odio malesuada habitant per, rhoncus ornare
                  pellentesque feugiat. Cras sagittis magna augue ultrices
                  cursus lectus class mauris venenatis, senectus malesuada
                  aliquam mattis vivamus ad ligula lacus vitae, tempus sed
                  praesent montes inceptos fusce eget quis.
                </p>

                <p>
                  Nisi auctor purus quis euismod proin donec convallis habitant
                  duis, pharetra lacus sociis quam senectus dis laoreet morbi
                  felis vitae, faucibus primis aptent malesuada praesent mi
                  magna dapibus. Condimentum taciti nulla hac massa porta urna
                  mi iaculis venenatis, blandit elementum ac lectus nullam eget
                  est inceptos, justo arcu pretium vel dapibus netus duis
                  tortor. Cubilia iaculis torquent ullamcorper ridiculus
                  interdum dignissim per ut tortor sodales est parturient
                  pharetra, fringilla tempus senectus sociosqu libero facilisi
                  orci praesent class massa nostra primis. Parturient arcu vel
                  curae hendrerit ornare lacus ultrices facilisi, class massa
                  vulputate bibendum natoque phasellus faucibus duis, ac dis nec
                  a nulla aenean tortor.
                </p>

                <p>
                  Augue integer dui cum suspendisse vel class ullamcorper
                  curabitur ultricies, hendrerit tristique duis eget tortor
                  natoque neque cursus a eros, porta quam placerat erat vehicula
                  senectus potenti sem. Eleifend sapien nisl congue ornare
                  consequat massa imperdiet primis eget, faucibus litora
                  pharetra nascetur vel aliquam euismod potenti tempus, blandit
                  facilisis duis at risus himenaeos ultricies scelerisque.
                  Sodales phasellus ultrices orci habitasse nostra molestie
                  lacinia turpis volutpat, neque rhoncus aliquet interdum litora
                  est nulla eros cursus justo, tristique lectus fermentum ornare
                  aptent dui cubilia in.
                </p>

                <p>
                  Condimentum lobortis platea tortor pharetra auctor metus non
                  aenean lectus conubia nam erat, blandit cursus gravida
                  vestibulum mollis luctus magnis convallis inceptos tempus
                  quisque. Neque posuere eget sapien erat varius et phasellus
                  cras est rutrum, lacinia facilisi maecenas dictum sem quisque
                  senectus eu magna turpis mauris, fames tincidunt parturient
                  tempor gravida etiam a dui sed. Scelerisque vestibulum vivamus
                  leo aptent cursus in sagittis rhoncus, mauris sociis inceptos
                  nisl imperdiet montes lectus pharetra sapien, ante etiam non
                  feugiat interdum hendrerit euismod.
                </p>

                <p>
                  Habitant dis hendrerit lectus turpis ullamcorper aenean fames,
                  cum id tempor porttitor feugiat magna commodo, pharetra
                  pellentesque rutrum ante placerat dignissim. Egestas neque
                  quam urna eleifend feugiat integer orci dignissim, sagittis
                  hendrerit convallis malesuada nam porta iaculis, per ornare
                  duis tellus nulla maecenas arcu. Rhoncus mauris potenti
                  habitasse nullam neque, ornare elementum suscipit dapibus
                  sagittis eleifend, arcu dictumst fames orci. Phasellus
                  suspendisse sodales penatibus vitae conubia tellus feugiat
                  cubilia, donec molestie justo cras aliquet tempor elementum
                  urna, iaculis sed lectus placerat pharetra pulvinar nam.
                </p>

                <p>
                  Etiam ultrices posuere ligula phasellus nisi euismod ac class,
                  nam pretium dictum metus suscipit aenean sodales. Aliquet
                  egestas tincidunt dignissim neque sociosqu nostra hendrerit
                  porttitor hac, tortor rhoncus laoreet vivamus vulputate tempor
                  euismod pharetra id massa, non diam sollicitudin quam nunc
                  convallis venenatis vel. Vestibulum per vulputate nam mattis
                  sociosqu etiam mi felis est cursus, dignissim facilisi conubia
                  ultricies euismod quisque pellentesque habitasse taciti.
                </p>

                <p>
                  Nibh auctor congue nascetur eget netus penatibus tempor sociis
                  volutpat, venenatis dui vulputate senectus pulvinar sapien
                  convallis curabitur. Metus nec condimentum eleifend
                  pellentesque congue dictumst, hac eros massa molestie aliquet,
                  bibendum duis convallis fringilla nullam. Vulputate sagittis
                  netus integer suspendisse lectus libero, faucibus nisl sed
                  nascetur nostra, ridiculus lobortis ante nam senectus. Morbi
                  sapien rhoncus taciti litora pulvinar nisi rutrum, curae id
                  odio potenti risus ullamcorper ultricies, donec ad mauris
                  sociosqu orci volutpat.
                </p>

                <p>
                  Class blandit arcu erat ad posuere faucibus litora aptent
                  suspendisse purus per vestibulum, nunc sagittis imperdiet
                  porta leo potenti maecenas cum himenaeos etiam praesent.
                  Ultricies habitasse consequat magna faucibus lobortis nulla
                  sodales, porta venenatis dictum pharetra integer vivamus purus
                  tempus, laoreet himenaeos magnis taciti enim dis. Eu diam urna
                  mi nunc potenti odio sem curabitur aenean est varius, tempus
                  fusce platea dictum pharetra arcu ligula mauris mollis libero
                  felis et, dapibus lacus pretium magnis condimentum euismod
                  cras tincidunt per habitasse.
                </p>

                <p>
                  Etiam elementum nibh ullamcorper morbi cras eget felis nam
                  praesent, dictum consequat fermentum rhoncus massa varius
                  hendrerit purus porttitor, faucibus fames quam duis hac
                  tincidunt auctor malesuada. Proin pharetra habitant lacus
                  conubia cum tortor metus inceptos libero laoreet, semper
                  suscipit vehicula tellus faucibus consequat tincidunt viverra
                  eros vestibulum facilisis, ridiculus platea accumsan vivamus
                  in hendrerit cursus netus litora. Aliquam luctus turpis nulla
                  penatibus rhoncus dis elementum nam, ac sagittis posuere fames
                  tellus metus non.
                </p>

                <p>
                  Vel lectus mi lacinia fringilla facilisis nulla venenatis
                  scelerisque phasellus cursus, auctor sodales conubia ligula
                  molestie condimentum aptent risus. Metus cum tincidunt
                  suscipit bibendum nibh potenti nec varius sociosqu, elementum
                  iaculis felis dui libero imperdiet mauris feugiat, morbi velit
                  penatibus placerat egestas etiam gravida cursus. Molestie
                  risus class metus donec vestibulum ultrices, augue eleifend
                  lobortis bibendum interdum dictum, odio himenaeos taciti
                  curabitur vitae. Fames egestas laoreet scelerisque interdum
                  penatibus risus at eros, condimentum lacinia donec accumsan
                  convallis euismod vulputate nunc pharetra, ridiculus taciti
                  hac iaculis sem porta blandit.
                </p>

                <p>
                  Egestas phasellus semper luctus habitant mauris malesuada
                  tempor sagittis hendrerit, nullam orci imperdiet at tincidunt
                  fringilla ad felis platea, fames lacinia natoque a elementum
                  neque accumsan rutrum. Faucibus mi dis ridiculus vitae
                  fringilla mus vel, class porttitor tellus senectus rutrum
                  dignissim dictumst eros, platea fusce viverra sollicitudin
                  maecenas molestie. Mi posuere nibh sociosqu placerat quam
                  curabitur habitant, sodales tincidunt magna purus sollicitudin
                  interdum eu, etiam orci fames parturient ullamcorper commodo.
                </p>

                <p>
                  Dictum in sagittis posuere ante venenatis eget aenean
                  parturient aptent, potenti tellus dapibus erat morbi aliquet
                  turpis quis urna fermentum, at cum dis volutpat ornare arcu
                  fusce gravida. Libero dignissim magna pellentesque varius
                  fusce eu senectus turpis, torquent ut vivamus metus cubilia
                  feugiat tempus nec molestie, augue condimentum purus himenaeos
                  egestas nibh sagittis. Dignissim curae fusce tempor neque ac
                  nullam maecenas ridiculus odio litora felis hac augue
                  vulputate, ante a urna dis iaculis vehicula eget sociosqu
                  bibendum ultricies interdum pharetra mauris.
                </p>

                <p>
                  Mauris penatibus erat nostra venenatis imperdiet, ridiculus
                  luctus posuere id cursus mollis, in eros suscipit cubilia.
                  Risus mollis eros porta vivamus euismod nisi nibh mattis
                  praesent varius netus, mauris hendrerit molestie urna non ad
                  mus nullam ut. Placerat ridiculus hac neque interdum sem at
                  tellus nascetur, augue nulla orci tincidunt aliquam
                  sollicitudin euismod mus morbi, condimentum natoque tempor leo
                  molestie pellentesque cum. Pharetra felis torquent interdum
                  dui morbi eu bibendum vulputate senectus donec, dictumst quam
                  nascetur pulvinar ultricies id faucibus litora suscipit justo,
                  lobortis lacus cum nam penatibus ridiculus porta dis varius.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermCondition;
