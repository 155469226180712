import { axiosPost } from "../axios/axios.config";
import { setLoading } from "../redux/actions/general.actions";
import { store } from "../redux/store";

export const forgotPassword = async (data) => {
  store.dispatch(setLoading(true));
  const res = await axiosPost("forgot-password", data);
  store.dispatch(setLoading(false));
  if (res[["data"]]) {
    return res;
  }
  return false;
};
