import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { getImagePath } from "../../../helpers/general.helper";

export const Inner = ({
  children,
  companyId = "",
  onSubmit,
  loading,
  routeCompanyId,
  brandLogo,
  match,
  companyData,
  buttonHidden = false,
  buttonDisable = false,
  secondSectionDisplay = true,
  companyInnerId = "",
}) => {
  const [routeId, setRouteId] = useState("");
  const [menuToggle, setMenuToggle] = useState(false);
  useEffect(() => {
    let id = "";
    if (routeCompanyId) {
      id = routeCompanyId;
    } else if (match.params.id) {
      id = match.params.id;
    }
    setRouteId(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeCompanyId]);

  const getRoute = (name) => {
    if (routeCompanyId) {
      return routeCompanyId + "/" + name;
    } else if (match.params.id) {
      return match.params.id + "/" + name;
    } else {
      return "create";
    }
  };

  const getClassIfActive = (matchRoute, optionalRoute = "") => {
    if (optionalRoute) {
      return match.path.includes(matchRoute) ||
        match.path.includes(optionalRoute)
        ? "active"
        : "";
    } else {
      return match.path.includes(matchRoute) ? "active" : "";
    }
  };

  return (
    <>
      <section className="section general-top-bar">
        <div className="container">
          <div className="wrapper">
            <div className="general-top-wrap">
              <Link to="/">
                <div className="title">Plataforma GDI</div>
              </Link>
              {match && match?.params?.orderId ? (
                ""
              ) : (
                <div
                  // href="##"
                  className="menulinks admin-sidebar-mobile-menu"
                  onClick={(e) => setMenuToggle(!menuToggle)}
                >
                  <i></i>
                </div>
              )}

              <div className="order-id">
                ID:{" "}
                {companyId !== ""
                  ? companyId
                  : companyData?.companyGDI !== ""
                  ? companyData.companyGDI
                  : ""}
              </div>
              {secondSectionDisplay && (
                <div className="return-order">
                  <button
                    className={
                      buttonDisable && buttonDisable === true
                        ? "btn blue-square-btn disabled"
                        : "btn blue-square-btn"
                    }
                    onClick={onSubmit}
                    disabled={loading}
                    hidden={buttonHidden}
                  >
                    {loading && (
                      <i className="fa fa-spinner fa-spin button-loader"></i>
                    )}
                    Guardar
                  </button>
                </div>
              )}
              {!secondSectionDisplay && (
                <div className="return-link mb-35">
                  {companyInnerId !== "" && (
                    <Link to={`/brands/${companyInnerId}/orders`}>
                      <i className="fa fa-arrow-circle-o-left"></i>Regresar
                    </Link>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {secondSectionDisplay && (
        <section className="section">
          <div className="main-wrapper">
            <div className={`sidebar ${menuToggle ? "show" : ""}`}>
              <div
                className="close-btn"
                onClick={(e) => {
                  setMenuToggle(!menuToggle);
                }}
              >
                <i className="fa fa-close"></i>
              </div>
              <div className="sidebar-logo">
                {brandLogo && brandLogo !== "" && routeId !== "" ? (
                  <img src={getImagePath(brandLogo)} alt="sidebar-logo" />
                ) : companyData?.logo && routeId !== "" ? (
                  <img
                    src={getImagePath(companyData.logo)}
                    alt="sidebar-logo"
                  />
                ) : (
                  <img src="/assets/images/gray-logo.png" alt="sidebar-logo" />
                )}
              </div>
              <div className="sidebar-links">
                <div className="title">
                  <h4>Configuración</h4>
                </div>
                <ul>
                  <li
                    className={getClassIfActive(
                      "/brands/create",
                      "/brands/update"
                    )}
                  >
                    {routeId ? (
                      <Link to={`/brands/update/${routeId}`}>General</Link>
                    ) : (
                      <Link to="/brands/create">General</Link>
                    )}
                  </li>
                  <li className={getClassIfActive("/users")}>
                    <Link to={`/brands/${getRoute("users")}`}>Usuarios</Link>
                  </li>
                  <li className={getClassIfActive("/branches")}>
                    <Link to={`/brands/${getRoute("branches")}`}>
                      Sucursales
                    </Link>
                  </li>
                  <li className={getClassIfActive("/orders")}>
                    <Link to={`/brands/${getRoute("orders")}`}>
                      Ordenes de compra
                    </Link>
                  </li>
                  <li className={getClassIfActive("/import-data")}>
                    <Link to={`/brands/${getRoute("import-data")}`}>
                      Datos de importacion
                    </Link>
                  </li>
                  {/* <li className={getClassIfActive("/responsible-users")}>
                    <Link to={`/brands/${getRoute("responsible-users")}`}>
                      Usuario responsable
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>

            {children}
          </div>
        </section>
      )}
    </>
  );
};

const mapStateToProps = ({
  general: { form_processing },
  company: { companyGDI, logo },
}) => ({
  loading: form_processing,
  companyData: { companyGDI, logo },
});

export const BrandInner = connect(mapStateToProps)(withRouter(Inner));
