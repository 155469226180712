import React, { useEffect, useState } from "react";
import { BrandInner } from "../../brand-inner";
import ProductImportForm from "./product-import-form";
import ConsumoReportImportForm from "./consumo-report-import-form";
import FactuartionReportImportForm from "./factuartion-report-import-form";
import SagmintoReportImportForm from "./sagminto-report-import-form";

const ImportData = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [compnayId, setCompanyId] = useState("");

  useEffect(() => {
    if (props?.match?.params?.id) {
      setCompanyId(props.match.params.id);
    }
  }, [props?.match?.params]);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  return (
    <>
      <BrandInner buttonHidden={true}>
        <div className="general-wrapper">
          <div className="general-setting-wrap mobile-loder-spacing general-form-input">
            <div className="productDetailTab">
              <ul>
                <li
                  className={activeTab === "1" ? "active" : ""}
                  id="1"
                  onClick={(e) => {
                    toggle("1");
                  }}
                >
                  Producto
                </li>
                <li
                  className={activeTab === "2" ? "active" : ""}
                  id="2"
                  onClick={(e) => {
                    toggle("2");
                  }}
                >
                  Reporte de consumo
                </li>
                <li
                  className={activeTab === "3" ? "active" : ""}
                  id="3"
                  onClick={(e) => {
                    toggle("3");
                  }}
                >
                  Reportes de Facturación
                </li>
                <li
                  className={activeTab === "4" ? "active" : ""}
                  id="4"
                  onClick={(e) => {
                    toggle("4");
                  }}
                >
                  Reporte de seguimiento
                </li>
              </ul>
              <div className="productDetailTab-content">
                {activeTab === "1" && compnayId !== "" && (
                  <>
                    <ProductImportForm
                      setActiveTab={setActiveTab}
                      compnayId={compnayId}
                    />
                  </>
                )}
                {activeTab === "2" && compnayId !== "" && (
                  <ConsumoReportImportForm
                    setActiveTab={setActiveTab}
                    compnayId={compnayId}
                  />
                )}
                {activeTab === "3" && compnayId !== "" && (
                  <FactuartionReportImportForm
                    setActiveTab={setActiveTab}
                    compnayId={compnayId}
                  />
                )}
                {activeTab === "4" && compnayId !== "" && (
                  <SagmintoReportImportForm compnayId={compnayId} />
                )}
              </div>
            </div>
          </div>
        </div>
      </BrandInner>
    </>
  );
};
export default ImportData;
