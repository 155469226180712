import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import {
  removeFromCart,
  multipleRemoveFromCart,
  editFromCart,
  emptyCart,
} from "../../redux/actions/cart.actions";
import { setCartComment } from "../../redux/actions/cart-comment.actions";
import { Modal } from "../../components/modal/modal";

const CartOrderTable = ({
  cartItems,
  removeCart,
  removeMultiPleCart,
  editCart,
  emptyCart,
  userData,
  setCartComment,
}) => {
  let history = useHistory();
  const [total, setTotal] = useState(0);
  const [totalProduct, setTotalProduct] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [deleteParticularId, setDeleteParticularId] = useState({});
  const [deleteProducts, setDeleteProducts] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [editProduct, setEditProduct] = useState({});
  const [quantityOption, setQuantityOption] = useState([]);
  const [modelTalla, setModelTalla] = useState("");
  const [modelCant, setModelCant] = useState("");
  const [editIndex, setEditIndex] = useState(-1);
  const [orderNumber, setOrderNumber] = useState(0);

  const [userRole, setUserRole] = useState("basic");
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      const number = Math.floor(Math.random() * (999 - 100 + 1) + 100);
      setOrderNumber(number);
      // setComment("");
      let totalData = 0;
      let totalItem = 0;
      cartItems.forEach((e) => {
        totalItem = totalItem + e.Cantidad;
        const subTotal = parseFloat(parseFloat(e.subTotal).toFixed(2));
        totalData = totalData + subTotal;
      });
      setTotal(totalData);
      setTotalProduct(totalItem);

      let tempArray = [...quantityOption];
      for (let i = 0; i <= 50; i++) {
        tempArray.push(i);
      }
      setQuantityOption(tempArray);
    }

    if (userData?.permission?.type === "admin") setUserRole("admin");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems, userData]);

  const deleteParticularProduct = (productId, Talla, checked) => {
    if (checked) {
      setDeleteProducts([
        ...deleteProducts,
        { IdProduct: productId, Talla: Talla },
      ]);
    } else {
      const tempArray = [...deleteProducts];
      const index = tempArray.findIndex(
        (e) => e.IdProduct === productId && e.Talla === Talla
      );
      if (index !== -1) {
        tempArray.splice(index, 1);
        setDeleteProducts(tempArray);
      }
    }
  };

  const editModalHandle = (product) => {
    const cartItemsData = [...cartItems];

    const index = cartItemsData.findIndex(
      (e, index) =>
        index !== editIndex &&
        e.IdProduct === product.IdProduct &&
        e.Talla === product.Talla
    );

    if (index !== -1) {
      cartItemsData[index] = {
        ...cartItemsData[index],
        Cantidad:
          parseInt(cartItemsData[index].Cantidad) + parseInt(product.Cantidad),
        subTotal:
          (parseInt(cartItemsData[index].Cantidad) +
            parseInt(product.Cantidad)) *
          parseFloat(cartItemsData[editIndex].Precio).toFixed(2),
      };
      cartItemsData.splice(editIndex, 1);
    } else {
      cartItemsData[editIndex] = {
        ...product,
        Cantidad: parseInt(product.Cantidad),
        subTotal:
          parseInt(product.Cantidad) *
          parseFloat(cartItemsData[editIndex].Precio).toFixed(2),
      };
    }

    editCart(cartItemsData);
  };

  const handleContinue = () => {
    if (comment !== "") {
      setCartComment(comment);
    }

    if (userRole === "admin") {
      history.push(`/admin/order/confirmation/${orderNumber}`);
    } else {
      history.push(`/order/confirmation/${orderNumber}`);
    }
  };

  return (
    <>
      <section className="section preorder-sec">
        <div className="container">
          <div className="wrapper">
            <div className="pre-order-wrap">
              <div className="title">
                <h4>Pre-orden de compra #{orderNumber}</h4>
              </div>
              <div className="date">
                {/* 25 de marzo 2021 */}

                {moment().format("Do MMMM YYYY")}
              </div>
            </div>
          </div>
        </div>
      </section>

      {cartItems.length === 0 && (
        <section className="section cancel-order gray-bg">
          <div className="container">
            <div className="wrapper">
              <div className="cancel-order-wrap">
                <div className="box">
                  <div className="title">
                    Esta pre-orden ha sido eliminada, regresa al catálogo para
                    crear una nueva
                  </div>
                  <div className="submit-btn">
                    <Link
                      to={
                        userRole === "admin" ? "/admin/catalogue" : "/catalogue"
                      }
                    >
                      <button
                        type="button"
                        className="blue-btn download-pdf-btn"
                      >
                        catálogo
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {cartItems && cartItems.length > 0 && (
        <section className="section order-history gray-bg preorder-table">
          <div className="container">
            <div className="wrapper">
              <div className="table-wrapper">
                <div className="order-history-table-wrap">
                  <table className="order-history-table">
                    <thead>
                      <tr>
                        <th>
                          <>
                            {deleteProducts.length > 0 ? (
                              <img
                                src="/assets/images/close-red.png"
                                alt=""
                                onClick={(e) => {
                                  setShowModal(true);
                                }}
                              />
                            ) : (
                              <img src="/assets/images/close-dark.png" alt="" />
                            )}
                          </>
                        </th>
                        <th className="text-left">Artículo</th>
                        <th>Talla</th>
                        <th>Cant.</th>
                        <th>Precio</th>
                        <th>Subtotal</th>
                        <th>Eliminar</th>
                      </tr>
                    </thead>

                    {cartItems && cartItems.length > 0 ? (
                      cartItems.map((data, index) => (
                        <tbody key={index}>
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  deleteParticularProduct(
                                    data.IdProduct,
                                    data.Talla,
                                    e.target.checked
                                  )
                                }
                                id={`${index}`}
                                checked={
                                  deleteProducts && deleteProducts.length > 0
                                    ? deleteProducts.forEach((e) => {
                                        if (
                                          e.IdProduct === data.IdProduct &&
                                          e.Talla === data.Talla
                                        ) {
                                          return true;
                                        } else {
                                          return false;
                                        }
                                      })
                                    : false
                                }
                              />

                              <label htmlFor={`${index}`}></label>
                            </td>
                            <td className="article-detail-center">
                              <div className="article-detail">
                                <div
                                  className="image"
                                  style={{
                                    backgroundImage: `url(${
                                      data.ProductImage
                                        ? data.ProductImage
                                        : `/assets/images/product-item.jpg`
                                    })`,
                                  }}
                                ></div>
                                <div className="article-detail-content">
                                  <div className="title">
                                    <h4>{data.Name}</h4>
                                  </div>
                                  <div className="subtitle"></div>
                                </div>
                              </div>
                            </td>
                            <td className="cant">
                              <div>
                                <label>{data.Talla}</label>
                              </div>
                            </td>
                            <td className="size">
                              <div>
                                <div className="wrap">
                                  <label>{data.Cantidad}</label>
                                  <img
                                    src="/assets/images/edit.png"
                                    alt="icon"
                                    onClick={(e) => {
                                      setEditModal(true);
                                      setEditIndex(index);
                                      setModelTalla(data.Talla);
                                      setModelCant(data.Cantidad);
                                      setEditProduct({
                                        IdProduct: data.IdProduct,
                                        Name: data.Name,
                                        TallOption: data.TallOptions,
                                        ProductImage: data.ProductImage,
                                        ...data,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </td>
                            <td className="price">
                              ${parseFloat(data.Precio).toFixed(2)} MXN
                            </td>
                            <td className="subtotal">
                              ${parseFloat(data.subTotal).toFixed(2)} MXN
                            </td>
                            <td className="eliminar">
                              <img
                                src="/assets/images/close.png"
                                alt=""
                                onClick={(e) => {
                                  setShowModal(true);
                                  setDeleteParticularId({
                                    IdProduct: data.IdProduct,
                                    Talla: data.Talla,
                                  });
                                }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <tbody>
                        <tr>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                  </table>

                  <div className="conditional-comment">
                    <div className="title">Agregar comentarios adicionales</div>
                    {/* <div className="content-box"> */}
                    <div className="text-area-comment">
                      <textarea
                        onChange={(e) => {
                          setComment(e.target.value);
                        }}
                        rows={10}
                        style={{ padding: "10px" }}
                        maxLength={350}
                      ></textarea>
                    </div>
                  </div>

                  <div className="process-order">
                    <div className="process-order-wrap">
                      <div className="process-order-content">
                        <div className="content">
                          Este documento es una preorden de compra, para poder
                          procesar tu pedido es necesaria la aprobación por tu
                          unidad de negocio
                        </div>
                      </div>
                      <div className="process-order-total">
                        <div className="total-products">
                          <div className="products-unit">
                            Productos: <span>{totalProduct} unidades</span>
                          </div>
                          <div className="total">
                            Total:
                            <span> ${parseFloat(total).toFixed(2)} MXN</span>
                          </div>
                        </div>
                        <div className="submit-btn">
                          {/* <Link
                            to={
                              userRole === "admin"
                                ? "/admin/catalogue"
                                : "/catalogue"
                            }
                          > */}
                          <button
                            type="button"
                            className="blue-btn download-pdf-btn"
                            onClick={(e) => {
                              if (userRole === "admin") {
                                history.push("/admin/catalogue");
                              } else {
                                history.push("/catalogue");
                              }
                            }}
                          >
                            Agregar más prendas
                          </button>
                          {/* </Link> */}

                          {/* <Link
                            to={
                              userRole === "admin"
                                ? `/admin/order/confirmation/${orderNumber}`
                                : `/order/confirmation/${orderNumber}`
                            }
                          > */}
                          <button
                            type="button"
                            className="blue-btn download-pdf-btn"
                            onClick={(e) => handleContinue()}
                          >
                            Continuar
                          </button>
                          {/* </Link> */}
                        </div>
                        <div className="delete-prepurchase">
                          <a
                            href="##"
                            className="link"
                            onClick={(e) => {
                              emptyCart([]);
                            }}
                          >
                            Eliminar pre-orden de compra
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <Modal
        visible={showModal}
        id="photo-modal"
        onClose={(e) => setShowModal(false)}
      >
        <div className="choose-photo-popup">
          <div className="choose-photo-wrap popup-content-center">
            <div className="title">
              <h4>Rempve el producto del carrito</h4>
            </div>

            <div className="selection-photo-grid">
              <h6>¿Estás segura de que quieres eliminar esto?</h6>
            </div>

            <div className="link">
              <a
                href="#!"
                className="btn blue-square-btn mr-1"
                onClick={(e) => setShowModal(false)}
              >
                Cancelar
              </a>
              <a
                href="#!"
                className="btn blue-square-btn"
                onClick={(e) => {
                  if (Object.keys(deleteParticularId).length > 0) {
                    removeCart(cartItems, deleteParticularId);
                  }
                  if (deleteProducts && deleteProducts.length > 0) {
                    removeMultiPleCart(cartItems, deleteProducts);
                    setDeleteProducts([]);
                  }

                  setShowModal(false);
                }}
              >
                Actualizar
              </a>
            </div>
          </div>
        </div>
      </Modal>
      {Object.keys(editProduct).length > 0 && (
        <Modal
          visible={editModal}
          id="edit-modal"
          onClose={(e) => setEditModal(false)}
        >
          <div className="choose-photo-popup">
            <div className="choose-photo-wrap">
              <div className="row edit-product-popup">
                <div className="col-md-6">
                  <div className="image">
                    {editProduct.ProductImage ? (
                      <img
                        src={editProduct.ProductImage}
                        alt=""
                        style={{ width: "18%" }}
                      />
                    ) : (
                      <img src="/assets/images/product-item.jpg" alt="" />
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="title">
                    <h4>{editProduct.Name}</h4>
                  </div>
                  <div className="cart-details">
                    <div className="size">
                      <h6>Talla</h6>

                      <select
                        value={modelTalla}
                        onChange={(e) => setModelTalla(e.target.value)}
                      >
                        {editProduct.TallOption.length &&
                          editProduct.TallOption.map((tallaData, index1) => (
                            <option value={tallaData} key={index1}>
                              {tallaData}{" "}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="qty">
                      <h6>Cant.</h6>

                      {/* <select
                        value={modelCant}
                        onChange={(e) => setModelCant(e.target.value)}
                      >
                        {quantityOption.length &&
                          quantityOption.map((quantityData, index1) => (
                            <option value={quantityData} key={index1}>
                              {quantityData}{' '}
                            </option>
                          ))}
                      </select> */}

                      <input
                        type="number"
                        min="1"
                        max="999"
                        value={modelCant}
                        onChange={(e) => {
                          setModelCant(e.target.value);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "-" || e.key === "." || e.key === "e") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4"></div>

              <div className="link">
                <a
                  href="#!"
                  className="btn blue-square-btn mr-1"
                  onClick={(e) => setEditModal(false)}
                >
                  Cancel
                </a>
                <a
                  href="#!"
                  className={`btn blue-square-btn ${
                    +modelCant <= 0 ? "disabled" : ""
                  }`}
                  onClick={(e) => {
                    if (modelCant === "" || +modelCant === 0) {
                      return;
                    }
                    setEditModal(false);
                    editModalHandle({
                      ...editProduct,
                      IdProduct: editProduct.IdProduct,
                      Talla: modelTalla,
                      Cantidad: modelCant,
                    });
                  }}
                >
                  Actualizar
                </a>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = ({ auth: { user_details }, cart: { items } }) => ({
  userData: user_details,
  cartItems: items,
});

const mapDispatchToProps = (dispatch) => ({
  removeCart: (cartItems, data) => dispatch(removeFromCart(cartItems, data)),
  removeMultiPleCart: (cartItems, data) =>
    dispatch(multipleRemoveFromCart(cartItems, data)),
  editCart: (cartItems, data) => dispatch(editFromCart(cartItems, data)),
  emptyCart: (cartItems) => dispatch(emptyCart(cartItems)),
  setCartComment: (commentData) => dispatch(setCartComment(commentData)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CartOrderTable);
