import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import Select from "react-select";
import { Spinner } from "../../../../../components/spinner/spinner";
import { BrandInner } from "../../brand-inner";
import { getOrders } from "../../../../../services/order.service";
import { getBranchOption } from "../../../../../services/branches.service";
import { getAllUsers } from "../../.../../../../../services/users.service";
import { getYears } from "../../../../../helpers/getYear.helper";

const Orders = (props) => {
  const [value, setValue] = useState([]);

  const [ordersData, setOrdersData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, SetTotal] = useState(0);
  const [companyID, setCompanyID] = useState(-1);
  const [userSelectOption, setUserSelectOption] = useState([]);
  const [sucursalSelectOption, setSucursalSelectOption] = useState([]);
  const [yearSelectOption, setYearSelectOption] = useState([]);
  const [filter, setFilter] = useState([]);
  let limit = 6;

  useEffect(() => {
    if (props?.match?.params?.id) {
      setCompanyID(props.match.params.id);
      getBranchOptions(props.match.params.id);
      getAllUserOptions(props.match.params.id);
    }

    if (props?.company?.date) {
      const year = getYears(props.company.date);
      if (year && year.length > 0) {
        let tempYear = [];
        year.forEach((e) => {
          tempYear.push({
            label: e,
            value: e,
            custom: "year",
            valueBackend: e,
          });
        });
        setYearSelectOption(tempYear);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.match?.params]);

  const getAllOrders = async (page) => {
    const res = await getOrders({
      page: page,
      limit: limit,
      company_id: companyID,
      sortby: JSON.stringify(filter),
    });
    if (
      res &&
      Object.keys(res).length > 0 &&
      res?.orders &&
      res?.total &&
      page >= 1
    ) {
      setOrdersData((orders) => [...orders, ...res.orders]);
      SetTotal(res.total);
    } else {
      setOrdersData([]);
      SetTotal(0);
    }
  };

  useEffect(() => {
    if (companyID !== -1) {
      getBranchOptions(companyID);
    }
    if (page === 1) {
      setOrdersData([]);
    }

    if (companyID !== -1) {
      getAllOrders(page, filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filter, companyID]);

  const loadMore = () => {
    setPage((page) => page + 1);
  };

  const getAllUserOptions = async (id) => {
    const res = await getAllUsers({ company_id: parseInt(id) });
    if (res && res.length > 0) {
      const comboValue = res.map((e) => {
        return {
          label: e.Name,
          // value: e.IdUser,
          value: `${e.Name}-${e.IdUser}`,
          custom: "user_id",
          valueBackend: e.IdUser,
        };
      });
      setUserSelectOption(comboValue);
    } else {
      setUserSelectOption([]);
    }
  };

  const getBranchOptions = async (id) => {
    const res = await getBranchOption({ company: parseInt(id) });
    if (res && res.length > 0) {
      const comboValue = res.map((e) => {
        return {
          label: e.Name,
          value: `${e.Name}-${e.IdSucursal}`,
          custom: "sucursal_id",
          valueBackend: e.IdSucursal,
        };
      });
      setSucursalSelectOption(comboValue);
    } else {
      setSucursalSelectOption([]);
    }
  };

  const createGroup = (groupName, options, setValue) => {
    return {
      label: (() => {
        return (
          <div
            onClick={() => {
              setValue((value) => {
                if (typeof value !== "undefined" && typeof value !== "object") {
                  value.concat(
                    options.filter((grpOpt) => !value.includes(grpOpt))
                  );
                }
              });
            }}
          >
            {groupName}
          </div>
        );
      })(),
      options: options,
    };
  };

  let options = [
    createGroup("Personas", userSelectOption, setValue),
    createGroup("Sucursales", sucursalSelectOption, setValue),
    createGroup("Año", yearSelectOption, setValue),
  ];

  return (
    <BrandInner buttonDisable={true} buttonHidden={true}>
      <div className="general-wrapper">
        <div className="general-setting-wrap mobile-loder-spacing">
          <div className="purchase-order-title">
            <div className="title mb-0">Ordenes de compra</div>
            <div className="filter-wrap">
              <div className="content">Filtra tu búsqueda por:</div>
              <div className="filter-form">
                <Select
                  options={options}
                  placeholder="Sofía Torres"
                  onChange={(e) => {
                    setPage(1);
                    let data = {};
                    data[e.custom] = e.valueBackend;
                    setFilter([data]);
                    return setValue(e);
                  }}
                  value={value}
                />
              </div>
            </div>
          </div>

          {props.loading ? <Spinner /> : ""}
          {ordersData && ordersData.length > 0 ? (
            <div className="puchase-order">
              <div className="admin-purchase-order">
                {ordersData.map((data, index) => (
                  <div className="purchase-order-item" key={data.IdOrder}>
                    <div className="item-box">
                      <div className="order-box">
                        <h5>Orden</h5>
                        <div className="id">#{data.Order_number}</div>
                      </div>
                      <div className="order-detail-box">
                        <div className="order-date">
                          <div className="date">
                            {moment(data.Creation_date)
                              .locale("es")
                              .format("ll")}
                          </div>
                          <div className="order-img">
                            {data.Active !== 0 ? (
                              data.Active === 1 ? (
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/approved.png`}
                                  alt="approved"
                                />
                              ) : (
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/not-approved.png`}
                                  alt="not-approved"
                                />
                              )
                            ) : (
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/pending.png`}
                                alt="pending"
                              />
                            )}
                          </div>
                        </div>

                        {data.Active !== 0 && data.user_action_by && (
                          <div className="approved-by">
                            {data.Active === 1 && (
                              <>Aprobado por: {data.user_action_by.Name}</>
                            )}
                            {data.Active === 2 && (
                              <>Rechazado por:{data.user_action_by.Name}</>
                            )}
                          </div>
                        )}

                        <div className="name">{data?.user?.userName}</div>
                        <div className="account">
                          Cuenta: {data?.company?.Name}
                        </div>
                        <div className="detail">
                          Detalles: {data?.total_products} productos
                        </div>
                        <div className="final-total">
                          Total final: ${parseFloat(data?.total).toFixed(2)}
                        </div>
                        <div className="see-order">
                          <Link
                            to={`/order/admin/${data.IdOrder}/${companyID}`}
                          >
                            Ver orden +
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {total && Math.ceil(total / limit) > page ? (
                <div className="see-more text-center">
                  <a
                    href="##"
                    onClick={(e) => {
                      e.preventDefault();
                      loadMore();
                    }}
                  >
                    Ver más <i className="fa fa-chevron-down"></i>
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            !props.loading && (
              <div className="no-puchase-order">
                <img src="/assets/images/order-purchase.png" alt="" />
                <div className="content">Aún no existen ordenes de compra</div>
              </div>
            )
          )}
        </div>
      </div>
    </BrandInner>
  );
};

const mapStateToProps = ({ general: { loading }, company }) => ({
  loading: loading,
  company,
});
export default connect(mapStateToProps, null)(Orders);
