const INITIAL_STATE = null;

export const token = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_TOKEN":
      return action.payload;
    default:
      return state;
  }
};
