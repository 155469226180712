import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { forgotPassword } from '../../services/auth.service';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('El correo no es válido')
    .required('correo electronico es requerido'),
});
const initials = { email: '' };

const ForgotPassword = ({ userDetails, loading }) => {
  let history = useHistory();
  const [superAdminToggle, setSuperAdminToggle] = useState(false);

  useEffect(() => {
    if (
      window.location.pathname &&
      window.location.pathname.includes('super-admin')
    ) {
      setSuperAdminToggle(true);
    }

    if (Object.keys(userDetails).length) {
      if (
        userDetails &&
        userDetails?.permission?.type &&
        userDetails.permission.type === 'superadmin'
      ) {
        history.push('/');
      }

      if (
        userDetails &&
        userDetails?.permission?.type &&
        userDetails.permission.type === 'basic'
      ) {
        history.push('/catalogue');
      }

      if (
        userDetails &&
        userDetails?.permission?.type &&
        userDetails.permission.type === 'admin'
      ) {
        history.push('/admin/catalogue');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const onSubmit = async (values) => {
    const res = await forgotPassword(values);
    if (res && res.status === 'success') {
      setTimeout(() => {
        if (superAdminToggle) {
          history.push({
            pathname: '/super-admin/forgot-password-fail-success',
          });
        } else {
          history.push({ pathname: '/forgot-password-fail-success' });
        }
      }, 200);
    }
  };

  return (
    <section className="section login-content-sec">
      <div className="login-wrapper">
        <div className="login-content">
          <div className="login-content-wrap">
            <div className="login-logo">
              <a href="/">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/logo-white.png`}
                  alt="brand-logo"
                />
              </a>
            </div>
            <div className="login-title-wrap">
              <div className="login-title">
                <h4>Recupera tu contraseña</h4>
              </div>
            </div>
            {!superAdminToggle && (
              <div className="social-icons">
                <ul>
                  <li>
                    <a href="#!">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#!">
                      <i className="fa fa-facebook-f"></i>
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="login-form">
          <div className="login-form-main">
            <div className="login-form-wrap">
              <div className="form-title">
                <h5>Enviaremos tu contraseña vía correo electrónico</h5>
              </div>
              <div className="login-form-inner">
                <Formik
                  initialValues={initials}
                  onSubmit={onSubmit}
                  validationSchema={loginSchema}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <div className="form-group">
                        <label>
                          {superAdminToggle
                            ? 'Usuario*'
                            : ' Número de empleado*'}
                        </label>
                        <Field
                          name="email"
                          type="text"
                          className={
                            'form-control' +
                            (errors.email && touched.email ? ' is-invalid' : '')
                          }
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="login-submit">
                        <button type="submit" className="blue-btn">
                          {loading && (
                            <i className="fa fa-spinner fa-spin button-loader"></i>
                          )}
                          Recuperar
                        </button>
                        {!superAdminToggle && (
                          <div className="link">
                            <a href="https://www.gdiuniformes.com/">
                              Regresar a GDI
                            </a>
                          </div>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = ({ auth: { user_details }, general: { loading } }) => ({
  userDetails: user_details,
  loading,
});

export default connect(mapStateToProps, null)(ForgotPassword);
