import React, { useEffect, useState, useRef } from "react";
import { Field, Form, Formik } from "formik";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { BrandInner } from "../../brand-inner";
import Select from "react-select";
import { SelectBox } from "../../../../../components/select-box/select-box";
import {
  getResponsibleUsers,
  getUser,
  saveUser,
} from "../../../../../services/users.service";
import { getPermissionOptions } from "../.../../../../../../services/permissions.service";
import { getBranchOption } from "../../../../../services/branches.service";
import { Spinner } from "../../../../../components/spinner/spinner";

const initials = {
  Name: "",
  Employee_number: "",
  Email: "",
  Password: "",
  IdPermissions: "",
  Sucursales: [],
  responsible_user: [],
};

const UserForm = (props) => {
  let history = useHistory();
  const formRef = useRef();
  const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const [submitted, setSubmitted] = useState(false);
  const [initialValues, setInitialValues] = useState(initials);
  const [id, setId] = useState(-1);
  const [userId, SetUserId] = useState(-1);
  const [sucursalSelectOption, setSucursalSelectOption] = useState([]);
  const [permissionOption, setPermissionOption] = useState([]);
  const [responsibleSelectOption, setResponsibleSelectOption] = useState([]);

  const [responsibleSelect, setResponsibleSelect] = useState([]);
  const [sucursalSelect, setSucursalSelect] = useState([]);

  // const [responsibleEmailTest, setResponsibleEmailTest] = useState(false);
  // const [selectResponsibleBoxOption, setSelectResponsibleBoxOption] = useState(
  //   []
  // );

  const [toggleResponsibleSelect, setToggleResponsibleSelect] = useState(true);

  useEffect(() => {
    if (props?.match?.params?.id) {
      setId(props.match.params.id);
      getPermissions();
      getUsersAvailableBranch(props.match.params.id);
      getResponsibleUsersOption(props.match.params.id);
    }
    if (props?.match?.params?.userId) {
      SetUserId(props.match.params.userId);
      getParticularUser(props.match.params.userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.match?.params]);

  const getPermissions = async () => {
    const res = await getPermissionOptions();
    if (res && res.length > 0) {
      const comboValue = res.map((e) => {
        return {
          label: e.Value,
          value: e.IdPermissions,
        };
      });

      setPermissionOption(comboValue);
    } else {
      setPermissionOption([]);
    }
  };

  const getUsersAvailableBranch = async (id) => {
    const res = await getBranchOption({ company: parseInt(id) });
    if (res && res.length > 0) {
      const comboValue = res.map((e) => {
        return {
          label: e.Name,
          value: e.IdSucursal,
        };
      });

      setSucursalSelectOption(comboValue);
    } else {
      setSucursalSelectOption([]);
    }
  };

  const getResponsibleUsersOption = async (company_id) => {
    const res = await getResponsibleUsers({ company_id });
    if (res && res.length > 0) {
      const options = res.map((e) => ({
        label: e.Name,
        value: e.IdUser,
      }));
      setResponsibleSelectOption(options);
    }
  };

  const getParticularUser = async (id) => {
    const res = await getUser({ id: parseInt(id) });
    if (res && Object.keys(res).length > 0) {
      const tempSucursale = [];
      const tempResponsibleUser = [];
      if (res?.IdPermissions === 2 || res?.IdPermissions === 4) {
        setToggleResponsibleSelect(false);
      }
      if (res?.sucursale && res.sucursale.length > 0) {
        res.sucursale.forEach((e) => tempSucursale.push(e.IdSucursal));
      }
      if (res?.responsible_user && res.responsible_user.length > 0) {
        res.responsible_user.forEach((e) =>
          tempResponsibleUser.push(e.responsible_IdUser)
        );
      }
      setInitialValues({
        ...initialValues,
        ...res,
        Sucursales: tempSucursale,
        responsible_user: tempResponsibleUser,
      });
    }
  };

  // const getParticularUser = async (id) => {
  //   const res = await getUser({ id: parseInt(id) });
  //   if (res && Object.keys(res).length > 0) {
  //     if (res?.sucursale && res.sucursale.length > 0) {
  //       const { responsible_user, ...rest } = res;
  //       let tempArray = [];
  //       let responsibleUserTemp = [];

  //       const responsibleUserArray = responsible_user
  //         ? responsible_user.split(",")
  //         : [];

  //       responsibleUserArray.length > 0 &&
  //         responsibleUserArray.forEach((e) =>
  //           responsibleUserTemp.push({ label: e, value: e })
  //         );
  //       setSelectResponsibleBoxOption(responsibleUserTemp);
  //       res.sucursale.forEach((e) => tempArray.push(e.IdSucursal));
  //       setInitialValues({ ...initialValues, ...rest, Sucursales: tempArray });
  //     } else {
  //       const { responsible_user, ...rest } = res;
  //       setInitialValues({ ...initialValues, ...rest });
  //     }
  //   }
  // };

  const sucursalSelectHandle = (e, setFieldValue) => {
    let tempArray = Array.isArray(e) ? e.map((x) => x.value) : [];
    setSucursalSelect(tempArray);
    setFieldValue("Sucursales", tempArray);
  };

  const responsibleSelectHandle = (e, setFieldValue) => {
    let tempArray = Array.isArray(e) ? e.map((x) => x.value) : [];
    setResponsibleSelect(tempArray);
    setFieldValue("responsible_user", tempArray);
  };

  // const handleChange = (newValue, actionMeta, setFieldValue) => {
  //   let toggle = false;

  //   if (newValue && newValue.length > 0) {
  //     newValue.forEach((e) => {
  //       if (!reg.test(e.value)) {
  //         toggle = true;
  //         // setResponsibleEmailTest(true);
  //       } else {
  //         toggle = false;
  //         // setResponsibleEmailTest(false);
  //       }
  //     });
  //   }
  //   if (
  //     newValue &&
  //     actionMeta &&
  //     actionMeta.action === "create-option" &&
  //     toggle === false
  //   ) {
  //     setSelectResponsibleBoxOption(newValue);
  //   } else if (toggle === false) {
  //     setSelectResponsibleBoxOption(newValue);
  //   }
  // };

  const hasErrors = () => {
    const isBasicRole = formRef.current.values.IdPermissions === 3;
    // if (userId !== -1) {
    //   return (
    //     !formRef.current.values.Name ||
    //     !formRef.current.values.Employee_number ||
    //     !formRef.current.values.Email ||
    //     !reg.test(formRef.current.values.Email) ||
    //     !formRef.current.values.IdPermissions ||
    //     !formRef.current.values.Sucursales.length > 0 ||
    //     (isBasicRole &&
    //       !formRef.current.values.responsible_user.length > 0)
    //     // responsibleEmailTest ||
    //     // !selectResponsibleBoxOption.length > 0
    //   );
    // }
    return (
      !formRef.current.values.Name ||
      !formRef.current.values.Employee_number ||
      !formRef.current.values.Email ||
      !reg.test(formRef.current.values.Email) ||
      (userId === -1 && !formRef.current.values.Password) ||
      !formRef.current.values.IdPermissions ||
      !formRef.current.values.Sucursales.length > 0 ||
      (isBasicRole && !formRef.current.values.responsible_user.length > 0)
      // responsibleEmailTest ||
      // !selectResponsibleBoxOption.length > 0
    );
  };

  const onSubmit = async () => {
    setSubmitted(true);
    if (userId !== -1) {
      if (!hasErrors()) {
        let data = {
          ...formRef.current.values,
          IdCompany: id,
          IdUser: userId,
        };
        delete data.Password;
        const res = await saveUser(data);
        setSubmitted(false);
        if (res && res.status === "success") {
          setTimeout(() => {
            history.push(`/brands/${id}/users`);
          }, 200);
        }
      }
    } else {
      if (!hasErrors()) {
        let data = {
          ...formRef.current.values,
          IdCompany: id,
        };

        if (data.IdPermissions === 2 || data.IdPermissions === 4) {
          delete data.responsible_user;
        }

        const res = await saveUser(data);
        setSubmitted(false);
        if (res && res.status === "success") {
          setTimeout(() => {
            history.push(`/brands/${id}/users`);
          }, 200);
        }
      }
    }
  };

  return (
    <BrandInner onSubmit={onSubmit}>
      <div className="general-wrapper">
        <div className="general-setting-wrap superadmin-adduser mobile-loder-spacing">
          {props.loading ? (
            <Spinner />
          ) : (
            <>
              <div className="title mb-0">información general</div>
              <div className="return-link mb-35">
                <Link to={`/brands/${id}/users`}>
                  <i className="fa fa-arrow-circle-o-left"></i>Regresar
                </Link>
              </div>
              <div className="user-page">
                <div className="user-image">
                  <img src="/assets/images/user-icon.png" alt="" />
                </div>
                <div className="user-form">
                  <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    innerRef={formRef}
                  >
                    {({ errors, touched, values, setFieldValue }) => (
                      <Form>
                        <div className="row">
                          <div className="col col-6">
                            <div className="form-group">
                              <label>Nombre completo</label>
                              <Field
                                name="Name"
                                type="text"
                                placeholder="Ana Sofía Torres Pereira"
                                className={
                                  "form-control" +
                                  (errors.Name && touched.Name
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              {submitted && !values.Name.trim() && (
                                <div className="invalid-feedback">
                                  Este campo es obligatorio
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col col-6">
                            <div className="form-group">
                              <label>Número de empleado</label>
                              <Field
                                name="Employee_number"
                                type="text"
                                placeholder="7894536ABC"
                                className={
                                  "form-control" +
                                  (errors.Employee_number &&
                                  touched.Employee_number
                                    ? " is-invalid"
                                    : "")
                                }
                                disabled={userId !== -1 ? true : false}
                              />
                              {submitted && !values.Employee_number.trim() && (
                                <div className="invalid-feedback">
                                  Este campo es obligatorio
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col col-6">
                            <div className="form-group">
                              <label>Correo electrónico</label>
                              <Field
                                name="Email"
                                type="text"
                                placeholder="storres@oxxo.com.mx"
                                className={
                                  "form-control" +
                                  (errors.Email && touched.Email
                                    ? " is-invalid"
                                    : "")
                                }
                                disabled={userId !== -1 ? true : false}
                              />
                              {submitted && !values.Email.trim() && (
                                <div className="invalid-feedback">
                                  Este campo es obligatorio
                                </div>
                              )}
                              {submitted &&
                                values.Email &&
                                !reg.test(values.Email) && (
                                  <div className="invalid-feedback">
                                    Por favor introduzca un correo electrónico
                                    válido
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col col-6">
                            <div className="form-group">
                              <label>Contraseña</label>
                              <Field
                                name="Password"
                                type="password"
                                placeholder="674HUDNO890"
                                className={
                                  "form-control" +
                                  (errors.Password && touched.Password
                                    ? " is-invalid"
                                    : "")
                                }
                                disabled={userId !== -1 ? true : false}
                              />
                              {submitted &&
                                !values.Password &&
                                userId === -1 && (
                                  <div className="invalid-feedback">
                                    Este campo es obligatorio
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col col-6">
                            <div className="form-group select-overlap">
                              <label>Nivel de permiso</label>
                              <SelectBox
                                name="IdPermissions"
                                onChange={(e) => {
                                  setFieldValue("IdPermissions", e);
                                  +e === 2 || +e === 4
                                    ? setToggleResponsibleSelect(false)
                                    : setToggleResponsibleSelect(true);
                                }}
                                option={permissionOption}
                                value={values.IdPermissions}
                              />

                              {submitted && !values.IdPermissions && (
                                <div className="invalid-feedback">
                                  Este campo es obligatorio
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col col-6">
                            <div className="form-group select-overlap">
                              <label>
                                Sucursales validas para este usuario
                              </label>

                              <Select
                                name={"Sucursales"}
                                value={
                                  values?.Sucursales &&
                                  values?.Sucursales.length > 0
                                    ? sucursalSelectOption.filter((e) =>
                                        values.Sucursales.includes(e.value)
                                      )
                                    : sucursalSelectOption.filter((e) =>
                                        sucursalSelect.includes(e.value)
                                      )
                                }
                                // menuIsOpen={true}
                                onChange={(e) => {
                                  sucursalSelectHandle(e, setFieldValue);
                                }}
                                options={sucursalSelectOption}
                                isMulti={true}
                              />

                              {submitted && !values.Sucursales.length && (
                                <div className="invalid-feedback">
                                  Este campo es obligatorio
                                </div>
                              )}
                            </div>
                          </div>

                          {toggleResponsibleSelect && (
                            <div className="col col-6">
                              <div className="form-group  select-overlap">
                                <label>Usuario responsable</label>
                                <Select
                                  isMulti
                                  name={`responsible_user`}
                                  options={responsibleSelectOption}
                                  onChange={(e) => {
                                    responsibleSelectHandle(e, setFieldValue);
                                  }}
                                  value={
                                    values?.responsible_user &&
                                    values?.responsible_user.length > 0
                                      ? responsibleSelectOption.filter((e) =>
                                          values.responsible_user.includes(
                                            e.value
                                          )
                                        )
                                      : responsibleSelectOption.filter((e) =>
                                          responsibleSelect.includes(e.value)
                                        )
                                  }
                                />
                                {submitted &&
                                  responsibleSelect.length === 0 && (
                                    <div className="invalid-feedback">
                                      Este campo es obligatorio
                                    </div>
                                  )}
                              </div>
                            </div>
                          )}

                          {/* <div className="col col-6">
                              <div className="form-group">
                                <label>Usuario responsable</label>

                                <CreatableSelect
                                  options={selectResponsibleBoxOption}
                                  value={selectResponsibleBoxOption}
                                  isMulti
                                  name={`responsible_user`}
                                  onChange={(value, action) => {
                                    handleChange(value, action, setFieldValue);
                                  }}
                                  // onInputChange={(e) => onInputValueChange(e)}
                                />

                                {submitted &&
                                  selectResponsibleBoxOption.length === 0 && (
                                    <div className="invalid-feedback">
                                      Este campo es obligatorio
                                    </div>
                                  )} */}

                          {/* {submitted &&
                                selectResponsibleBoxOption.length > 0 &&
                                responsibleEmailTest && (
                                  <div className="invalid-feedback">
                                    Por favor introduzca un correo electrónico
                                    válido
                                  </div>
                                )} */}
                          {/* </div>
                            </div> */}
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </BrandInner>
  );
};

const mapStateToProps = ({ general: { loading } }) => ({
  loading: loading,
});

export default connect(mapStateToProps, null)(UserForm);
