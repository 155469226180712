const initialState = {
  loading: false,
  form_processing: false,
};

export const general = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_FORM_PROCESSING":
      return { ...state, form_processing: action.payload };
    default:
      return state;
  }
};
