import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { BrandInner } from "../../brand-inner";
import TableComponent from "../../../../../components/table/table";
import {
  getBranches,
  deleteBranches,
} from "../../../../../services/branches.service";
import { Modal } from "../../../../../components/modal/modal";

export const Branches = (props) => {
  let history = useHistory();

  const [id, setId] = useState(-1);
  const [branchData, setBranchData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [deleteBranch, setDeleteBranch] = useState([]);

  useEffect(() => {
    if (props?.match?.params?.id) {
      setId(props.match.params.id);
    }
  }, [props?.match?.params]);

  const getAllBranches = async (page, filterData) => {
    const res = await getBranches({
      page: page,
      search: filterData,
      company_id: parseInt(id),
    });

    if (res && Object.keys(res).length > 0) {
      setBranchData(res.records);
      setDataCount(res.total);
    }
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  const deleteParticularBranch = (branchId, checked) => {
    if (checked) {
      setDeleteBranch([...deleteBranch, branchId]);
    } else {
      const tempArray = [...deleteBranch];
      const index = tempArray.findIndex((e) => e === branchId);
      if (index !== -1) {
        tempArray.splice(index, 1);
        setDeleteBranch(tempArray);
      }
    }
  };

  const deleteMultipleBranches = async () => {
    let data = {
      company_id: parseInt(id),
      branches: deleteBranch,
    };
    const res = await deleteBranches(data);
    if (res.status === "success") {
      setShowModal(false);
      const tempUser = [...branchData];
      const data = tempUser.filter((e) => !deleteBranch.includes(e.IdSucursal));
      setBranchData([...data]);
      setDeleteBranch([]);
    }
  };

  const headerData = [
    {
      header: (
        <>
          {deleteBranch.length > 0 ? (
            <img
              src="/assets/images/close-red.png"
              alt=""
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                setShowModal(true);
              }}
            />
          ) : (
            <img src="/assets/images/close-dark.png" alt="" />
          )}
        </>
      ),
      cell: (props) => {
        return (
          <div className="form-group">
            <input
              type="checkbox"
              onChange={(e) =>
                deleteParticularBranch(props.IdSucursal, e.target.checked)
              }
              id={`${props.IdSucursal}`}
              checked={deleteBranch.includes(props.IdSucursal)}
            />
            <label htmlFor={`${props.IdSucursal}`}></label>
          </div>
        );
      },
    },
    {
      header: "Nombre de la sucursal",
      name: "Name",
    },
    {
      header: "Dirección",
      name: "Detail",
    },
    {
      header: "Editar",
      cell: (props) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              history.push({
                pathname: `/brands/${id}/branches/update/${props.IdSucursal}`,
              });
            }}
          >
            <img src="/assets/images/edit.png" alt="icon" />
          </div>
        );
      },
    },
  ];

  return (
    <BrandInner buttonDisable={true}>
      {id !== -1 ? (
        <>
          <TableComponent
            title="Sucursales"
            link={
              <Link to={`/brands/${id}/branches/create`}>
                <div className="add-new-user">
                  Agregar sucursal
                  <img src="/assets/images/branch-icon.png" alt="" />
                </div>
              </Link>
            }
            headerData={headerData}
            bodyData={branchData}
            noData={
              <div className="add-new-branch">
                <div className="add-new-branch-icon">
                  <img src="/assets/images/add-new-branch.png" alt="" />
                </div>
                <Link to={`/brands/${id}/branches/create`}>
                  <div className="title">Agregar sucursales</div>
                </Link>
              </div>
            }
            onChange={getAllBranches}
            dataCount={dataCount}
            search={true}
            searchNoData={
              <div className="search-result">
                <div className="search-result-content">
                  <div className="icon">
                    <img src="/assets/images/comments.png" alt="" />
                  </div>
                  <div class="content">
                    Tu búsqueda no tuvo resultados, por favor realiza otra{" "}
                  </div>
                </div>
              </div>
            }
          />
          <Modal visible={showModal} id="photo-modal" onClose={onCloseModal}>
            <div className="choose-photo-popup">
              <div className="choose-photo-wrap popup-content-center">
                <div className="title">
                  <h4>Eliminar sucursal</h4>
                </div>

                <div className="selection-photo-grid">
                  <h6>¿Estás segura de que quieres eliminar esto?</h6>
                </div>
                <div className="link">
                  <a
                    href="#!"
                    className="btn blue-square-btn mr-1"
                    onClick={onCloseModal}
                  >
                    Cancelar
                  </a>
                  <a
                    href="#!"
                    className="btn blue-square-btn"
                    onClick={deleteMultipleBranches}
                  >
                    Actualizar
                  </a>
                </div>
              </div>
            </div>
          </Modal>
        </>
      ) : (
        ""
      )}
    </BrandInner>
  );
};
