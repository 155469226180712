import React from 'react';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <footer className="user-footer footer">
      <div className="container">
        <div className="wrapper">
          <div className="row">
            <div className="col footer-logo">
              <div className="image">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/logo-white.png`}
                  alt="brand-logo"
                />
              </div>
            </div>
            <div className="col footer-links">
              <div className="quick-links">
                <h4>Nuestras marcas</h4>
                <ul>
                  <li>
                    <a
                      href="https://www.gdiuniformes.com/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      GDI Uniformes
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://bravouniforms.com/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Bravo Uniforms
                    </a>
                  </li>
                </ul>
              </div>
              <div className="quick-links">
                <h4>Uniformes</h4>
                <ul>
                  <li>
                    <Link to={`/admin/catalogue`}>Catálogo</Link>
                  </li>
                  <li>
                    <Link to={`/admin/order`}>Pre-ordenes</Link>
                  </li>
                  <li>
                    <Link to={`/admin/gdi-solutions`}>Soluciones GDI</Link>
                  </li>
                </ul>
              </div>
              <div className="quick-links">
                <h4>Reportes</h4>
                <ul>
                  <li>
                    <Link to={`/admin/report/consumption-report`}>Consumo</Link>
                  </li>
                  <li>
                    <Link to={`/admin/report/invoice-report`}>Facturación</Link>
                  </li>
                  <li>
                    <Link to={`/admin/report/follow-up-report`}>
                      Seguimiento
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="quick-links">
                <h4>Contacto</h4>
                <ul>
                  <li>
                    <Link to={`/admin/contact`}>Contactar a EC’s</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="disclaimer">
            <ul className="privacy">
              <li>
                <Link to={`/admin/policy-notice`}>Aviso de privacidad</Link>
              </li>
              <li>
                <Link to={`/admin/term-conditions`}>
                  Términos y condiciones
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};
