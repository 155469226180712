import React, { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { constants } from "../../app-config";
import TableComponent from "../../components/table/table";
import {
  getBaseReport,
  getBaseReportExcel,
} from "../../services/report.service";

const FollowUpReport = ({ userData }) => {
  const headerData = [
    {
      header: "Año",
      name: "Year",
    },
    {
      header: "Mes",
      name: "Month",
    },
    {
      header: "Folio de requisición",
      name: "Requi",
    },
    {
      header: "Folio de Orden de Compra",
      name: "Reference",
    },
    {
      header: "Folio Orden de Venta",
      name: "OV",
    },
    {
      header: "Fecha de recepción de pedido",
      name: "F_Recepcion_GDI",
    },
    {
      header: "Artículo",
      name: "Articulo",
    },
    {
      header: "Descripción",
      name: "Description",
    },
    {
      header: "Descripción del cliente",
      name: "Desc_Extrangera",
    },
    {
      header: "SKU Cliente",
      name: "Articulo_SKU",
    },
    {
      header: "Cantidad pendiente",
      name: "Cant_Pendiente",
    },
    {
      header: "Cantidad facturada",
      name: "Cantidad_Factura",
    },
    {
      header: "Código de rastreo",
      name: "Codigo_rastreo",
    },
    {
      header: "Comentario",
      name: "Comentario",
    },
    {
      header: "Folio de entrega",
      name: "Entrega",
    },
    {
      header: "Fecha tentativa de entrega",
      name: "F_Entrega",
    },
    {
      header: "Fecha de Orden de Compra",
      name: "F_OC_CTE",
    },
    {
      header: "Cliente",
      name: "Nombre_cliente",
    },
    {
      header: "Fecha real de entrega",
      name: "Fecha_real_entrega",
    },
    {
      header: "Folio de factura",
      name: "Folio_Factura",
    },
    {
      header: "Total",
      name: "Monto_Factura",
    },
    {
      header: "Nivel de servicio",
      name: "Nivel_servicio",
    },
    {
      header: "Paquetería",
      name: "Paqueteria",
    },
    {
      header: "Precio del producto",
      name: "Precio_Factura",
    },
    {
      header: "Quien recibió",
      name: "Quien_recibio",
    },
    {
      header: "Status",
      name: "Status",
    },
    {
      header: "Talla",
      name: "Talla",
    },
    // {
    //   header: "concatenar",
    //   name: "concatenar",
    // },
  ];
  const [dataCount, setDataCount] = useState(0);
  const [baseReportData, setBaseReportData] = useState([]);
  const [downloadloadingToggle, setDownloadLoadingToggle] = useState(false);

  const getBaseReportData = async (page) => {
    const res = await getBaseReport({
      page: page,
      IdCompanyGDI: userData?.company?.company_gdi,
    });

    if (res && Object.keys(res).length > 0) {
      setBaseReportData(res.base_report);
      setDataCount(res.total);
    }
  };

  const downloadExcel = async () => {
    setDownloadLoadingToggle(true);
    const res = await getBaseReportExcel({
      IdCompanyGDI: userData?.company?.company_gdi,
    });
    setDownloadLoadingToggle(false);

    if (Object.keys(res).length > 0) {
      window.open(constants.BACKEND_URL + res.file_path, "_blank");
    }
  };

  return (
    <>
      <section
        className="section report-topbar"
        style={{
          backgroundColor:
            userData && userData?.company?.color ? userData.company.color : "",
        }}
      >
        <div className="container">
          <div className="wrapper">
            <div className="title-wrapper">
              <div className="title">
                <h4>Reporte de seguimiento</h4>
              </div>
              <div className="image">
                {userData && userData?.company?.logo ? (
                  <img
                    src={constants.BACKEND_URL + userData.company.logo}
                    alt=""
                  />
                ) : (
                  <img src="/assets/images/gray-logo.png" alt="" />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section global-consumption-sec billing-report">
        <div className="container">
          <div className="wrapper">
            <div className="global-consumption-wrap">
              <div className="title">
                <h4>Base reporte</h4>
              </div>
              <div className="last-update-date">
                Última fecha de actualización:
                <strong> {moment().format("DD-MM-YYYY")}</strong>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section global-consumption-table-sec">
        <div className="container">
          <div className="wrapper">
            <div className="global-consumption-table">
              <div
                className={`global-consumption-tablewrap ${
                  baseReportData && baseReportData.length === 0
                    ? "nodata-table-height"
                    : ""
                }`}
              >
                <div className="global-consumption-table-inner">
                  <TableComponent
                    title=""
                    headerData={headerData}
                    bodyData={baseReportData}
                    onChange={getBaseReportData}
                    dataCount={dataCount}
                    search={false}
                    divClassName={""}
                    noData={
                      <div
                        style={{ textAlign: "center" }}
                        className="no-data-text"
                      >
                        Datos no encontrados
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
            {baseReportData && baseReportData.length > 0 && (
              <div className="download-excelwrap">
                <div
                  className="download-excel"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => downloadExcel()}
                >
                  {downloadloadingToggle && (
                    <i className="fa fa-spinner fa-spin button-loader"></i>
                  )}{" "}
                  Descargar en XLSX
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

const mapToStateProps = ({ auth: { user_details } }) => ({
  userData: user_details,
});
export default connect(mapToStateProps, null)(FollowUpReport);
