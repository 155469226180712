import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logoutUser } from "../../redux/actions/auth.actions";

const ResponsibleUserHeader = ({ logoutUser }) => {
  const [toggleHeader, setToggleHeader] = useState(false);
  return (
    <header id="header">
      <div className="header-main">
        <div className="header-section">
          <div className="logo">
            <div className="left-menu">
              <Link to={"/responsibe-user/orders"}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                  alt="Logo"
                />
              </Link>
            </div>
          </div>
          <div className="right-menu">
            <ul>
              <li>
                <Link to={`/responsibe-user/contact`}>
                  <div className="btn contact-btn text-white">Contacto</div>
                </Link>
              </li>
              <li className="my-account toggle-btn">
                <div
                  onClick={() => {
                    setToggleHeader(!toggleHeader);
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/user-circle-icon.png`}
                    alt="Logo"
                  />
                  <i className="fa fa-chevron-down"></i>
                </div>

                <ul
                  className={`my-account-toggle toggle-content ${
                    toggleHeader ? "show" : ""
                  }`}
                >
                  <li onClick={logoutUser}>
                    <i className="fa fa-times-circle"></i>Cerrar sesión
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};
const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser()),
});

export const Header = connect(null, mapDispatchToProps)(ResponsibleUserHeader);
