import { axiosPost } from "../axios/axios.config";
import {
  setFormProcessing,
  setLoading,
} from "../redux/actions/general.actions";
import { store } from "../redux/store";

export const getAdmins = async (data) => {
  store.dispatch(setLoading(true));
  const res = await axiosPost("user/list-admin", data);
  store.dispatch(setLoading(false));
  if (res["data"]) {
    return res["data"];
  }
  return [];
};

export const getAdmin = async (data) => {
  store.dispatch(setLoading(true));
  const res = await axiosPost("user/get-admin", data);
  store.dispatch(setLoading(false));
  if (res["data"]) {
    return res["data"];
  }
  return [];
};

export const saveAdmin = async (data) => {
  store.dispatch(setFormProcessing(true));
  const res = await axiosPost("user/save-admin", data);
  store.dispatch(setFormProcessing(false));
  if (res[["data"]]) {
    return res;
  }
  return false;
};

export const deleteAdmins = async (data) => {
  const res = await axiosPost("user/delete-admin", data);
  if (res && res.status === "success") {
    return res;
  }
  return false;
};
