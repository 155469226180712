import { axiosPost } from "../axios/axios.config";
import {
  setFormProcessing,
  setLoading,
} from "../redux/actions/general.actions";
import { store } from "../redux/store";

export const getUsers = async (data) => {
  store.dispatch(setLoading(true));
  const res = await axiosPost("user/list", data);
  store.dispatch(setLoading(false));
  if (res["data"]) {
    return res["data"];
  }
  return [];
};

export const getUser = async (data) => {
  store.dispatch(setLoading(true));
  const res = await axiosPost("user/get", data);
  store.dispatch(setLoading(false));
  if (res["data"]) {
    return res["data"];
  }
  return [];
};

export const getAllUsers = async (data) => {
  store.dispatch(setLoading(true));
  const res = await axiosPost("user/all", data);
  store.dispatch(setLoading(false));
  if (res["data"]) {
    return res["data"];
  }
  return [];
};

export const getResponsibleUsers = async (data) => {
  store.dispatch(setLoading(true));
  const res = await axiosPost("user/responsible", data);
  store.dispatch(setLoading(false));
  if (res["data"]) {
    return res["data"];
  }
  return [];
};

export const saveUser = async (data) => {
  store.dispatch(setFormProcessing(true));
  const res = await axiosPost("user/save", data);
  store.dispatch(setFormProcessing(false));
  if (res[["data"]]) {
    return res;
  }
  return false;
};

export const deleteUsers = async (data) => {
  const res = await axiosPost("user/delete", data);
  if (res && res.status === "success") {
    return res;
  }
  return false;
};
