import moment from "moment";

export const getYears = (startDate) => {
  if (startDate !== 0) {
    const years = [];
    const dateStart = moment(startDate);
    const dateEnd = moment();

    while (
      dateEnd.diff(dateStart, "years") >= 0 &&
      dateStart.year() !== dateEnd.year()
    ) {
      years.push(dateStart.format("YYYY"));
      dateStart.add(1, "year");
    }

    if (years.length > 0) {
      return years;
    } else {
      return [dateEnd.year()];
    }
  } else {
    return [];
  }
};
