import React, { Suspense, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import { store } from "./redux/store";
import { SplashScreen } from "./components/splash-screen/splash-screen";
import { AdminLayout } from "./layout/admin/admin-layout";
import { BrandAdminLayout } from "./layout/brandAdmin/brand-admin-layout";
import { UserLayout } from "./layout/user/user-layout";
import { ResponsibleUserLayout } from "./layout/responsibleUser/responsible-user-layout";
import { getAuthUser } from "./redux/actions/auth.actions";
import Login from "./pages/authentication/login";
import BranchForm from "./pages/brands/brand-inner/configuration/branches/branch-form";
import { Branches } from "./pages/brands/brand-inner/configuration/branches/branches";
import GeneralForm from "./pages/brands/brand-inner/configuration/general/general-form";
import Orders from "./pages/brands/brand-inner/configuration/orders/orders";
import UserForm from "./pages/brands/brand-inner/configuration/users/user-form";
import { Users } from "./pages/brands/brand-inner/configuration/users/users";
import Brands from "./pages/brands/brands";
import AdminForm from "./pages/brands/admin/admin-form";
import AdminList from "./pages/brands/admin/admins";
import MainCatalogue from "./pages/catalogue/index";
import CatalogueDetail from "./pages/catalogue/catalogue-detail";
import BrandOrders from "./pages/order/brand-orders";
import PreOrder from "./pages/order/pre-order";
import ConsumptionReport from "./pages/report/consumption-report";
import FollowUpReport from "./pages/report/followup-report";
import InvoiceReport from "./pages/report/invoice-report";
import Solutions from "./pages/solutions/gdi-solutions";
import Contact from "./pages/contact/contact";
import ViewOrder from "./pages/order/view-order";
import CartOrderTable from "./pages/order/cart-order-table";
import TermCondition from "./pages/term-condition/term-condition";
import PolicyNotice from "./pages/policy-notice/policy-notice";
import ConfirmationOrder from "./pages/order/confirmation-order";
import ConfirmationWarning from "./pages/order/confirmation-warning";
import SuccessfullConfirmation from "./pages/order/successfull-confirmation";
import ForgotPassword from "./pages/authentication/forgot-password";
import ForgotPasswordSuccessFail from "./pages/authentication/forgot-password-sucess-fail";
import ResponsibleUserOrders from "./pages/responsible-user/respobsible-user-order";
import ImportData from "./pages/brands/brand-inner/configuration/import-data/import-data";

const { superAdmin, admin, basic, responsibleUser } = {
  superAdmin: "superadmin",
  admin: "admin",
  basic: "basic",
  responsibleUser: "responsibeuser",
};

const CustomRoute = ({
  adminLayout,
  userLayout,
  brandAdminLayout,
  responsibleUserLayout,
  history,
  redirectToLogin,
  auth,
  allowed,
  userData,
  component: Component,
  ...rest
}) => {
  let redirectTo = "";
  useEffect(() => {
    if (redirectToLogin) {
      history.push("/login");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectToLogin]);

  const state = store.getState();
  const token = state.token;
  // const token = localStorage.getItem("token");

  if (auth) {
    if (!token) {
      redirectTo = "/login";
    }
  } else {
    if (token && Object.keys(userData).length) {
      if (userData?.permission?.type === superAdmin) {
        redirectTo = "/";
      }
      if (userData?.permission?.type === basic) {
        redirectTo = "/catalogue";
      }
      if (userData.permission.type === admin) {
        redirectTo = "/admin/catalogue";
      }
      if (userData.permission.type === responsibleUser) {
        redirectTo = "/responsibe-user/orders";
      }
    }
  }

  // check role here
  if (Object.keys(userData).length > 0 && allowed) {
    if (!allowed.includes(userData?.permission?.type)) {
      if (userData.permission.type === superAdmin) {
        redirectTo = "/";
      }
      if (userData.permission.type === basic) {
        redirectTo = "/catalogue";
      }
      if (userData.permission.type === admin) {
        redirectTo = "/admin/catalogue";
      }
      if (userData.permission.type === responsibleUser) {
        redirectTo = "/responsibe-user/orders";
      }
    }
  }

  const route = (
    <Route
      {...rest}
      render={(props) =>
        redirectTo ? (
          <Redirect
            to={{ pathname: redirectTo, state: { from: props.location } }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );

  return adminLayout ? (
    <AdminLayout>{route}</AdminLayout>
  ) : userLayout ? (
    <UserLayout>{route}</UserLayout>
  ) : brandAdminLayout ? (
    <BrandAdminLayout>{route}</BrandAdminLayout>
  ) : responsibleUserLayout ? (
    <ResponsibleUserLayout>{route}</ResponsibleUserLayout>
  ) : (
    <Route render={(props) => <Component {...props} />} {...rest} />
  );
};

const mapStateToProps = ({ auth: { redirect_to_login, user_details } }) => ({
  redirectToLogin: redirect_to_login,
  userData: user_details,
});
const mapDispatchToProps = (dispatch) => ({
  getUser: () => dispatch(getAuthUser()),
});

const AppRoute = connect(mapStateToProps)(withRouter(CustomRoute));
const AppComponent = ({ getUser, userData }) => {
  const [showRoutes, setShowRoutes] = useState(false);
  useEffect(() => {
    const state = store.getState();
    const token = state.token;
    if (token) {
      if (Object.keys(userData).length === 0) {
        getUser();
      } else {
        setShowRoutes(true);
      }
    } else {
      setShowRoutes(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      setShowRoutes(true);
    }
  }, [userData]);

  return (
    <>
      {showRoutes ? (
        <Suspense fallback={"loading"}>
          <BrowserRouter>
            <Switch>
              {/* super admin */}
              <AppRoute
                exact
                path="/"
                component={Brands}
                auth
                adminLayout
                allowed={[superAdmin]}
              />
              <AppRoute
                exact
                path="/brands/create"
                component={GeneralForm}
                auth
                adminLayout
                allowed={[superAdmin]}
              />
              <AppRoute
                exact
                path="/brands/update/:id"
                component={GeneralForm}
                auth
                adminLayout
                allowed={[superAdmin]}
              />
              <AppRoute
                exact
                path="/brands/:id/users"
                component={Users}
                auth
                adminLayout
                allowed={[superAdmin]}
              />
              <AppRoute
                exact
                path="/brands/:id/users/create"
                component={UserForm}
                auth
                adminLayout
                allowed={[superAdmin]}
              />
              <AppRoute
                exact
                path="/brands/:id/users/update/:userId"
                component={UserForm}
                auth
                adminLayout
                allowed={[superAdmin]}
              />
              <AppRoute
                exact
                path="/brands/:id/branches"
                component={Branches}
                auth
                adminLayout
                allowed={[superAdmin]}
              />
              <AppRoute
                exact
                path="/brands/:id/branches/create"
                component={BranchForm}
                auth
                adminLayout
                allowed={[superAdmin]}
              />
              <AppRoute
                exact
                path="/brands/:id/branches/update/:branchId"
                component={BranchForm}
                auth
                adminLayout
                allowed={[superAdmin]}
              />
              <AppRoute
                exact
                path="/brands/:id/orders"
                component={Orders}
                auth
                adminLayout
                allowed={[superAdmin]}
              />
              <AppRoute
                exact
                path="/admin/create"
                component={AdminForm}
                auth
                adminLayout
                allowed={[superAdmin]}
              />
              <AppRoute
                exact
                path="/admin/admins"
                component={AdminList}
                auth
                adminLayout
                allowed={[superAdmin]}
              />
              <AppRoute
                exact
                path="/admin/update/:id"
                component={AdminForm}
                auth
                adminLayout
                allowed={[superAdmin]}
              />
              <AppRoute
                exact
                path="/brands/:id/import-data"
                component={ImportData}
                auth
                adminLayout
                allowed={[superAdmin]}
              />

              {/* basic route */}
              <AppRoute
                exact
                path="/catalogue"
                component={MainCatalogue}
                auth
                userLayout
                allowed={[basic]}
              />
              <AppRoute
                exact
                path="/catalogue/detail/:productId"
                component={CatalogueDetail}
                auth
                userLayout
                allowed={[basic]}
              />
              <AppRoute
                exact
                path="/cart/products"
                component={CartOrderTable}
                auth
                userLayout
                allowed={[basic]}
              />
              <AppRoute
                exact
                path="/order"
                component={PreOrder}
                auth
                userLayout
                allowed={[basic]}
              />
              <AppRoute
                exact
                path="/order/:orderId"
                component={ViewOrder}
                auth
                userLayout
                allowed={[basic]}
              />
              <AppRoute
                exact
                path="/order/admin/:orderId/:companyId"
                component={ViewOrder}
                auth
                adminLayout
                allowed={[superAdmin]}
              />
              <AppRoute
                exact
                path="/order/confirmation/:id"
                component={ConfirmationOrder}
                auth
                userLayout
                allowed={[basic]}
              />
              <AppRoute
                exact
                path="/order/confirmation-warning/:id/:branchId"
                component={ConfirmationWarning}
                auth
                userLayout
                allowed={[basic]}
              />
              <AppRoute
                exact
                path="/order/successfull-confirmation/:id/:orderId"
                component={SuccessfullConfirmation}
                auth
                userLayout
                allowed={[basic]}
              />
              <AppRoute
                exact
                path="/report/follow-up-report"
                component={FollowUpReport}
                auth
                userLayout
                allowed={[basic]}
              />
              <AppRoute
                exact
                path="/gdi-solutions"
                component={Solutions}
                auth
                userLayout
                allowed={[basic]}
              />
              <AppRoute
                exact
                path="/contact"
                component={Contact}
                auth
                userLayout
                allowed={[basic]}
              />
              <AppRoute
                exact
                path="/term-conditions"
                component={TermCondition}
                auth
                userLayout
                allowed={[basic]}
              />
              <AppRoute
                exact
                path="/policy-notice"
                component={PolicyNotice}
                auth
                userLayout
                allowed={[basic]}
              />

              {/* brand admin route */}
              <AppRoute
                exact
                path="/admin/catalogue"
                component={MainCatalogue}
                auth
                brandAdminLayout
                allowed={[admin]}
              />
              <AppRoute
                exact
                path="/admin/catalogue/detail/:productId"
                component={CatalogueDetail}
                auth
                brandAdminLayout
                allowed={[admin]}
              />
              <AppRoute
                exact
                path="/admin/cart/products"
                component={CartOrderTable}
                auth
                brandAdminLayout
                allowed={[admin]}
              />
              <AppRoute
                exact
                path="/admin/order"
                component={PreOrder}
                auth
                brandAdminLayout
                allowed={[admin]}
              />
              <AppRoute
                exact
                path="/admin/order/:orderId"
                component={ViewOrder}
                auth
                brandAdminLayout
                allowed={[admin]}
              />
              <AppRoute
                exact
                path="/admin/order/confirmation/:id"
                component={ConfirmationOrder}
                auth
                brandAdminLayout
                allowed={[admin]}
              />
              <AppRoute
                exact
                path="/admin/order/confirmation-warning/:id/:branchId"
                component={ConfirmationWarning}
                auth
                brandAdminLayout
                allowed={[admin]}
              />
              <AppRoute
                exact
                path="/admin/order/successfull-confirmation/:id/:orderId"
                component={SuccessfullConfirmation}
                auth
                brandAdminLayout
                allowed={[admin]}
              />

              <AppRoute
                exact
                path="/admin/report/consumption-report"
                component={ConsumptionReport}
                auth
                brandAdminLayout
                allowed={[admin]}
              />
              <AppRoute
                exact
                path="/admin/report/invoice-report"
                component={InvoiceReport}
                auth
                brandAdminLayout
                allowed={[admin]}
              />
              <AppRoute
                exact
                path="/admin/report/follow-up-report"
                component={FollowUpReport}
                auth
                brandAdminLayout
                allowed={[admin]}
              />

              <AppRoute
                exact
                path="/admin/gdi-solutions"
                component={Solutions}
                auth
                brandAdminLayout
                allowed={[admin]}
              />
              <AppRoute
                exact
                path="/admin/contact"
                component={Contact}
                auth
                brandAdminLayout
                allowed={[admin]}
              />
              <AppRoute
                exact
                path="/admin/term-conditions"
                component={TermCondition}
                auth
                brandAdminLayout
                allowed={[admin]}
              />
              <AppRoute
                exact
                path="/admin/policy-notice"
                component={PolicyNotice}
                auth
                brandAdminLayout
                allowed={[admin]}
              />
              <AppRoute
                exact
                path="/admin/brand-orders"
                component={BrandOrders}
                auth
                brandAdminLayout
                allowed={[admin]}
              />

              <AppRoute
                exact
                path="/admin/brand-orders/:orderId/:orderNumber"
                component={ViewOrder}
                auth
                brandAdminLayout
                allowed={[admin]}
              />

              {/* responsible user */}
              <AppRoute
                exact
                path="/responsibe-user/orders"
                component={ResponsibleUserOrders}
                auth
                responsibleUserLayout
                allowed={[responsibleUser]}
              />
              <AppRoute
                exact
                path="/responsibe-user/view-order/:orderId/:orderNumber"
                component={ViewOrder}
                auth
                responsibleUserLayout
                allowed={[responsibleUser]}
              />
              <AppRoute
                exact
                path="/responsibe-user/contact"
                component={Contact}
                auth
                responsibleUserLayout
                allowed={[responsibleUser]}
              />

              {/* authentication route */}
              <AppRoute exact path="/login" component={Login} />
              <AppRoute exact path="/super-admin/login" component={Login} />
              <AppRoute
                exact
                path="/forgot-password"
                component={ForgotPassword}
              />
              <AppRoute
                exact
                path="/super-admin/forgot-password"
                component={ForgotPassword}
              />
              <AppRoute
                exact
                path="/forgot-password-fail-success"
                component={ForgotPasswordSuccessFail}
              />
              <AppRoute
                exact
                path="/super-admin/forgot-password-fail-success"
                component={ForgotPasswordSuccessFail}
              />

              <Redirect from="*" to="/" />
            </Switch>
          </BrowserRouter>
        </Suspense>
      ) : (
        <SplashScreen />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppComponent);
