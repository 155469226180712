import React, { useState } from "react";
import { connect } from "react-redux";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Dropzone from "react-dropzone";
import { constants } from "../../../../../app-config";
import { facturacionGraphReportsImport } from "../../../../../services/import-file.service";

const FactuartionReportImportForm = ({ company, setActiveTab, compnayId }) => {
  const initials = {
    fileGraph: "",
    fileReport: "",
  };

  const [submitted, setSubmitted] = useState(false);

  const FactuartionReportImportFormSchema = Yup.object().shape({
    fileGraph: Yup.array()
      .required("Este campo es obligatorio")
      .test(
        "file test",
        "por favor, importe el archivo de extensión xlsx",
        (value) => {
          let toggle = true;
          const file = value?.[0];
          const fileName = file?.name.split(".").pop();
          if (
            !value ||
            value.length === 0 ||
            !file ||
            !fileName ||
            fileName !== "xlsx"
          ) {
            toggle = false;
          }
          return toggle;
        }
      ),
    fileReport: Yup.array()
      .required("Este campo es obligatorio")
      .test(
        "file test",
        "por favor, importe el archivo de extensión xlsx",
        (value) => {
          let toggle = true;
          const file = value?.[0];
          const fileName = file?.name.split(".").pop();
          if (
            !value ||
            value.length === 0 ||
            !file ||
            !fileName ||
            fileName !== "xlsx"
          ) {
            toggle = false;
          }
          return toggle;
        }
      ),
  });

  const onSubmit = async (values) => {
    setSubmitted(true);
    const formData = new FormData();

    if (values.fileGraph[0]) {
      formData.append("fileGraph", values.fileGraph[0]);
    }
    if (values.fileReport[0]) {
      formData.append("fileReport", values.fileReport[0]);
    }
    if (
      Object.keys(company).length > 0 &&
      company?.companyGDI &&
      company.companyGDI !== ""
    ) {
      formData.append("idCompanyGdi", company.companyGDI);
    }
    formData.append("IdCompany", compnayId);

    const res = await facturacionGraphReportsImport(formData);
    setSubmitted(false);
    if (res && res.status === "success") {
      setActiveTab("4");
    }
  };
  return (
    <div className="content-detail">
      <div className="content-detail-button">
        <div>
          <a
            href={`${constants.BACKEND_URL}storage/excel-demo/ReporteDeFacturacionGraph.xlsx`}
            className="btn blue-square-btn"
            download
          >
            Primer informe de referencia
          </a>
        </div>
        <div style={{ marginLeft: "10px", marginBottom: "15px" }}>
          <a
            href={`${constants.BACKEND_URL}storage/excel-demo/ReporteFacturacion2.xlsx`}
            className="btn blue-square-btn"
            download
          >
            Segundo informe de referencia
          </a>
        </div>
      </div>
      <Formik
        initialValues={initials}
        onSubmit={onSubmit}
        validationSchema={FactuartionReportImportFormSchema}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="drop-image">
              <label>Monto facturado por linea de producto</label>
              <Dropzone
                accept=".xlsx"
                multiple={false}
                onDrop={(acceptedFiles) => {
                  const imageDataOnDrop = acceptedFiles.map((file) =>
                    Object.assign(file, {
                      preview: URL.createObjectURL(file),
                    })
                  );

                  setFieldValue("fileGraph", imageDataOnDrop);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <>
                    <div className="drop-box" {...getRootProps()}>
                      <input {...getInputProps()} />
                      {values.fileGraph === "" &&
                        "Drag and drop file or select xlsx file"}
                      <aside>
                        {values.fileGraph &&
                          values.fileGraph.map((file, index) => {
                            return (
                              <div className="dz_img_wrp" key={file.name}>
                                <div className="thumb">
                                  <div className="thumb-inner">
                                    <img
                                      src="/assets/images/file-icon.png"
                                      alt="itemImage"
                                    />
                                  </div>
                                  <p className="thumb-file-name">{file.name}</p>
                                </div>
                              </div>
                            );
                          })}
                      </aside>
                    </div>
                  </>
                )}
              </Dropzone>
              <ErrorMessage
                name="fileGraph"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="drop-image mt-20">
              <label>Reportes de Facturación</label>

              <Dropzone
                accept=".xlsx"
                multiple={false}
                onDrop={(acceptedFiles) => {
                  const imageDataOnDrop = acceptedFiles.map((file) =>
                    Object.assign(file, {
                      preview: URL.createObjectURL(file),
                    })
                  );

                  setFieldValue("fileReport", imageDataOnDrop);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <>
                    <div className="drop-box" {...getRootProps()}>
                      <input {...getInputProps()} />
                      {values.fileReport === "" &&
                        "Drag and drop file or select xlsx file"}
                      <aside>
                        {values.fileReport &&
                          values.fileReport.map((file, index) => {
                            return (
                              <div className="dz_img_wrp" key={file.name}>
                                <div className="thumb">
                                  <div className="thumb-inner">
                                    <img
                                      src="/assets/images/file-icon.png"
                                      alt="itemImage"
                                    />
                                  </div>
                                  <p className="thumb-file-name">{file.name}</p>
                                </div>
                              </div>
                            );
                          })}
                      </aside>
                    </div>
                  </>
                )}
              </Dropzone>
              <ErrorMessage
                name="fileReport"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="tabSubmit-btn">
              <button
                className="btn blue-square-btn"
                type="submit"
                disabled={submitted}
              >
                {submitted && (
                  <i className="fa fa-spinner fa-spin button-loader"></i>
                )}
                Enviar
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

// export default FactuartionReportImportForm;
const mapStateToProps = ({ company }) => ({
  company,
});

export default connect(mapStateToProps, null)(FactuartionReportImportForm);
