import React, { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Bar } from "react-chartjs-2";
import { constants } from "../../app-config";
import TableComponent from "../../components/table/table";
import {
  getFacturactionReport,
  getFacturactionReportExcel,
  getFacturactionGraph,
  getFacturactionGraphExcel,
} from "../../services/report.service";

const InvoiceReport = ({ userData }) => {
  const headerData = [
    {
      header: "Mes",
      name: "MesMonth",
    },
    {
      header: "Total Gral",
      name: "Total",
      cell: (props) => {
        return props?.Total ? "$" + parseFloat(props.Total).toFixed(2) : "-";
      },
    },
    {
      header: "Bata",
      name: "Bata",
      cell: (props) => {
        return props?.Bata ? "$" + parseFloat(props.Bata).toFixed(2) : "-";
      },
    },
    {
      header: "Camisa",
      name: "Camisa",
      cell: (props) => {
        return props?.Camisa ? "$" + parseFloat(props.Camisa).toFixed(2) : "-";
      },
    },
    {
      header: "Camisola",
      name: "Camisloa",
      cell: (props) => {
        return props?.Camisloa
          ? "$" + parseFloat(props.Camisloa).toFixed(2)
          : "-";
      },
    },
    {
      header: "Chamarra",
      name: "Chamarra",
      cell: (props) => {
        return props?.Chamarra
          ? "$" + parseFloat(props.Chamarra).toFixed(2)
          : "-";
      },
    },
    {
      header: "Gorra",
      name: "Gorra",
      cell: (props) => {
        return props?.Gorra ? "$" + parseFloat(props.Gorra).toFixed(2) : "-";
      },
    },
    {
      header: "Multiestilo",
      name: "Multiestilo",
      cell: (props) => {
        return props?.Multiestilo
          ? "$" + parseFloat(props.Multiestilo).toFixed(2)
          : "-";
      },
    },
    {
      header: "Pantalón gabardina",
      name: "Pantalon_Gabardina",
      cell: (props) => {
        return props?.Pantalon_Gabardina
          ? "$" + parseFloat(props.Pantalon_Gabardina).toFixed(2)
          : "-";
      },
    },
    {
      header: "Pantalon Cargo",
      name: "Pantalon_Cargo",
      cell: (props) => {
        return props?.Pantalon_Cargo
          ? "$" + parseFloat(props.Pantalon_Cargo).toFixed(2)
          : "-";
      },
    },
    {
      header: "Playera",
      name: "Playera",
      cell: (props) => {
        return props?.Playera
          ? "$" + parseFloat(props.Playera).toFixed(2)
          : "-";
      },
    },
    {
      // header: "Playera Comprada",
      header: "Playera Básica",
      name: "Playera_Basica",
      cell: (props) => {
        return props?.Playera_Comprada
          ? "$" + parseFloat(props.Playera_Comprada).toFixed(2)
          : "-";
      },
    },
    {
      header: "Cubreboca",
      name: "Cubreboca",
      cell: (props) => {
        return props?.Playera_Comprada
          ? "$" + parseFloat(props.Playera_Comprada).toFixed(2)
          : "-";
      },
    },
  ];
  const headerDataForFacturacion = [
    {
      header: "Núm de factura",
      name: "Numero_de_factura",
    },
    {
      header: "Numero de prendas",
      name: "Numero_de_prendas",
    },
    {
      header: "Monto",
      name: "Monto_facturado",
      cell: (props) => {
        return "$" + props.Monto_facturado;
      },
    },
    {
      header: "Mes",
      name: "MesMonth",
    },
  ];
  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    Bata: [],
    Camisa: [],
    Camisloa: [],
    Chamarra: [],
    Gorra: [],
    Multiestilo: [],
    Pantalon_Gabardina: [],
    Playera: [],
    Pantalon_Cargo: [],
    Playera_Comprada: [],
    Cubreboca: [],
    Total: [],
  };

  const [dataCount, setDataCount] = useState(0);
  const [invoiceReportData, setInvoiceReportData] = useState([]);
  const [facturacionDataCount, setFacturacionDataCount] = useState(0);
  const [facturacionReportData, setFacturacionReportData] = useState([]);
  const [facturationGraph, setFacturationGraph] = useState(data);
  const [downloadloadingToggleFirst, setDownloadLoadingToggleFirst] =
    useState(false);
  const [downloadloadingToggleSecond, setDownloadLoadingToggleSecond] =
    useState(false);

  const getFacturacionReportData = async (page) => {
    const res = await getFacturactionReport({
      page: page,
      IdCompanyGDI: userData?.company?.company_gdi,
    });

    if (res && Object.keys(res).length > 0) {
      setFacturacionReportData(res.reportDeFacturaction2);
      setFacturacionDataCount(res.total);
    }
  };

  const getFacturactionGraphDataAndReport = async (IdCompanyGDI) => {
    const res = await getFacturactionGraph({
      IdCompanyGDI: userData?.company?.company_gdi,
    });

    if (
      res &&
      Object.keys(res).length > 0 &&
      res?.reporte_de_facturaction_graphs
    ) {
      setInvoiceReportData(res.reporte_de_facturaction_graphs);
      setDataCount(res.total);
      let obj = {};
      let BataValue = Array.from(Array(12)).fill(0);
      let CamisaValue = Array.from(Array(12)).fill(0);
      let CamisloaValue = Array.from(Array(12)).fill(0);
      let ChamarraValue = Array.from(Array(12)).fill(0);
      let GorraValue = Array.from(Array(12)).fill(0);
      let MultiestiloValue = Array.from(Array(12)).fill(0);
      let PantalonCargoValue = Array.from(Array(12)).fill(0);
      let PantalonGabardinaValue = Array.from(Array(12)).fill(0);
      let PlayeraValue = Array.from(Array(12)).fill(0);
      let PlayeraCompradaValue = Array.from(Array(12)).fill(0);
      let CubrebocaValue = Array.from(Array(12)).fill(0);
      let TotalValue = Array.from(Array(12)).fill(0);

      res.reporte_de_facturaction_graphs.forEach((e) => {
        if (e.hasOwnProperty("Bata")) {
          BataValue[parseInt(e.Mes) - 1] = e.Bata;
          obj["Bata"] = BataValue;
        }
        if (e.hasOwnProperty("Camisa")) {
          CamisaValue[parseInt(e.Mes) - 1] = e.Camisa;
          obj["Camisa"] = CamisaValue;
        }
        if (e.hasOwnProperty("Camisloa")) {
          CamisloaValue[parseInt(e.Mes) - 1] = e.Camisloa;
          obj["Camisloa"] = CamisloaValue;
        }
        if (e.hasOwnProperty("Chamarra")) {
          ChamarraValue[parseInt(e.Mes) - 1] = e.Chamarra;
          obj["Chamarra"] = ChamarraValue;
        }
        if (e.hasOwnProperty("Gorra")) {
          GorraValue[parseInt(e.Mes) - 1] = e.Gorra;
          obj["Gorra"] = GorraValue;
        }
        if (e.hasOwnProperty("Multiestilo")) {
          MultiestiloValue[parseInt(e.Mes) - 1] = e.Multiestilo;
          obj["Multiestilo"] = MultiestiloValue;
        }
        if (e.hasOwnProperty("Pantalon_Cargo")) {
          PantalonCargoValue[parseInt(e.Mes) - 1] = e.Pantalon_Cargo;
          obj["Pantalon_Cargo"] = PantalonCargoValue;
        }
        if (e.hasOwnProperty("Pantalon_Gabardina")) {
          PantalonGabardinaValue[parseInt(e.Mes) - 1] = e.Pantalon_Gabardina;
          obj["Pantalon_Gabardina"] = PantalonGabardinaValue;
        }
        if (e.hasOwnProperty("Playera")) {
          PlayeraValue[parseInt(e.Mes) - 1] = e.Playera;
          obj["Playera"] = PlayeraValue;
        }
        if (e.hasOwnProperty("Playera_Comprada")) {
          PlayeraCompradaValue[parseInt(e.Mes) - 1] = e.Playera_Comprada;
          obj["Playera_Comprada"] = PlayeraCompradaValue;
        }
        if (e.hasOwnProperty("Cubreboca")) {
          CubrebocaValue[parseInt(e.Mes) - 1] = e.Cubreboca;
          obj["Cubreboca"] = CubrebocaValue;
        }
        if (e.hasOwnProperty("Total")) {
          TotalValue[parseInt(e.Mes) - 1] = e.Total;
          obj["Total"] = TotalValue;
        }
      });
      setFacturationGraph({ ...facturationGraph, ...obj });
    }
  };

  const downloadExcelForInvoiceReport = async () => {
    setDownloadLoadingToggleFirst(true);
    const res = await getFacturactionGraphExcel({
      IdCompanyGDI: userData?.company?.company_gdi,
    });
    setDownloadLoadingToggleFirst(false);

    if (Object.keys(res).length > 0) {
      window.open(constants.BACKEND_URL + res.file_path, "_blank");
    }
  };

  const downloadExcelForFacturacionReport = async () => {
    setDownloadLoadingToggleSecond(true);
    const res = await getFacturactionReportExcel({
      IdCompanyGDI: userData?.company?.company_gdi,
      // IdCompanyGDI: "PT OXXO",
    });
    setDownloadLoadingToggleSecond(false);

    if (Object.keys(res).length > 0) {
      window.open(constants.BACKEND_URL + res.file_path, "_blank");
    }
  };

  return (
    <>
      <section
        className="section report-topbar"
        style={{
          backgroundColor:
            userData && userData?.company?.color ? userData.company.color : "",
        }}
      >
        <div className="container">
          <div className="wrapper">
            <div className="title-wrapper">
              <div className="title">
                <h4>Reportes de Facturación</h4>
              </div>
              <div className="image">
                {userData && userData?.company?.logo ? (
                  <img
                    src={constants.BACKEND_URL + userData.company.logo}
                    alt=""
                  />
                ) : (
                  <img src="/assets/images/gray-logo.png" alt="" />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section global-consumption-sec invoice-report">
        <div className="container">
          <div className="wrapper">
            <div className="global-consumption-wrap">
              <div className="title">
                <h4>Monto facturado por linea de producto</h4>
              </div>
              <div className="last-update-date">
                Última fecha de actualización:
                <strong> {moment().format("DD-MM-YYYY")}</strong>
              </div>
            </div>
            <div className="global-consumption-graph">
              <div className="image">
                {/* <img src="/assets/images/consumption-graph.png" /> */}
                <Bar
                  data={{
                    labels: facturationGraph.labels,
                    responsive: true,
                    datasets: [
                      {
                        label: "Total general",
                        backgroundColor: "#ff5883",
                        data: facturationGraph.Total,
                      },
                      {
                        label: "Bata",
                        backgroundColor: "#c9cbcf",
                        data: facturationGraph.Bata,
                        stack: "1",
                      },
                      {
                        label: "Camisa",
                        backgroundColor: "#d572Cf",
                        data: facturationGraph.Camisa,
                        stack: "1",
                      },
                      {
                        label: "Camisola",
                        backgroundColor: "#ffcb68",
                        data: facturationGraph.Camisloa,
                        stack: "1",
                      },
                      {
                        label: "Multiestilo",
                        backgroundColor: "#00c2c0",
                        data: facturationGraph.Multiestilo,
                        stack: "1",
                      },
                      {
                        label: "Pantalon gabardina",
                        backgroundColor: "#00a5e6",
                        data: facturationGraph.Pantalon_Gabardina,
                        stack: "1",
                      },
                      {
                        label: "Playera Básica",
                        backgroundColor: "#9e67f6",
                        data: facturationGraph.Playera_Comprada,
                        stack: "1",
                      },
                      {
                        label: "Playera",
                        backgroundColor: "#ff9a50",
                        data: facturationGraph.Playera,
                        stack: "1",
                      },
                      {
                        label: "Chamarra",
                        backgroundColor: "#000",
                        data: facturationGraph.Chamarra,
                        stack: "1",
                      },
                      {
                        label: "Gorra",
                        backgroundColor: "#123808",
                        data: facturationGraph.Gorra,
                        stack: "1",
                      },
                      {
                        label: "Pantalon Cargo",
                        backgroundColor: "#9e6rtf",
                        data: facturationGraph.Pantalon_Cargo,
                        stack: "1",
                      },
                      {
                        label: "Cubreboca",
                        backgroundColor: "#800080",
                        data: facturationGraph.Cubreboca,
                        stack: "1",
                      },
                    ],
                  }}
                  height={150}
                  options={{
                    scales: {
                      xAxes: {
                        ticks: {
                          autoSkip: false,
                          maxRotation: 30,
                          minRotation: 30,
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section global-consumption-table-sec">
        <div className="container">
          <div className="wrapper">
            <div className="global-consumption-table">
              <div
                className={`global-consumption-tablewrap ${
                  invoiceReportData && invoiceReportData.length === 0
                    ? "nodata-table-height"
                    : ""
                }`}
              >
                <div className="global-consumption-table-inner">
                  <TableComponent
                    title=""
                    headerData={headerData}
                    bodyData={invoiceReportData}
                    onChange={getFacturactionGraphDataAndReport}
                    dataCount={dataCount}
                    search={false}
                    divClassName={""}
                    noData={
                      <div
                        style={{ textAlign: "center" }}
                        className="no-data-text"
                      >
                        Datos no encontrados
                      </div>
                    }
                    paginationShow={false}
                  />
                </div>
              </div>
            </div>
            {invoiceReportData && invoiceReportData.length > 0 && (
              <div className="download-excelwrap">
                <div
                  className="download-excel"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => downloadExcelForInvoiceReport()}
                >
                  {downloadloadingToggleFirst && (
                    <i className="fa fa-spinner fa-spin button-loader"></i>
                  )}{" "}
                  Descargar en XLSX
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="section global-consumption-sec billing-report">
        <div className="container">
          <div className="wrapper">
            <div className="global-consumption-wrap">
              <div className="title">
                <h4>Reportes de Facturación</h4>
              </div>
              <div className="last-update-date">
                Última fecha de actualización:
                <strong> {moment().format("DD-MM-YYYY")}</strong>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section global-consumption-table-sec">
        <div className="container">
          <div className="wrapper">
            <div className="global-consumption-table">
              <div
                className={`global-consumption-tablewrap ${
                  facturacionReportData && facturacionReportData.length === 0
                    ? "nodata-table-height"
                    : ""
                }`}
              >
                <div className="global-consumption-table-inner">
                  <TableComponent
                    title=""
                    headerData={headerDataForFacturacion}
                    bodyData={facturacionReportData}
                    onChange={getFacturacionReportData}
                    dataCount={facturacionDataCount}
                    search={false}
                    divClassName={""}
                    noData={
                      <div
                        style={{ textAlign: "center" }}
                        className="no-data-text"
                      >
                        Datos no encontrados
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
            {facturacionReportData && facturacionReportData.length > 0 && (
              <div className="download-excelwrap">
                <div
                  className="download-excel"
                  onClick={(e) => downloadExcelForFacturacionReport()}
                  style={{ cursor: "pointer" }}
                >
                  {downloadloadingToggleSecond && (
                    <i className="fa fa-spinner fa-spin button-loader"></i>
                  )}{" "}
                  Descargar en XLSX
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

const mapToStateProps = ({ auth: { user_details } }) => ({
  userData: user_details,
});
export default connect(mapToStateProps, null)(InvoiceReport);
