import React, { useEffect, useState, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Catalogue from "./catalogue";
import {
  getProducts,
  getFilterOptionForProduct,
} from "../../services/catalogue.service";
// import { getBranchOption } from "../../services/branches.service";

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}
const CatalogueFilter = ({ userData, ...rest }) => {
  let history = useHistory();

  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [total, SetTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [companyId, setCompanyId] = useState(-1);
  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState([
    { Gender: [], Sucursal: [], Type: [], Area: [] },
  ]);
  // const [branch, setBranch] = useState([]);
  const [companyGdi, setCompanyGdi] = useState("");
  const [filterOptions, setFilterOptions] = useState({});

  const [userRole, setUserRole] = useState("basic");

  useEffect(() => {
    if (history.location.state && history.location.state.searchVal) {
      setSearch(history.location.state.searchVal);
      let state = { ...history.location.state };
      delete state.searchVal;

      history.replace({ ...history.location, state });
      // history.replace({ ...history.location, state: undefined });
    }
  }, [history]);

  const getAllProducts = async (page, search, filter) => {
    if (Object.keys(userData).length && userData?.company?.IdCompany) {
      const res = await getProducts({
        page: page,
        search: search,
        company_id: userData.company.company_gdi,
        filter: JSON.stringify(filter),
      });

      if (
        res &&
        Object.keys(res).length > 0 &&
        res?.products &&
        res?.total &&
        page >= 1
      ) {
        setProducts((products) => [...products, ...res.products]);
        SetTotal(res.total);
      } else if (
        res &&
        Object.keys(res).length > 0 &&
        res?.products &&
        res?.total === 0 &&
        page === 1
      ) {
        setProducts((products) => [...products, ...res.products]);
        SetTotal(res.total);
      }
    } else {
      setProducts([]);
      SetTotal(0);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceLoadData = useCallback(debounce(getAllProducts, 1000), []);

  useEffect(() => {
    if (Object.keys(userData).length && userData?.company?.IdCompany) {
      setCompanyId(userData.company.IdCompany);
      setCompanyGdi(userData.company.company_gdi);
    }

    if (page === 1) {
      setProducts([]);
    }

    debounceLoadData(page, search, filter);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search, companyId, filter]);

  useEffect(() => {
    // if (companyId !== -1) {
    //   getBranchOptions(companyId);
    // }

    if (companyGdi !== "") {
      getFilters(companyGdi);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    if (userData?.permission?.type === "admin") setUserRole("admin");
  }, [userData, history.location]);

  // const getBranchOptions = async (id) => {
  //   const res = await getBranchOption({ company: parseInt(id) });
  //   if (res && res.length > 0) {
  //     setBranch(res);
  //   } else {
  //     setBranch([]);
  //   }
  // };

  const getFilters = async (companyGdi) => {
    const res = await getFilterOptionForProduct({ IdCompanyGDI: companyGdi });

    if (res && Object.keys(res).length > 0) {
      setFilterOptions(res);
    } else {
      setFilterOptions({});
    }
  };

  const loadMore = () => {
    setPage((page) => page + 1);
  };

  const filterChangeHandle = (key, checkData, check) => {
    const tempFilterData = [...filter];
    setPage(1);
    if (check) {
      Object.keys(tempFilterData[0]).forEach((e) => {
        if (e === key) {
          tempFilterData[0][e].push(checkData);
        }
      });
    } else {
      Object.keys(tempFilterData[0]).forEach((e) => {
        if (e === key && tempFilterData[0][e].includes(checkData)) {
          const index = tempFilterData[0][e].indexOf(checkData);
          if (index > -1) {
            tempFilterData[0][e].splice(index, 1);
          }
        }
      });
    }

    setFilter(tempFilterData);
  };

  return (
    <>
      <section className="section product-section">
        <div className="container">
          <div className="wrapper">
            <div className="product-topbar">
              <div className="breadcrumb">
                <ul className="breadcrumb-list">
                  <li>Catálogo</li>
                  {filter.length > 0 &&
                    Object.keys(filter[0]).map(
                      (e, index) =>
                        filter[0][e].length > 0 && (
                          <li key={index}>
                            {e === "Sucursal"
                              ? "División"
                              : e === "Area"
                              ? "Área / Departamento"
                              : e === "Gender"
                              ? "Genero"
                              : e === "Type"
                              ? "Tipo de prendas"
                              : ""}
                          </li>
                        )
                    )}
                  {/* {filter.length > 0 &&
                    Object.keys(filter[0]).map((e, index) => {
                      if (filter[0][e].length > 0) {
                        return (
                          <li key={index}>
                            {e === "Sucursal"
                              ? "División"
                              : e === "Area"
                              ? "Área / Departamento"
                              : e === "Gender"
                              ? "Genero"
                              : e === "Type"
                              ? "Tipo de prendas"
                              : ""}
                          </li>
                        );
                      }
                    })} */}
                </ul>
              </div>
              <div className="product-searchbar">
                <div className="link">
                  <Link
                    to={userRole === "admin" ? "/admin/contact" : "/contact"}
                  >
                    <div className="btn contact-btn">Contactar a EC’s</div>
                  </Link>
                </div>
                <div
                  className="categories_button"
                  onClick={() => setShow(!show)}
                >
                  <a href="##" className="category">
                    Categorías
                  </a>
                </div>
                <div className="search-form">
                  <form action="#" method="post">
                    <div className="form-group">
                      <div className="search-icon">
                        <i className="fa fa-search"></i>
                      </div>
                      <input
                        type="text"
                        name="search"
                        placeholder="Buscar"
                        onChange={(e) => {
                          if (rest?.loading === false) {
                            setPage(1);
                            setSearch(e.target.value);
                          }
                        }}
                        value={search}
                      />
                    </div>
                  </form>
                </div>
                <div
                  className="cart"
                  onClick={(e) => {
                    userRole === "admin"
                      ? history.push({
                          pathname: "/admin/cart/products",
                        })
                      : history.push({ pathname: "/cart/products" });
                  }}
                >
                  <img src="/assets/images/cart.png" alt="" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col product-list-main">
                {/* <div className="categories-wrap" > */}
                <div className={`${"categories-wrap"} ${show ? "show" : ""}`}>
                  <div className="close-btn">
                    <i
                      className="fa fa-close"
                      onClick={() => setShow(!show)}
                    ></i>
                  </div>
                  <div className="main-title">
                    <h4>Categorías</h4>
                  </div>
                  <div className="categories-list">
                    <div className="categories">
                      <h5>Genero</h5>

                      {filterOptions &&
                      Object.keys(filterOptions).length > 0 &&
                      filterOptions.hasOwnProperty("Gender")
                        ? filterOptions["Gender"].map((e, index) => {
                            return (
                              <div className="form-group" key={index}>
                                <input
                                  type="checkbox"
                                  name="category"
                                  id={e.Gender}
                                  onChange={(evt) => {
                                    filterChangeHandle(
                                      "Gender",
                                      e.Gender,
                                      evt.target.checked
                                    );
                                    setShow(!show);
                                  }}
                                />
                                <label htmlFor={e.Gender}>{e.Gender}</label>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                    <div className="categories">
                      <h5>Tipo de prendas</h5>
                      {filterOptions &&
                      Object.keys(filterOptions).length > 0 &&
                      filterOptions.hasOwnProperty("Type")
                        ? filterOptions["Type"].map((e, index) => {
                            return (
                              <div className="form-group" key={index}>
                                <input
                                  type="checkbox"
                                  name="category"
                                  id={e.Type}
                                  onChange={(evt) => {
                                    filterChangeHandle(
                                      "Type",
                                      e.Type,
                                      evt.target.checked
                                    );
                                    setShow(!show);
                                  }}
                                />
                                <label htmlFor={e.Type}>{e.Type}</label>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                    <div className="categories">
                      <h5>División</h5>
                      {filterOptions &&
                      Object.keys(filterOptions).length > 0 &&
                      filterOptions.hasOwnProperty("Branch")
                        ? filterOptions["Branch"].map((e, index) => {
                            return (
                              <div className="form-group" key={index}>
                                <input
                                  type="checkbox"
                                  name="category"
                                  id={index}
                                  onChange={(evt) => {
                                    filterChangeHandle(
                                      "Sucursal",
                                      e.Sucursal,
                                      evt.target.checked
                                    );
                                    setShow(!show);
                                  }}
                                />
                                <label htmlFor={index}>{e.Sucursal}</label>
                              </div>
                            );
                          })
                        : ""}

                      {/* {branch && branch.length > 0
                        ? branch.map((e, index) => {
                            return (
                              <div className="form-group" key={index}>
                                <input
                                  type="checkbox"
                                  name="category"
                                  id={e.IdSucursal}
                                  onChange={(evt) => {
                                    filterChangeHandle(
                                      "Sucursal",
                                      e.Name,
                                      evt.target.checked
                                    );
                                    setShow(!show);
                                  }}
                                />
                                <label htmlFor={e.IdSucursal}>{e.Name}</label>
                              </div>
                            );
                          })
                        : ""} */}
                    </div>
                    <div className="categories">
                      <h5>Área / Departamento</h5>
                      {filterOptions &&
                      Object.keys(filterOptions).length > 0 &&
                      filterOptions.hasOwnProperty("Area")
                        ? filterOptions["Area"].map((e, index) => {
                            return (
                              <div className="form-group" key={index}>
                                <input
                                  type="checkbox"
                                  name="category"
                                  id={e.Area}
                                  onChange={(evt) => {
                                    filterChangeHandle(
                                      "Area",
                                      e.Area,
                                      evt.target.checked
                                    );
                                    setShow(!show);
                                  }}
                                />
                                <label htmlFor={e.Area}>{e.Area}</label>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
              <Catalogue
                loadMore={loadMore}
                products={products}
                total={total}
                page={page}
                search={search}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

// export default CatalogueFilter;
const mapStateToProps = ({ general: { loading } }) => ({
  loading,
});

export default connect(mapStateToProps, null)(CatalogueFilter);
