const initialState = {
  items: [],
};
export const cart = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      return {
        ...state,
        items: action.payload.cartItems,
      };
    case "REMOVE_FROM_CART":
      return { ...state, items: action.payload.cartItems };
    case "MULTIPLE_REMOVE_FROM_CART":
      return { ...state, items: action.payload.cartItems };
    case "EDIT_FROM_CART":
      return { ...state, items: action.payload.cartItems };
    case "EMPTY_CART":
      return { ...state, items: action.payload.cartItems };
    default:
      return state;
  }
};
