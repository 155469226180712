import { axiosPost } from "../axios/axios.config";
import { setLoading } from "../redux/actions/general.actions";
// import { emptyCart } from "../redux/actions/cart.actions";
import { store } from "../redux/store";


export const getOrders = async (data) => {
  store.dispatch(setLoading(true));
  const res = await axiosPost("order/list", data);
  store.dispatch(setLoading(false));
  if (res["data"]) {
    return res["data"];
  }
  return [];
};

export const getOrder = async (data) => {
  store.dispatch(setLoading(true));
  const res = await axiosPost(`order/get`, { id: data });
  store.dispatch(setLoading(false));
  if (res["data"]) {
    return res["data"];
  }
  return [];
};

export const saveOrder = async (data) => {
  // store.dispatch(emptyCart([]));
  store.dispatch(setLoading(true));
  const res = await axiosPost("order/save", data);
  store.dispatch(setLoading(false));
  if (res["data"]) {
    return res["data"];
  }
  return [];
};

export const crearpago = async(data) => {
  
  const res  = await axiosPost("order/crearpago", data);
 
 
  if (res.data) {
    return res.data;
  }
  return [];

}

export const saveStatusMP = async(data) => {
  
  const res  = await axiosPost("order/savempago", data);

  if (res.data) {
    return res.data;
  }
  return [];

}

export const getOrderPdf = async (data) => {
  const res = await axiosPost("order/pdf", data);
  if (res["data"]) {
    return res["data"];
  }
  return [];
};

export const getOrderExcel = async (data) => {
  const res = await axiosPost("order/excel", data);
  if (res["data"]) {
    return res["data"];
  }
  return [];
};
export const FileDelete = async (data) => {
  store.dispatch(setLoading(true));
  const res = await axiosPost("order/delete-file", data);
  store.dispatch(setLoading(false));
  if (res["data"]) {
    return res["data"];
  }
  return [];
};
export const changeStatus = async (data) => {
  store.dispatch(setLoading(true));
  const res = await axiosPost("order/status-change", data);
  store.dispatch(setLoading(false));
  if (res["data"]) {
    return res["data"];
  }
  return [];
};

export const upLoadPago = async (data) => {
  const res = await axiosPost('order/uploadpago', data,{
    headers: {
      "Content-Type": "multipart/form-data",
    } }) ;
  if (res['data']) {
    return res['data'];
  }
  return [];
};


export const saveSeguimiento = async (data) => {
  const res = await axiosPost('order/saveseguimiento', data,{
    headers: {
      "Content-Type": "multipart/form-data",
    } }) ;
  if (res['data']) {
    return res['data'];
  }
  return [];
};