import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modal } from "../../../components/modal/modal";
import { saveAdmin, getAdmin } from "../../../services/admin.service";

const AdminForm = (props) => {
  // const phoneRegExp =
  //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const initials = {
    Name: "",
    last_name: "",
    Email: "",
    Password: "",
  };

  const [initialValues, setInitialValues] = useState(initials);
  const [submitted, setSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState(-1);

  useEffect(() => {
    if (props?.match?.params?.id) {
      setId(props.match.params.id);
      getParticularAdmin(props.match.params.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.match?.params]);

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Este campo es obligatorio"),
    last_name: Yup.string().required("Este campo es obligatorio"),
  });

  const passwordSchema = Yup.object().shape({
    Email: Yup.string()
      .email("Email inválido")
      .required("Este campo es obligatorio"),
    Password: Yup.string().required("Este campo es obligatorio"),
  });

  const mergeSchema = validationSchema.concat(passwordSchema);

  const onSubmit = async (values) => {
    setSubmitted(true);

    if (id !== -1) {
      let data;
      delete values["Password"];
      data = { ...values, IdUser: id };
      const res = await saveAdmin(data);
      setSubmitted(false);
      if (res && res.status === "success") {
        setShowModal(true);
      }
    } else {
      const res = await saveAdmin(values);
      setSubmitted(false);
      if (res && res.status === "success") {
        setShowModal(true);
      }
    }
  };

  const getParticularAdmin = async (id) => {
    const res = await getAdmin({ id: parseInt(id) });
    if (res && Object.keys(res).length > 0) {
      setInitialValues({ ...initialValues, ...res });
    }
  };

  const onCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <section className="section inner-banner-sec gdi-solution-banner">
        <div className="container">
          <div className="wrapper">
            <div className="inner-banner-wrap">
              <div
                className="inner-banner"
                style={{
                  backgroundImage: `url(/assets/images/inner-banner.png)`,
                }}
              >
                <div className="inner-banner-title">
                  <h4>¡Bienvenido a tu plataforma GDI!</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section admin-add-new-user admin-footer-margin">
        <div className="container">
          <div className="wrapper">
            <div className="admin-company-wrap">
              <div className="add-new-company"> información geneal</div>
              <div className="return-link">
                <Link to={"/"}>
                  <i className="fa fa-arrow-circle-o-left"></i>Regresar
                </Link>
              </div>
            </div>
            <div className="add-user-form">
              <div className="icon">
                <img src="/assets/images/user-icon.png" alt="" />
              </div>
              <div className="add-user-form-wrap">
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={id !== -1 ? validationSchema : mergeSchema}
                >
                  {({ errors, touched, values, setFieldValue }) => (
                    <Form>
                      <div className="form-group">
                        <label>Nombre</label>
                        <Field
                          name="Name"
                          type="text"
                          className={
                            "form-control" +
                            (errors.Name && touched.Name ? " is-invalid" : "")
                          }
                          placeholder="Nombre"
                        />
                        <ErrorMessage
                          name="Name"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="form-group">
                        <label>Apellido</label>
                        <Field
                          name="last_name"
                          type="text"
                          className={
                            "form-control" +
                            (errors.last_name && touched.last_name
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Apellido"
                        />
                        <ErrorMessage
                          name="last_name"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="form-group">
                        <label>Correo electrónico</label>
                        <Field
                          name="Email"
                          type="text"
                          className={
                            "form-control" +
                            (errors.Email && touched.Email ? " is-invalid" : "")
                          }
                          placeholder="Mail"
                          disabled={id !== -1 ? true : false}
                        />
                        <ErrorMessage
                          name="Email"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="form-group">
                        <label>Contraseña</label>
                        <Field
                          name="Password"
                          type="Password"
                          className={
                            "form-control" +
                            (errors.Password && touched.Password
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="674HUDNO890"
                          disabled={id !== -1 ? true : false}
                        />
                        <ErrorMessage
                          name="Password"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="submit-btn">
                        <button
                          type="submit"
                          name="gdi-solution-btn"
                          disabled={submitted}
                        >
                          {submitted && (
                            <i className="fa fa-spinner fa-spin button-loader"></i>
                          )}
                          Agregar
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>

                <Modal
                  visible={showModal}
                  id="photo-modal"
                  onClose={onCloseModal}
                >
                  <div className="update-popup show">
                    <div className="update-popup-wrap">
                      <div className="icon">
                        <img src="/assets/images/comments.png" alt="" />
                      </div>
                      <div className="content">
                        Este usuario ha sido agregado con éxito al
                        administrador, a partir de ahora podrá editar todas las
                        empresas existentes
                      </div>
                      <div className="popup-buttons">
                        <div className="back-to-top">
                          <Link to={"/"}>Volver al inicio</Link>
                        </div>
                        <div className="back-to-user">
                          <Link to={"/admin/admins"}>Regresar a usuarios</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminForm;
