import React, { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Bar } from "react-chartjs-2";
import { constants } from "../../app-config";
import TableComponent from "../../components/table/table";
import {
  getConsumoReportAndGraph,
  getConsumoReportExcel,
} from "../../services/report.service";

const ConsumptionReport = ({ userData }) => {
  const headerData = [
    {
      header: "Mes",
      name: "MesMonth",
    },
    {
      header: "Total Gral",
      name: "Total",
    },
    {
      header: "Bata",
      name: "Bata",
    },
    {
      header: "Camisa",
      name: "Camisa",
    },
    {
      header: "Camisola",
      name: "Camisloa",
    },
    {
      header: "Chamarra",
      name: "Chamarra",
    },
    {
      header: "Gorra",
      name: "Gorra",
    },
    {
      header: "Multiestilo",
      name: "Multiestilo",
    },
    {
      header: "Pantalón gabardina",
      name: "Pantalon_Gabardina",
    },
    {
      header: "Pantalon Cargo",
      name: "Pantalon_Cargo",
    },
    {
      header: "Playera",
      name: "Playera",
    },
    {
      header: "Playera Básica",
      name: "Playera_Basica",
    },
    {
      header: "Cubreboca",
      name: "Cubreboca",
    },
  ];
  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    Bata: [],
    Camisa: [],
    Camisloa: [],
    Chamarra: [],
    Gorra: [],
    Multiestilo: [],
    Pantalon_Gabardina: [],
    Playera: [],
    Pantalon_Cargo: [],
    Playera_Comprada: [],
    Cubreboca: [],
    Total: [],
  };

  const [dataCount, setDataCount] = useState(0);
  const [consumptionReportData, setConsumptionReportData] = useState([]);
  const [consumptionReportGraph, setConsumptionReportGraph] = useState(data);
  const [downloadloadingToggle, setDownloadLoadingToggle] = useState(false);

  const getConsumptionReportData = async (page) => {
    const res = await getConsumoReportAndGraph({
      IdCompanyGDI: userData?.company?.company_gdi,
      // IdCompanyGDI: "PT OXXO",
    });

    if (res && Object.keys(res).length > 0) {
      setConsumptionReportData(res.consumo_report);
      setDataCount(res.total);

      let obj = {};
      let BataValue = Array.from(Array(12)).fill(0);
      let CamisaValue = Array.from(Array(12).fill(0));
      let CamisloaValue = Array.from(Array(12)).fill(0);
      let ChamarraValue = Array.from(Array(12)).fill(0);
      let GorraValue = Array.from(Array(12)).fill(0);
      let MultiestiloValue = Array.from(Array(12)).fill(0);
      let PantalonCargoValue = Array.from(Array(12)).fill(0);
      let PantalonGabardinaValue = Array.from(Array(12)).fill(0);
      let PlayeraValue = Array.from(Array(12)).fill(0);
      let PlayeraCompradaValue = Array.from(Array(12)).fill(0);
      let CubrebocaValue = Array.from(Array(12)).fill(0);
      let TotalValue = Array.from(Array(12)).fill(0);

      res.consumo_report.forEach((e) => {
        if (e.hasOwnProperty("Bata")) {
          BataValue[parseInt(e.Mes) - 1] = e.Bata;
          obj["Bata"] = BataValue;
        }
        if (e.hasOwnProperty("Camisa")) {
          CamisaValue[parseInt(e.Mes) - 1] = e.Camisa;
          obj["Camisa"] = CamisaValue;
        }
        if (e.hasOwnProperty("Camisloa")) {
          CamisloaValue[parseInt(e.Mes) - 1] = e.Camisloa;
          obj["Camisloa"] = CamisloaValue;
        }
        if (e.hasOwnProperty("Chamarra")) {
          ChamarraValue[parseInt(e.Mes) - 1] = e.Chamarra;
          obj["Chamarra"] = ChamarraValue;
        }
        if (e.hasOwnProperty("Gorra")) {
          GorraValue[parseInt(e.Mes) - 1] = e.Gorra;
          obj["Gorra"] = GorraValue;
        }
        if (e.hasOwnProperty("Multiestilo")) {
          MultiestiloValue[parseInt(e.Mes) - 1] = e.Multiestilo;
          obj["Multiestilo"] = MultiestiloValue;
        }
        if (e.hasOwnProperty("Pantalon_Cargo")) {
          PantalonCargoValue[parseInt(e.Mes) - 1] = e.Pantalon_Cargo;
          obj["Pantalon_Cargo"] = PantalonCargoValue;
        }
        if (e.hasOwnProperty("Pantalon_Gabardina")) {
          PantalonGabardinaValue[parseInt(e.Mes) - 1] = e.Pantalon_Gabardina;
          obj["Pantalon_Gabardina"] = PantalonGabardinaValue;
        }
        if (e.hasOwnProperty("Playera")) {
          PlayeraValue[parseInt(e.Mes) - 1] = e.Playera;
          obj["Playera"] = PlayeraValue;
        }
        if (e.hasOwnProperty("Playera_Comprada")) {
          PlayeraCompradaValue[parseInt(e.Mes) - 1] = e.Playera_Comprada;
          obj["Playera_Comprada"] = PlayeraCompradaValue;
        }
        if (e.hasOwnProperty("Cubreboca")) {
          CubrebocaValue[parseInt(e.Mes - 1)] = e.Cubreboca;
          obj["Cubreboca"] = CubrebocaValue;
        }
        if (e.hasOwnProperty("Total")) {
          TotalValue[parseInt(e.Mes) - 1] = e.Total;
          obj["Total"] = TotalValue;
        }
      });
      setConsumptionReportGraph({ ...consumptionReportGraph, ...obj });
    }
  };
  const downloadExcel = async () => {
    setDownloadLoadingToggle(true);
    const res = await getConsumoReportExcel({
      IdCompanyGDI: userData?.company?.company_gdi,
      // IdCompanyGDI: "PT OXXO",
    });
    setDownloadLoadingToggle(false);

    if (Object.keys(res).length > 0) {
      window.open(constants.BACKEND_URL + res.file_path, "_blank");
    }
  };

  return (
    <>
      <section
        className="section report-topbar"
        style={{
          backgroundColor:
            userData && userData?.company?.color ? userData.company.color : "",
        }}
      >
        <div className="container">
          <div className="wrapper">
            <div className="title-wrapper">
              <div className="title">
                <h4>Reporte de consumo</h4>
              </div>
              <div className="image">
                {userData && userData?.company?.logo ? (
                  <img
                    src={constants.BACKEND_URL + userData.company.logo}
                    alt=""
                  />
                ) : (
                  <img src="/assets/images/gray-logo.png" alt="" />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section global-consumption-sec">
        <div className="container">
          <div className="wrapper">
            <div className="global-consumption-wrap">
              <div className="title">
                <h4>Consumo Global Año Vigente</h4>
              </div>
              <div className="last-update-date">
                Última fecha de actualización:
                <strong> {moment().format("DD-MM-YYYY")}</strong>
              </div>
            </div>
            {console.log(consumptionReportGraph, "consumptionReportGraph")}
            <div className="global-consumption-graph">
              <div className="image">
                {/* <div className="image"> */}
                <Bar
                  // className="canvas-container"
                  data={{
                    labels: consumptionReportGraph.labels,
                    responsive: true,
                    datasets: [
                      {
                        label: "Total general",
                        backgroundColor: "#ff5883",
                        data: consumptionReportGraph.Total,
                      },
                      {
                        label: "Bata",
                        backgroundColor: "#c9cbcf",
                        data: consumptionReportGraph.Bata,
                        stack: "1",
                      },
                      {
                        label: "Camisa",
                        backgroundColor: "#d572Cf",
                        data: consumptionReportGraph.Camisa,
                        stack: "1",
                      },
                      {
                        label: "Camisola",
                        backgroundColor: "#ffcb68",
                        data: consumptionReportGraph.Camisloa,
                        stack: "1",
                      },
                      {
                        label: "Multiestilo",
                        backgroundColor: "#00c2c0",
                        data: consumptionReportGraph.Multiestilo,
                        stack: "1",
                      },
                      {
                        label: "Pantalon gabardina",
                        backgroundColor: "#00a5e6",
                        data: consumptionReportGraph.Pantalon_Gabardina,
                        stack: "1",
                      },
                      {
                        label: "Playera Básica",
                        backgroundColor: "#9e67f6",
                        data: consumptionReportGraph.Playera_Comprada,
                        stack: "1",
                      },
                      {
                        label: "Playera",
                        backgroundColor: "#ff9a50",
                        data: consumptionReportGraph.Playera,
                        stack: "1",
                      },
                      {
                        label: "Chamarra",
                        backgroundColor: "#000",
                        data: consumptionReportGraph.Chamarra,
                        stack: "1",
                      },
                      {
                        label: "Gorra",
                        backgroundColor: "#123808",
                        data: consumptionReportGraph.Gorra,
                        stack: "1",
                      },
                      {
                        label: "Pantalon Cargo",
                        backgroundColor: "#9e6rtf",
                        data: consumptionReportGraph.Pantalon_Cargo,
                        stack: "1",
                      },
                      {
                        label: "Cubreboca",
                        backgroundColor: "#800080",
                        data: consumptionReportData.Cubreboca,
                        stack: "1",
                      },
                    ],
                  }}
                  height={150}
                  options={{
                    scales: {
                      xAxes: {
                        ticks: {
                          autoSkip: false,
                          maxRotation: 30,
                          minRotation: 30,
                        },
                      },
                    },
                    // responsive: true,
                    // maintainAspectRatio: false,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section global-consumption-table-sec">
        <div className="container">
          <div className="wrapper">
            <div className="global-consumption-table">
              <div
                className={`global-consumption-tablewrap ${
                  consumptionReportData && consumptionReportData.length === 0
                    ? "nodata-table-height"
                    : ""
                }`}
              >
                <div className="global-consumption-table-inner">
                  <TableComponent
                    title=""
                    headerData={headerData}
                    bodyData={consumptionReportData}
                    onChange={getConsumptionReportData}
                    dataCount={dataCount}
                    search={false}
                    divClassName={""}
                    paginationShow={false}
                    noData={
                      <div
                        style={{ textAlign: "center" }}
                        className="no-data-text"
                      >
                        Datos no encontrados
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
            {consumptionReportData && consumptionReportData.length > 0 && (
              <div className="download-excelwrap">
                <div
                  className={`download-excel ${
                    downloadloadingToggle ? "disabled" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => downloadExcel()}
                >
                  {downloadloadingToggle && (
                    <i className="fa fa-spinner fa-spin button-loader"></i>
                  )}{" "}
                  Descargar en XLSX
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

const mapToStateProps = ({ auth: { user_details } }) => ({
  userData: user_details,
});
export default connect(mapToStateProps, null)(ConsumptionReport);
