import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { auth } from "./reducers/auth.reducers";
import { general } from "./reducers/general.reducers";
import { cart } from "./reducers/cart.reducers";
import { company } from "./reducers/company.reducers";
import { token } from "./reducers/token.reducers";
import { cartComment } from "./reducers/cart-comment.reducers";

const persistConfig = {
  key: "gdi",
  storage,
};

const appReducer = combineReducers({
  auth,
  general,
  cart,
  company,
  token,
  cartComment,
});

// export default appReducer;
export default persistReducer(persistConfig, appReducer);
