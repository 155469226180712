import React, { useEffect } from "react";
import "./modal.css";

export const Modal = ({
  title,
  visible,
  children,
  id,
  wrapperClass,
  onClose,
}) => {
  useEffect(() => {
    // Get the modal
    var modal = document.getElementById(id);

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
      if (event.target === modal) {
        modal.style.display = "none";
        onClose();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (visible) {
      document.getElementById(id).style.display = "block";
    } else {
      document.getElementById(id).style.display = "none";
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <div id={id} className={`modal ${wrapperClass}`}>
      <div className="modal-content">
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};
