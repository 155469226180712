import { axiosPost } from '../axios/axios.config';
import {
  setFormProcessing,
  setLoading,
} from '../redux/actions/general.actions';
import { store } from '../redux/store';

export const uploadCompanyImages = async (data) => {
  const res = await axiosPost('company/upload-images', data);
  if (res['data']) {
    return res['data'];
  }
  return [];
};

export const getCompanies = async (data) => {
  store.dispatch(setLoading(true));
  const res = await axiosPost('company/list', data);
  store.dispatch(setLoading(false));
  if (res['data']) {
    return res['data'];
  }
  return [];
};

export const saveCompany = async (data) => {
  store.dispatch(setFormProcessing(true));
  const res = await axiosPost('company/save', data);
  store.dispatch(setFormProcessing(false));
  if (res[['data']]) {
    return res['data'];
  }
  return false;
};

export const getCompanyDetails = async (data) => {
  // store.dispatch(setFormProcessing(true));
  store.dispatch(setLoading(true));
  const res = await axiosPost('company/get', data);
  // store.dispatch(setFormProcessing(false));
  store.dispatch(setLoading(false));
  if (res[['data']]) {
    return res['data'];
  }
  return false;
};
