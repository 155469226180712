import React from 'react';

export const Footer = () => (
  <footer className='footer admin-footer'>
    <div className='container'>
      <div className='wrapper'>
        <div className='row'>
          <div className='col footer-logo'>
            <div className='image'>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/gray-logo.png`}
                alt='brand-logo'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);
