import { constants } from "../app-config";

export const getImagePath = (path) => {
  if (typeof path === "string" && path.includes("storage")) {
    return constants.BACKEND_URL + "" + path;
  } else {
    return path;
  }
};

export const getRandomString = (len = 10) => {
  let result = "";
  const characters = "0123456789";
  const charactersLength = characters.length;
  for (var i = 0; i < len; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
