import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { logoutUser } from "../../redux/actions/auth.actions";
import { emptyCart } from "../../redux/actions/cart.actions";

const UserHeader = ({ logoutUser, emptyCart, ...rest }) => {
  const [toggleHeader, setToggleHeader] = useState(false);
  const [toggleSubMenu, setToggleSubMenu] = useState(false);
  const [show, setShow] = useState(false);
  const getClassIfActive = (matchRoute, optionalRoute = "") => {
    if (optionalRoute) {
      return rest.location.pathname.includes(matchRoute) ||
        rest.location.pathname.includes(optionalRoute)
        ? "current"
        : "";
    } else {
      return rest.location.pathname.includes(matchRoute) ? "current" : "";
    }
  };

  return (
    <header id="header">
      <div className="header-main">
        <div className="header-section">
          <div className="logo">
            <div className="left-menu">
              <Link to={"/admin/catalogue"}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                  alt="Logo"
                />
              </Link>
            </div>
          </div>

          <div className="menu-section">
            <div className="nav-menu">
              <div
                className={`menulinks ${show ? "close" : ""}`}
                onClick={() => setShow(!show)}
              >
                <i></i>
              </div>
              <ul className={`mainmenu ${show ? "show" : ""}`}>
                <li>
                  <Link
                    to="/admin/catalogue"
                    className={getClassIfActive("/admin/catalogue")}
                    onClick={() => setShow(!show)}
                  >
                    Catálogo
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/admin/order`}
                    className={getClassIfActive("/admin/order")}
                    onClick={() => setShow(!show)}
                  >
                    Pre-ordenes
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/admin/brand-orders`}
                    className={getClassIfActive("/admin/brand-orders")}
                    onClick={() => setShow(!show)}
                  >
                    Ordenes
                  </Link>
                </li>
                <li
                  className="has-sub-menu"
                  onClick={() => {
                    setToggleSubMenu(!toggleSubMenu);
                  }}
                >
                  <a href="##">Reportes</a>
                  <ul
                    className={`sub-menu ${getClassIfActive("/admin/report")} ${
                      toggleSubMenu ? "show" : ""
                    }`}
                  >
                    <li>
                      <Link
                        to={`/admin/report/consumption-report`}
                        onClick={() => setShow(!show)}
                      >
                        Reporte de consumo
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/admin/report/invoice-report`}
                        onClick={() => setShow(!show)}
                      >
                        Reporte de facturación
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/admin/report/follow-up-report`}
                        onClick={() => setShow(!show)}
                      >
                        Reporte de seguimiento
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    to={`/admin/gdi-solutions`}
                    className={getClassIfActive("/admin/gdi-solutions")}
                    onClick={() => setShow(!show)}
                  >
                    Soluciones GDI
                  </Link>
                </li>
              </ul>
              <div className="right-menu">
                <ul>
                  <li>
                    <Link
                      to={`/admin/contact`}
                      className={getClassIfActive("/admin/contact")}
                      onClick={() => setShow(!show)}
                    >
                      <div className="btn contact-btn text-white">Contacto</div>
                    </Link>
                  </li>
                  <li
                    className="my-account toggle-btn"
                    onClick={() => {
                      setToggleHeader(!toggleHeader);
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/user-circle-icon.png`}
                      alt="Logo"
                    />
                    <i className="fa fa-chevron-down"></i>
                    <ul
                      className={`my-account-toggle toggle-content ${
                        toggleHeader ? "show" : ""
                      }`}
                    >
                      <li
                        onClick={(e) => {
                          emptyCart([]);
                          logoutUser();
                        }}
                      >
                        <i className="fa fa-times-circle"></i>Cerrar sesión
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser()),
  emptyCart: (cartItems) => dispatch(emptyCart(cartItems)),
});

export const Header = connect(null, mapDispatchToProps)(withRouter(UserHeader));
