import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import { Modal } from "../../../../../components/modal/modal";
import {
  getImagePath,
  getRandomString,
} from "../../../../../helpers/general.helper";
import {
  getCompanyDetails,
  saveCompany,
  uploadCompanyImages,
} from "../../../../../services/company.service";
import { BrandInner } from "../../brand-inner";
import { Spinner } from "../../../../../components/spinner/spinner";
import { setCompanyGdi } from "../../../../../redux/actions/company.actions";

// for image
const initials = {
  IdCompany: "",
  Name: "",
  IdCompanyGDI: getRandomString(),
  images: [
    // 'storage/company-temp/BKwszxK4.png',
    // 'storage/company-temp/BMQ00uIR.png',
  ],
  Logo: "",
  Color: "#e21c2a",
  BannerCatalogo: [""],
  BannerOrders: "",
};

const GeneralForm = ({ history, match, loading, setCompanyGdi }) => {
  // const [images, setImages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [values, setValues] = useState(JSON.parse(JSON.stringify(initials)));
  const [modalImageType, setModalImageType] = useState("");
  const [selectedImage, setSelectedImage] = useState(""); // only for modal
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (match.params.id) {
      getDetails();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDetails = async () => {
    const res = await getCompanyDetails({ id: match.params.id });
    if (res) {
      const tempValues = { ...values };
      Object.keys(values).forEach((key) => {
        if (tempValues[key] !== undefined) {
          tempValues[key] = res[key];
        }
      });
      if (res["company_images"] !== undefined) {
        tempValues["images"] = res["company_images"].map((item) => item.path);
      }
      setValues(tempValues);
    }
  };

  const onDropImages = async (files) => {
    const fData = new FormData();
    files.forEach((file) => {
      fData.append("files[]", file);
    });
    if (match.params.id) {
      fData.append("IdCompany", match.params.id);
    }
    setUploading(true);
    const uploaded = await uploadCompanyImages(fData);
    setUploading(false);
    if (uploaded) {
      setValues({ ...values, images: [...values.images, ...uploaded] });
    }
  };

  const onOpenImageModal = (type, fieldValue = "") => {
    setModalImageType(type);
    setSelectedImage(fieldValue ? fieldValue : values[type]);
    setShowModal(true);
  };

  const onSelectModalImage = ({ target: { checked } }, image) => {
    if (checked) {
      setSelectedImage(image);
    } else {
      setSelectedImage("");
    }
  };

  const onCloseModal = () => {
    setShowModal(false);
    setSelectedImage([]);
  };

  const onRemoveBannerImage = (index) => {
    const tempValues = { ...values };
    tempValues["BannerCatalogo"].splice(index, 1);
    setValues(tempValues);
  };

  const onConfirmModalImage = () => {
    const keys = modalImageType.split(".");
    const tempValues = { ...values };
    if (keys.length > 1) {
      tempValues[keys[0]][keys[1]] = selectedImage;
    } else {
      tempValues[modalImageType] = selectedImage;
    }
    setValues(tempValues);
    setShowModal(false);
    setSelectedImage([]);
  };

  const hasErrors = () => {
    return (
      !values.Name.trim() ||
      !values.IdCompanyGDI ||
      !values.Logo ||
      !values.BannerOrders ||
      !(values.BannerCatalogo.length > 0 && values.BannerCatalogo[0])
    );
  };

  const onSubmit = async () => {
    setSubmitted(true);

    if (!hasErrors()) {
      const BannerCatalogoTrimmed = values.BannerCatalogo.filter(
        (element) => element !== ""
      );

      let tempValues = { ...values, BannerCatalogo: BannerCatalogoTrimmed };

      if (!tempValues.IdCompany) {
        delete tempValues["IdCompany"];
      }
      const res = await saveCompany(tempValues);
      if (res && res.IdCompany) {
        setValues({
          ...tempValues,
          IdCompany: res.IdCompany,
          IdCompanyGDI: res.IdCompanyGDI,
        });
        let IdCompanyGDI = res.IdCompanyGDI;
        let Logo = res.Logo;
        let created_at = new Date();
        setCompanyGdi({ IdCompanyGDI, Logo, created_at });

        setTimeout(() => {
          history.push(`/brands/${res.IdCompany}/users`);
        }, 200);
      }
    }
  };

  return (
    <BrandInner
      onSubmit={onSubmit}
      companyId={values.IdCompanyGDI}
      routeCompanyId={values.IdCompany}
      brandLogo={values.Logo}
    >
      <div className="general-wrapper">
        <div className="general-setting-wrap mobile-loder-spacing general-form-input">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className="title">General</div>
              <div className="general-form">
                <div className="wrap">
                  <div className="form-group">
                    <label>Nombre de la empresa</label>
                    <input
                      type="text"
                      name="Name"
                      placeholder="Oxxo"
                      onChange={({ target: { value } }) =>
                        setValues({ ...values, Name: value })
                      }
                      value={values.Name}
                    />
                    {submitted && !values.Name.trim() && (
                      <div className="invalid-feedback">
                        El nombre de la empresa es obligatorio
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <label>ID de la empresa</label>
                    <input
                      type="text"
                      name="IdCompanyGDI"
                      placeholder="786523WMN"
                      onChange={({ target: { value } }) =>
                        setValues({ ...values, IdCompanyGDI: value })
                      }
                      value={values.IdCompanyGDI}
                      disabled={!!match.params.id}
                    />
                    {submitted && !values.IdCompanyGDI && (
                      <div className="invalid-feedback">
                        Se requiere la identificación de la empresa
                      </div>
                    )}
                  </div>
                  <div className="drop-image">
                    <label>Agregar imágenes</label>
                    <Dropzone
                      accept="image/png,image/jpg,image/jpeg"
                      disabled={uploading}
                      onDrop={onDropImages}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <>
                          <div className="drop-box" {...getRootProps()}>
                            <input {...getInputProps()} />
                            {uploading ? (
                              <>
                                {" "}
                                <i className="fa fa-spinner fa-spin button-loader"></i>
                                uploading ...
                              </>
                            ) : (
                              "Drag and drop files or click to select"
                            )}
                          </div>
                        </>
                      )}
                    </Dropzone>
                  </div>
                </div>
                <div className="general-complete-image">
                  <div className="title">COMPLETE</div>
                  <div className="general-complete-grid">
                    {values.images.map((image) => (
                      <div className="general-complete-item" key={image}>
                        <div
                          className="image"
                          style={{
                            //   backgroundImage: 'url(/assets/images/complete.png)',
                            backgroundImage: `url(${getImagePath(image)})`,
                          }}
                        ></div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="company-logo-wrap">
                  <div className="company-logo-inner">
                    <div className="company-title">Logo de la empresa </div>
                    <div className="company-logo">
                      {values.Logo ? (
                        <img src={getImagePath(values.Logo)} alt="logo" />
                      ) : (
                        ""
                      )}

                      <div
                        className="edit-icon cursor-pointer"
                        onClick={() => onOpenImageModal("Logo")}
                      >
                        <img src="/assets/images/edit.png" alt="edit" />
                      </div>
                    </div>
                    {submitted && !values.Logo && (
                      <div className="invalid-feedback">
                        Este campo es obligatorio
                      </div>
                    )}
                  </div>
                  <div className="distinctive">
                    <div className="company-title">Distintivo </div>
                    <input
                      type="color"
                      className="d-none"
                      id="color-picker"
                      onChange={(e) =>
                        setValues({ ...values, Color: e.target.value })
                      }
                    />
                    <label htmlFor="color-picker">
                      <div
                        className="color-box"
                        style={{ backgroundColor: values.Color }}
                      ></div>
                    </label>
                    <div className="color-code">{values.Color}</div>
                  </div>
                </div>
                <div className="catalog-banner">
                  <h4>Banner catálogo</h4>
                  <div className="catalog-banner-wrap">
                    {values.BannerCatalogo.map((image, index) => (
                      <div className="catalog-banner-item" key={index}>
                        <div
                          className="catalog-banner-img"
                          style={{
                            backgroundImage: `url(${getImagePath(image)})`,
                          }}
                        >
                          <div className="catalog-banner-edit">
                            <div
                              className="icon"
                              onClick={() =>
                                onOpenImageModal(
                                  `BannerCatalogo.${index}`,
                                  image
                                )
                              }
                            >
                              <img src="/assets/images/edit.png" alt="edit" />
                            </div>
                            {values.BannerCatalogo.length > 1 && (
                              <div
                                className="close-icon cursor-pointer"
                                onClick={() => {
                                  onRemoveBannerImage(index);
                                }}
                              >
                                <i className="fa fa-times"></i>
                              </div>
                            )}
                          </div>
                        </div>
                        {submitted &&
                          !(
                            values.BannerCatalogo.length > 0 &&
                            values.BannerCatalogo[0]
                          ) && (
                            <div className="invalid-feedback">
                              Este campo es obligatorio
                            </div>
                          )}
                      </div>
                    ))}

                    <div className="add-catalog">
                      <div
                        className="icon"
                        onClick={() =>
                          setValues({
                            ...values,
                            BannerCatalogo: [...values.BannerCatalogo, ""],
                          })
                        }
                      >
                        <i className="fa fa-plus"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="puchase-order-banner">
                  <h4>Banner ordenes de compra</h4>
                  <div className="puchase-banner-wrap">
                    <div className="catalog-banner-item">
                      <div
                        className="catalog-banner-img"
                        style={{
                          backgroundImage: `url(${getImagePath(
                            values.BannerOrders
                          )})`,
                        }}
                      >
                        <div className="catalog-banner-edit">
                          <div
                            className="icon"
                            onClick={() => onOpenImageModal("BannerOrders")}
                          >
                            <img src="/assets/images/edit.png" alt="edit" />
                          </div>
                        </div>
                      </div>

                      {submitted && !values.BannerOrders && (
                        <div className="invalid-feedback">
                          Este campo es obligatorio
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <Modal visible={showModal} id="photo-modal" onClose={onCloseModal}>
        <div className="choose-photo-popup">
          <div className="choose-photo-wrap">
            <div className="title">
              <h4>Selecciona una photo</h4>
            </div>
            <div className="text-center mt-1">
              {values.images.length === 0 && <p>No se encontraron fotos</p>}
            </div>
            <div className="selection-photo-grid">
              {values.images.map((image) => (
                <div className="selection-photo-item" key={image}>
                  <div className="form-group">
                    <input
                      type={"radio"}
                      name="image"
                      id={image}
                      checked={selectedImage === image}
                      onChange={(e) => onSelectModalImage(e, image)}
                    />
                    <label htmlFor={image}></label>
                  </div>
                  <img src={getImagePath(image)} alt="Icon" className="image" />
                </div>
              ))}
            </div>
            <div className="link">
              <a
                href="#!"
                className="btn blue-square-btn mr-1"
                onClick={onCloseModal}
              >
                Cancel
              </a>
              <a
                href="#!"
                className="btn blue-square-btn"
                onClick={onConfirmModalImage}
              >
                Actualizar
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </BrandInner>
  );
};

const mapStateToProps = ({ general: { loading } }) => ({
  loading: loading,
});
const mapDispatchToProps = (dispatch) => ({
  setCompanyGdi: (data) => dispatch(setCompanyGdi(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralForm);
