import { axiosPost } from "../axios/axios.config";
import { setLoading } from "../redux/actions/general.actions";
import { store } from "../redux/store";

export const getBaseReport = async (data) => {
  store.dispatch(setLoading(true));
  const res = await axiosPost("report/baseReport", data);
  store.dispatch(setLoading(false));
  if (res["data"]) {
    return res["data"];
  }
  return [];
};

export const getBaseReportExcel = async (data) => {
  const res = await axiosPost("report/base-report-excel", data);
  if (res["data"]) {
    return res["data"];
  }
  return [];
};

export const getInvoiceReport = async (data) => {
  store.dispatch(setLoading(true));
  const res = await axiosPost("report/invoiceReport", data);
  store.dispatch(setLoading(false));
  if (res["data"]) {
    return res["data"];
  }
  return [];
};

export const getInvoiceReportExcel = async (data) => {
  store.dispatch(setLoading(true));
  const res = await axiosPost("report/invoice-report-excel", data);
  store.dispatch(setLoading(false));
  if (res["data"]) {
    return res["data"];
  }
  return [];
};

export const getConsumoReportAndGraph = async (data) => {
  store.dispatch(setLoading(true));
  const res = await axiosPost("report/consumoReport", data);
  store.dispatch(setLoading(false));
  if (res["data"]) {
    return res["data"];
  }
  return [];
};

export const getConsumoReportExcel = async (data) => {
  const res = await axiosPost("report/consumo-report-excel", data);
  if (res["data"]) {
    return res["data"];
  }
  return [];
};

export const getFacturactionReport = async (data) => {
  // store.dispatch(setLoading(true));
  const res = await axiosPost("report/ReportDeFacturaction-2", data);
  // store.dispatch(setLoading(false));
  if (res["data"]) {
    return res["data"];
  }
  return [];
};

export const getFacturactionReportExcel = async (data) => {
  const res = await axiosPost("report/ReportDeFacturaction-2-excel", data);
  if (res["data"]) {
    return res["data"];
  }
  return [];
};

export const getFacturactionGraph = async (data) => {
  store.dispatch(setLoading(true));
  const res = await axiosPost("report/facturacion_graphs", data);
  store.dispatch(setLoading(false));
  if (res["data"]) {
    return res["data"];
  }
  return [];
};

export const getFacturactionGraphExcel = async (data) => {
  const res = await axiosPost("report/facturactions-graphs-excel", data);
  if (res["data"]) {
    return res["data"];
  }
  return [];
};
