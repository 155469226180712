import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Select from "react-select";
import { getOrders } from "../../services/order.service";
import { getResponsibleUserOptions } from "../../services/responsible-user.service";
import { constants } from "../../app-config";
import { Link } from "react-router-dom";
import { Spinner } from "../../components/spinner/spinner";
import { getYears } from "../../helpers/getYear.helper";

const ResponsibleUserOrders = ({ userData, loading }) => {
  let limit = 6;
  const [ordersData, setOrdersData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, SetTotal] = useState(0);
  const [usersSelectOption, setUsersSelectOption] = useState([]);
  const [filter, setFilter] = useState([]);
  const [userRole, setUserRole] = useState("responsibeuser");
  const [yearSelectOptions, setYearSelectOptions] = useState([]);

  const getAllOrders = async (page, filter) => {
    const res = await getOrders({
      page: page,
      limit: limit,
      responsible_user_id: userData.id,
      sortby: JSON.stringify(filter),
    });
    if (
      res &&
      Object.keys(res).length > 0 &&
      res?.orders &&
      res?.total &&
      page >= 1
    ) {
      setOrdersData((orders) => [...orders, ...res.orders]);
      SetTotal(res.total);
    } else {
      setOrdersData([]);
      SetTotal(0);
    }
  };

  useEffect(() => {
    if (page === 1) {
      setOrdersData([]);
    }
    if (userData.id) {
      getAllOrders(page, filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filter, userData.id]);

  useEffect(() => {
    if (userData?.id) {
      getUsersOptions(userData.id);
    }

    if (userData?.permission?.type === "responsibeuser")
      setUserRole("responsibeuser");

    if (userData?.company?.created_at) {
      const years = getYears(userData.company.created_at);
      if (years && years.length > 0) {
        let tempArray = [];
        years.forEach((e) => {
          tempArray.push({ label: e, value: e });
        });
        setYearSelectOptions(tempArray);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = () => {
    setPage((page) => page + 1);
  };

  const getUsersOptions = async (id) => {
    const res = await getResponsibleUserOptions({ user_id: parseInt(id) });

    if (res && res.length > 0) {
      const comboValue = res.map((e) => {
        return {
          label: e?.user_responsible?.Name,
          value: e?.IdUser,
          custom: "user_id",
        };
      });
      setUsersSelectOption(comboValue);
    } else {
      setUsersSelectOption([]);
    }
  };

  return (
    <>
      <section className="section inner-banner-sec select-zindex">
        <div className="container">
          <div className="wrapper">
            <div className="inner-banner-wrap">
              <div
                className="inner-banner no-overlay right-title"
                style={{
                  backgroundImage: `url(${constants.BACKEND_URL}${userData?.company?.banner_orders})`,
                }}
              >
                <div className="inner-banner-title">
                  <h4>Bienvenido a tus ordenes de compra</h4>
                </div>
              </div>
            </div>
            <div className="search-filter">
              <div className="search-filter-wrap">
                <div className="search-filter-title">
                  Filtra tu búsqueda por:
                </div>
                <div className="filter-form" style={{ width: "20%" }}>
                  <Select
                    options={[
                      {
                        label: "Usuarios",
                        options: usersSelectOption,
                      },
                      {
                        label: "Año",
                        options: yearSelectOptions,
                      },
                    ]}
                    placeholder="Sofía Torres"
                    onChange={(e) => {
                      setPage(1);
                      let data = {};
                      data[e.custom] = e.value;
                      setFilter([data]);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section listing-order-section admin-footer-margin">
        <div className="container">
          <div className="wrapper">
            <div className="listing-order-wrap">
              {loading ? <Spinner /> : ""}
              {ordersData && ordersData.length > 0 ? (
                <>
                  <div className="row">
                    {ordersData.map((data, index) => (
                      <div
                        className="col listing-order-item"
                        key={data.IdOrder}
                      >
                        <div className="listing-order-item-box">
                          <div className="listing-order-item-image">
                            <div className="image">
                              <img
                                src="/assets/images/listing-order-bgimg.png"
                                alt=""
                              />
                            </div>
                            <div className="listing-order-item-hover">
                              <div className="wrap">
                                <div className="icon">
                                  <img
                                    src="/assets/images/item-detail-icon.png"
                                    alt=""
                                  />
                                </div>
                                <div className="item-code">
                                  Orden #{data.Order_number}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="listing-order-item-content">
                            <div className="order-date">
                              <div className="date">
                                {moment(data.Creation_date)
                                  .locale("es")
                                  .format("LL")}
                              </div>
                              <div className="order-img">
                                {data.Active !== 0 ? (
                                  data.Active === 1 ? (
                                    <img
                                      src={`${process.env.PUBLIC_URL}/assets/images/approved.png`}
                                      alt="approved"
                                    />
                                  ) : (
                                    <img
                                      src={`${process.env.PUBLIC_URL}/assets/images/not-approved.png`}
                                      alt="not-approved"
                                    />
                                  )
                                ) : (
                                  <img
                                    src={`${process.env.PUBLIC_URL}/assets/images/pending.png`}
                                    alt="pending"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="sub-title">
                              <h6>{data?.user?.userName}</h6>
                              <ul>
                                <li>Cuenta: {data?.company?.Name} </li>
                                <li>
                                  Detalles: {data?.total_products} productos
                                </li>
                                <li>
                                  Total final: $
                                  {parseFloat(data?.total).toFixed(2)}
                                </li>
                              </ul>
                            </div>
                            <div className="view-ordr">
                              <Link
                                to={
                                  userRole === "responsibeuser"
                                    ? `/responsibe-user/view-order/${data.IdOrder}/${data.Order_number}`
                                    : `/responsibe-user/orders`
                                }
                              >
                                Ver orden <i className="fa fa-plus"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {total &&
                  Math.ceil(total / limit) > page &&
                  ordersData.length > 0 ? (
                    <div className="see-more text-center">
                      <a
                        href="##"
                        onClick={(e) => {
                          e.preventDefault();
                          loadMore();
                        }}
                      >
                        Ver más <i className="fa fa-chevron-down"></i>
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                !loading && (
                  <div className="no-puchase-order">
                    <img src="/assets/images/order-purchase.png" alt="" />
                    <div className="content">
                      Aún no existen ordenes de compra
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = ({ auth: { user_details }, general: { loading } }) => ({
  userData: user_details,
  loading,
});

export default connect(mapStateToProps, null)(ResponsibleUserOrders);
