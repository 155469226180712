import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { addToCart } from "../../redux/actions/cart.actions";
import { Spinner } from "../../components/spinner/spinner";

const Catalogue = ({
  loadMore,
  products,
  total,
  page,
  cartItems,
  addCart,
  loading,
  search,
  userData,
}) => {
  let history = useHistory();

  const [userRole, setUserRole] = useState("basic");

  useEffect(() => {
    if (userData?.permission?.type === "admin") setUserRole("admin");
  }, [userData]);

  const handleAddToCart = (productData) => {
    let tallaOptions = [];
    let productCart = [];
    if (
      productData?.product_details &&
      productData.product_details.length > 0
    ) {
      productData.product_details.forEach((e) => {
        if (e.hasOwnProperty("Talla")) {
          tallaOptions.push(e.Talla);
        }
      });
    }
    if (tallaOptions.length > 0) {
      tallaOptions.forEach((e) => {
        productCart.push({
          Cantidad: 1,
          Clave_GDI: 0,
          IdProduct: productData.IdProduct,
          IdCompanyGDI: userData?.company?.company_gdi,
          Name: productData.Name,
          Precio: productData.Pricing,
          ProductImage: productData.Photos,
          Type: productData.Type,
          TallOptions: tallaOptions,
          Talla: e,
          subTotal: parseFloat(1 * parseFloat(productData.Pricing).toFixed(2)),
        });
      });
    }

    if (userData?.company?.company_gdi && productCart.length > 0) {
      addCart(cartItems, productCart);

      setTimeout(() => {
        userRole === "admin"
          ? history.push({
              pathname: "/admin/cart/products",
            })
          : history.push({ pathname: "/cart/products" });
      }, 200);
    }
  };

  return (
    <div className="col product-list-wrap mobile-loder-space">
      {loading ? <Spinner /> : ""}
      {products.length > 0 ? (
        <div className="product-list">
          {/* change condition for search */}
          {search !== "" && total === 0 ? (
            <>
              <div className="search-result">
                <div className="search-result-content">
                  <div className="icon">
                    <img src="/assets/images/comments.png" alt="" />
                  </div>
                  <div className="content">
                    Tu búsqueda no coincidió con ningún producto, por favor
                    realiza otra{" "}
                  </div>
                  <div className="suggested-products">Productos sugeridos</div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          <div className="products">
            {/* {loading ? <Spinner /> : ""} */}
            {products.map((data, index) => (
              <div className="product-item" key={index}>
                <div className="item-box">
                  <div className="image">
                    {data?.Photos ? (
                      <img src={data.Photos} alt="" />
                    ) : (
                      <img src="/assets/images/product-item.jpg" alt="" />
                    )}
                  </div>
                  <div className="product-hover-box">
                    <div className="product-hover-box-wrap">
                      <div className="hover-buttons mb-0">
                        <button
                          className="white-btn"
                          onClick={(e) => {
                            userRole === "admin"
                              ? history.push({
                                  pathname: `/admin/catalogue/detail/${data.IdProduct}`,
                                })
                              : history.push({
                                  pathname: `/catalogue/detail/${data.IdProduct}`,
                                });
                          }}
                        >
                          Ver detalles
                        </button>

                        <button
                          className="blue-btn"
                          onClick={(e) => {
                            handleAddToCart(data);
                          }}
                        >
                          Añadir a la orden
                        </button>
                      </div>
                      <div className="desc">
                        la cantidad y tallas las podras escoger en tu orden
                      </div>
                    </div>
                  </div>
                </div>
                <div className="product-detail">
                  <div className="product-name">{data.Name}</div>
                  <div className="product-price">${data.Pricing} MXN</div>
                </div>
              </div>
            ))}

            {total &&
            page &&
            Math.ceil(total / 9) > page &&
            products.length !== 0 ? (
              <div className="load-more">
                <div className="view-more">
                  <a
                    href="##"
                    onClick={(e) => {
                      e.preventDefault();
                      loadMore();
                    }}
                  >
                    Ver más <i className="fa fa-chevron-down"></i>
                  </a>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

// export default Catalogue;

const mapStateToProps = ({
  cart: { items },
  general: { loading },
  auth: { user_details },
}) => ({
  cartItems: items,
  loading,
  userData: user_details,
});

const mapDispatchToProps = (dispatch) => ({
  addCart: (cartItems, data) => dispatch(addToCart(cartItems, data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Catalogue);
