import React, { useState } from "react";
import { connect } from "react-redux";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Dropzone from "react-dropzone";
import { constants } from "../../../../../app-config";
import { baseReportsImport } from "../../../../../services/import-file.service";

const SagmintoReportImportForm = ({ company, compnayId }) => {
  const initials = {
    file: "",
  };
  const [submitted, setSubmitted] = useState(false);

  const ConsumoReportImportFormSchema = Yup.object().shape({
    file: Yup.array()
      .required("Este campo es obligatorio")
      .test(
        "file test",
        "por favor, importe el archivo de extensión xlsx",
        (value) => {
          let toggle = true;
          const file = value?.[0];
          const fileName = file?.name.split(".").pop();
          if (
            !value ||
            value.length === 0 ||
            !file ||
            !fileName ||
            fileName !== "xlsx"
          ) {
            toggle = false;
          }
          return toggle;
        }
      ),
  });
  const onSubmit = async (values) => {
    setSubmitted(true);
    const formData = new FormData();
    if (values.file[0]) {
      formData.append("file", values.file[0]);
    }
    if (
      Object.keys(company).length > 0 &&
      company?.companyGDI &&
      company.companyGDI !== ""
    ) {
      formData.append("idCompanyGdi", company.companyGDI);
    }
    formData.append("IdCompany", compnayId);
    const res = await baseReportsImport(formData);
    setSubmitted(false);
    if (res && res.status === "success") {
      values.file = "";
      // showToast("todos los archivos almacenados correctamente", "success");
    }
  };
  return (
    <div className="content-detail">
      <div className="content-detail-button">
        <div style={{ marginLeft: "10px" }}>
          <a
            href={`${constants.BACKEND_URL}storage/excel-demo/BaseReport.xlsx`}
            className="btn blue-square-btn"
            download
          >
            Referencia del informe
          </a>
        </div>
      </div>
      <Formik
        initialValues={initials}
        onSubmit={onSubmit}
        validationSchema={ConsumoReportImportFormSchema}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="drop-image">
              <label>Reporte de seguimiento</label>
              <Dropzone
                accept=".xlsx"
                multiple={false}
                onDrop={(acceptedFiles) => {
                  const imageDataOnDrop = acceptedFiles.map((file) =>
                    Object.assign(file, {
                      preview: URL.createObjectURL(file),
                    })
                  );

                  setFieldValue("file", imageDataOnDrop);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <>
                    <div className="drop-box" {...getRootProps()}>
                      <input {...getInputProps()} />
                      {values.file === "" &&
                        "Drag and drop file or select xlsx file"}
                      <aside>
                        {values.file &&
                          values.file.map((file, index) => {
                            return (
                              <div className="dz_img_wrp" key={file.name}>
                                <div className="thumb">
                                  <div className="thumb-inner">
                                    <img
                                      src="/assets/images/file-icon.png"
                                      alt="itemImage"
                                    />
                                  </div>
                                  <p className="thumb-file-name">{file.name}</p>
                                </div>
                              </div>
                            );
                          })}
                      </aside>
                    </div>
                  </>
                )}
              </Dropzone>
              <ErrorMessage
                name="file"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="tabSubmit-btn">
              <button
                className="btn blue-square-btn"
                type="submit"
                disabled={submitted}
              >
                {submitted && (
                  <i className="fa fa-spinner fa-spin button-loader"></i>
                )}
                Enviar
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = ({ company }) => ({
  company,
});

export default connect(mapStateToProps, null)(SagmintoReportImportForm);
