import { axiosPost } from "../axios/axios.config";
import {
  setFormProcessing,
  setLoading,
} from "../redux/actions/general.actions";
import { store } from "../redux/store";

export const getBranches = async (data) => {
  store.dispatch(setLoading(true));
  const res = await axiosPost("branch/list", data);
  store.dispatch(setLoading(false));
  if (res["data"]) {
    return res["data"];
  }
  return [];
};

export const getBranch = async (data) => {
  store.dispatch(setLoading(true));
  const res = await axiosPost("branch/get", data);
  store.dispatch(setLoading(false));
  if (res["data"]) {
    return res["data"];
  }
  return [];
};

export const getBranchOption = async (data) => {
  const res = await axiosPost("branch/options", data);
  if (res["data"]) {
    return res["data"];
  }
  return [];
};

export const getBranchOptionForOrder = async (data) => {
  const res = await axiosPost("branch/options-user", data);
  if (res["data"]) {
    return res["data"];
  }
  return [];
};

export const saveBranch = async (data) => {
  store.dispatch(setFormProcessing(true));
  const res = await axiosPost("branch/save", data);
  store.dispatch(setFormProcessing(false));
  if (res[["data"]]) {
    return res;
  }
  return false;
};

export const deleteBranches = async (data) => {
  const res = await axiosPost("branch/delete", data);
  if (res && res.status === "success") {
    return res;
  }
  return false;
};
