import axios from "axios";
import { Promise } from "q";
import { constants } from "../app-config";
import { showToast } from "../helpers/toast.helper";
import { logoutUser } from "../redux/actions/auth.actions";
import { emptyCart } from "../redux/actions/cart.actions";
import { setLoading } from "../redux/actions/general.actions";
import { store } from "../redux/store";
const Axios = axios.create();

Axios.interceptors.request.use((request) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin":"*"

  };

  if (!request.data.no_auth) {
    // const token = localStorage.getItem('token');
    const state = store.getState();
    const token = state.token;
    if (token) {
      headers.Authorization = "Bearer " + token;
    } else {
      return request;
    }
  } else {
    delete request.data.no_auth;
  }

  request.headers = headers;
  return request;
});

Axios.interceptors.response.use(
  (response) => {
    const { data } = response;

    if (data?.code === 401) {
      store.dispatch(emptyCart([]));
      store.dispatch(logoutUser());
      return Promise.resolve([]);
    }

    if (data["toast"]) {
      if (data?.message && Array.isArray(data.message)) {
        let message = data.message.toString(",");
        showToast(message, data.status);
      } else {
        showToast(data.message, data.status);
      }
      if (data["code"] !== 200) {
        return Promise.reject(data);
      }
    }
    return Promise.resolve(response["data"]);
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // localStorage.removeItem("token");
      //   store.dispatch(loading(false));
      //   store.dispatch(setRedirectToLogin(true));
      //   store.dispatch(setAuthUser({}));

      store.dispatch(emptyCart([]));
      store.dispatch(logoutUser());
      return Promise.resolve([]);
    } else {
      showToast("error", "Error while processing");
      return Promise.resolve([]);
      // return Promise.reject(error);
    }
  }
);

export const axiosGet = (url, isAuth = true) => {
  return new Promise((resolve, reject) => {
    Axios.get(constants.API_URL + url, { data: { no_auth: !isAuth } }).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        store.dispatch(setLoading(false));
        reject(error);
      }
    );
  });
};

export const axiosPost = (url, data = {}, isAuth = true) => {
  if (!isAuth) {
    data["no_auth"] = true;
  }
  return new Promise((resolve, reject) => {
    Axios.post(constants.API_URL + url, data).then(
      (res) => {
        resolve(res);
      },
      (error) => {
        store.dispatch(setLoading(false));
        reject(error);
      }
    );
  });
};
