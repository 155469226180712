import React, { useEffect, useState, useRef } from "react";
import { Field, Form, Formik } from "formik";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { BrandInner } from "../../brand-inner";
import { Spinner } from "../../../../../components/spinner/spinner";
import {
  getBranch,
  saveBranch,
} from "../../../../../services/branches.service";

const initials = { Name: "", Detail: "" };

export const BranchForm = (props) => {
  let history = useHistory();
  const formRef = useRef();
  const [submitted, setSubmitted] = useState(false);
  const [initialValues, setInitialValues] = useState(initials);
  const [id, setId] = useState(-1);
  const [branchId, SetBranchId] = useState(-1);

  useEffect(() => {
    if (props?.match?.params?.id) {
      setId(props.match.params.id);
    }
    if (props?.match?.params?.branchId) {
      SetBranchId(props.match.params.branchId);
      getParticularBranch(props.match.params.branchId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.match?.params]);

  const getParticularBranch = async (id) => {
    const res = await getBranch({ id: parseInt(id) });
    if (res && Object.keys(res).length > 0) {
      setInitialValues({ ...initialValues, ...res });
    }
  };

  const hasErrors = () => {
    return !formRef.current.values.Name;
  };

  const onSubmit = async () => {
    setSubmitted(true);

    if (branchId !== -1) {
      if (!hasErrors()) {
        let data;
        data = {
          ...formRef.current.values,
          IdSucursal: branchId,
          IdCompany: id,
        };
        const res = await saveBranch(data);
        setSubmitted(false);
        if (res && res.status === "success") {
          setTimeout(() => {
            history.push(`/brands/${id}/branches`);
          }, 200);
        }
      }
    } else {
      if (!hasErrors()) {
        const data = { ...formRef.current.values, IdCompany: id };
        const res = await saveBranch(data);
        setSubmitted(false);
        if (res && res.status === "success") {
          setTimeout(() => {
            history.push(`/brands/${id}/branches`);
          }, 200);
        }
      }
    }
  };

  return (
    <BrandInner onSubmit={onSubmit}>
      <div className="general-wrapper">
        <div className="general-setting-wrap mobile-loder-spacing">
          {props.loading ? (
            <Spinner />
          ) : (
            <>
              <div className="title">Sucursales</div>
              <div className="return-link mb-35">
                <Link to={`/brands/${id}/branches`}>
                  <i className="fa fa-arrow-circle-o-left"></i>Regresar
                </Link>
              </div>
              <div className="user-page">
                <div className="user-image">
                  <img src="/assets/images/branch-office.png" alt="" />
                </div>
                <div className="branch-form">
                  <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    innerRef={formRef}
                  >
                    {({ errors, touched, values }) => (
                      <Form>
                        <div className="form-group">
                          <label>Nombre de la sucursal</label>
                          <Field
                            name="Name"
                            type="text"
                            placeholder="Nombre"
                            className={
                              "form-control" +
                              (errors.Name && touched.Name ? " is-invalid" : "")
                            }
                          />
                          {submitted && !values.Name && (
                            <div className="invalid-feedback">
                              Este campo es obligatorio
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label>Dirección</label>
                          <Field
                            name="Detail"
                            placeholder="Escribe la dirección"
                            as="textarea"
                            rows="10"
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </BrandInner>
  );
};
const mapStateToProps = ({ general: { loading } }) => ({
  loading: loading,
});

export default connect(mapStateToProps, null)(BranchForm);
