import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { constants } from '../../app-config';
import { SelectBox } from '../../components/select-box/select-box';
import { getBranchOptionForOrder } from '../../services/branches.service';

const ConfirmationOrder = (props) => {
  let history = useHistory();
  const [orderId, setOrderId] = useState(-1);
  const [companyLogo, setCompanyLogo] = useState('');
  const [sucursalSelectOption, setSucursalSelectOption] = useState([]);
  const [IdSucursal, setIdSucursal] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const [total, setTotal] = useState(0);

  const [userRole, setUserRole] = useState('basic');

  useEffect(() => {
    if (props?.match?.params?.id) {
      setOrderId(props.match.params.id);
    }

    if (props?.userData?.company?.logo) {
      setCompanyLogo(props.userData.company.logo);
    }

    if (props?.userData?.id) {
      getBranches(props.userData.id);
    }

    if (props?.cartItems) {
      let subtotal = 0;
      props.cartItems.forEach((e) => {
        const temp = parseFloat(parseFloat(e.subTotal).toFixed(2));
        subtotal = subtotal + temp;
      });
      setTotal(subtotal);
    }

    if (props.userData?.permission?.type === 'admin') setUserRole('admin');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.match?.params]);

  const getBranches = async (id) => {
    const res = await getBranchOptionForOrder({ IdUser: parseInt(id) });
    if (res && res.length > 0) {
      const comboValue = res.map((e) => {
        return {
          label: e.Name,
          value: e.IdSucursal,
        };
      });
      setSucursalSelectOption(comboValue);
    } else {
      setSucursalSelectOption([]);
    }
  };

  const hasErrors = () => {
    return !IdSucursal;
  };

  const onSubmit = async () => {
    setSubmitted(true);

    if (!hasErrors()) {
      setSubmitted(false);
      setShowDiv(true);
    }
  };

  const finalizeOrder = () => {
    if (IdSucursal) {
      userRole === 'admin'
        ? history.push({
            pathname: `/admin/order/confirmation-warning/${orderId}/${IdSucursal}`,
          })
        : history.push({
            pathname: `/order/confirmation-warning/${orderId}/${IdSucursal}`,
          });
    }
  };

  return (
    <>
      <section className="section preorder-sec">
        <div className="container">
          <div className="wrapper">
            <div className="pre-order-wrap">
              <div className="title">
                <h4>Pre-orden de compra #{orderId}</h4>
              </div>
              <div className="date">{moment().format('Do MMMM YYYY')}</div>
            </div>
          </div>
        </div>
      </section>
      <section className="section shipment-details-sec gray-bg">
        <div className="container">
          <div className="wrapper">
            <div className="shipment-details-wrap">
              <div className="shipment-details">
                <div className="shipment-details-box">
                  <div className="title">
                    <h4>Detalles de envío</h4>
                    <div className="sub-title">
                      Selecciona una sucursal de envío o crea una
                    </div>
                  </div>
                  <div className="shipping-location-form">
                    <form action="#">
                      <div className="form-group" style={{ width: '70%' }}>
                        <SelectBox
                          name="IdPermissions"
                          onChange={(e) => setIdSucursal(e)}
                          option={sucursalSelectOption}
                          value={IdSucursal}
                          className="col-md-2"
                        />
                        {submitted && !IdSucursal && (
                          <div className="invalid-feedback">
                            This field is required
                          </div>
                        )}
                      </div>
                      <div className="submit_btn">
                        <button
                          type="button"
                          name="shipping_location_btn"
                          onClick={(e) => onSubmit()}
                        >
                          <i className="fa fa-chevron-right"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="image">
                    <img src={constants.BACKEND_URL + companyLogo} alt="" />
                  </div>
                </div>
              </div>
              <div className="preorder-summary">
                <div
                  className={
                    showDiv === true
                      ? `preorder-summary-box`
                      : `preorder-summary-box overlay`
                  }
                >
                  <div className="title">
                    <h4>Resumen de la pre-orden</h4>
                  </div>
                  <div className="order-summary">
                    <div className="subtotal">
                      <div className="text-right">Subtotal: </div>
                      <div className="text-left">
                        ${parseFloat(total).toFixed(2)} MXN
                      </div>
                    </div>
                    {/* <div className="taxes">
                      <div className="text-right">Impuestos:</div>
                      <div className="text-left">0%</div>
                    </div> */}
                    <div className="total">
                      <div className="text-right">Total:</div>
                      <div className="text-left">
                        ${parseFloat(total).toFixed(2)} MXN
                      </div>
                    </div>
                  </div>
                  <div
                    className="finalize-order"
                    onClick={(e) => finalizeOrder()}
                  >
                    <a href="##" className="btn blue-square-btn">
                      Finalizar orden
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = ({ auth: { user_details }, cart: { items } }) => ({
  userData: user_details,
  cartItems: items,
});
export default connect(mapStateToProps, null)(ConfirmationOrder);
