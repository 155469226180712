import { axiosPost } from "../axios/axios.config";
import { setFormProcessing } from "../redux/actions/general.actions";
import { store } from "../redux/store";

export const productDataImport = async (data) => {
  store.dispatch(setFormProcessing(true));
  const res = await axiosPost("product/import-product", data);
  store.dispatch(setFormProcessing(false));
  if (res[["data"]]) {
    return res;
  }
  return false;
};

export const consumoReportsImport = async (data) => {
  store.dispatch(setFormProcessing(true));
  const res = await axiosPost("report/consumo-reports-import", data);
  store.dispatch(setFormProcessing(false));
  if (res[["data"]]) {
    return res;
  }
  return false;
};

export const facturacionGraphReportsImport = async (data) => {
  store.dispatch(setFormProcessing(true));
  const res = await axiosPost("report/facturacion-graph-reports-import", data);
  store.dispatch(setFormProcessing(false));
  if (res[["data"]]) {
    return res;
  }
  return false;
};

export const baseReportsImport = async (data) => {
  store.dispatch(setFormProcessing(true));
  const res = await axiosPost("report/base-reports-import", data);
  store.dispatch(setFormProcessing(false));
  if (res[["data"]]) {
    return res;
  }
  return false;
};

export const getProductExcel = async (data) => {
  const res = await axiosPost("product/product-excel", data);
  if (res[["data"]]) {
    return res[["data"]];
  }
  return false;
};

export const getProductDetailExcel = async (data) => {
  const res = await axiosPost("product/product-detail-excel", data);
  if (res[["data"]]) {
    return res[["data"]];
  }
  return false;
};
