import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';

const SuccessfullConfirmation = (props) => {
  const [orderId, setOrderId] = useState(-1);
  const [orderResId, setOrderResId] = useState(-1);

  const [userRole, setUserRole] = useState('basic');

  useEffect(() => {
    if (props?.match?.params?.id) {
      setOrderId(props.match.params.id);
    }
    if (props?.match?.params?.orderId) {
      setOrderResId(props.match.params.orderId);
    }

    if (props?.userData?.permission?.type === 'admin') setUserRole('admin');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.match?.params]);

  return (
    <>
      <section className="section preorder-sec">
        <div className="container">
          <div className="wrapper">
            <div className="pre-order-wrap">
              <div className="title">
                <h4>Pre-orden de compra #{orderId}</h4>
              </div>
              <div className="date">{moment().format('Do MMMM YYYY')}</div>
            </div>
          </div>
        </div>
      </section>
      <section className="section shipment-details-sec gray-bg">
        <div className="container">
          <div className="wrapper">
            <div className="shipment-details-wrap">
              <div className="shipment-details">
                <div className="shipment-details-box overlay">
                  <div className="title">
                    <h4>Detalles de envío</h4>
                    <div className="sub-title">
                      Selecciona una sucursal de envío o crea una
                    </div>
                  </div>
                  <div className="shipping-location-form">
                    <form action="#">
                      <div className="form-group">
                        <input
                          type="text"
                          name="shipping_location"
                          placeholder="Sucursal Norte"
                        />
                        <i className="fa fa-chevron-down"></i>
                      </div>
                      <div className="submit_btn">
                        <button type="submit" name="shipping_location_btn">
                          <i className="fa fa-chevron-right"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="preorder-summary succesfull">
                <div className="preorder-summary-box">
                  <div className="wrap">
                    <div className="title">
                      <h4>Tu pre-orden ha sido realizada con éxito</h4>
                    </div>
                    <div className="review-preorder">
                      {/* <a href="#">Revisar pre-orden</a> */}
                      <Link
                        to={
                          userRole === 'admin'
                            ? `/admin/order/${orderResId}`
                            : `/order/${orderResId}`
                        }
                      >
                        Revisar pre-orden
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = ({ auth: { user_details } }) => ({
  userData: user_details,
});

export default connect(mapStateToProps)(SuccessfullConfirmation);
