import { axiosPost } from "../axios/axios.config";
import { setLoading } from "../redux/actions/general.actions";
import { store } from "../redux/store";

export const getResponsibleUserOptions = async (data) => {
  store.dispatch(setLoading(true));
  const res = await axiosPost("user/responsible-user", data);
  store.dispatch(setLoading(false));
  if (res["data"]) {
    return res["data"];
  }
  return [];
};
